import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getCompany } from 'api/company';
import { handleError } from 'helpers';
import { GetCompanyDto, GetCompanyResponse } from 'models/company';

export const useGetCompany = (data: GetCompanyDto) => {
  return useQuery<GetCompanyResponse, AxiosError>(
    ['company', data.companyId],
    () => getCompany(data),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
