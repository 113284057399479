import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import { FC, useMemo } from 'react';
import { Line } from 'react-chartjs-2';

import { Select } from 'components';
import { useForm } from 'react-hook-form';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

const colors: string[] = [
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)',
  'rgba(255, 99, 100,0.5)',
  'rgba(54, 162, 100,0.5)',
  'rgba(255, 106, 20,0.5)',
  'rgba(75, 192, 102,0.5)',
  'rgba(103, 102, 255,0.5)',
  'rgba(205, 109, 64,0.5)'
];

enum Axis {
  X = 'x',
  Y = 'y',
  Z = 'z',
  Xn = 'xn',
  Yn = 'yn',
  Zn = 'zn'
}

const options = [
  { value: Axis.X, label: 'Ось X' },
  { value: Axis.Y, label: 'Ось Y' },
  { value: Axis.Z, label: 'Ось Z' },
  { value: Axis.Xn, label: 'Ось Xn' },
  { value: Axis.Yn, label: 'Ось Yn' },
  { value: Axis.Zn, label: 'Ось Zn' }
];

type ChartProps = {
  xAxis: number[][];
  yAxis: number[][];
  zAxis: number[][];
  xnAxis: number[][];
  ynAxis: number[][];
  znAxis: number[][];
  className?: string;
};

export const MyChart: FC<ChartProps> = props => {
  const { xAxis, yAxis, zAxis, xnAxis, ynAxis, znAxis, className } = props;

  const { control, watch } = useForm<{ axis: Axis }>({ defaultValues: { axis: Axis.X } });
  const selectedAxis = watch('axis');

  const data: ChartData<'line', number[], number> = useMemo(() => {
    const getAxis = (): number[][] => {
      switch (selectedAxis) {
        case Axis.X:
          return xAxis;
        case Axis.Y:
          return yAxis;
        case Axis.Z:
          return zAxis;
        case Axis.Xn:
          return xnAxis;
        case Axis.Yn:
          return ynAxis;
        case Axis.Zn:
          return znAxis;
      }
    };
    const axis = getAxis();

    return {
      labels: Array.from({ length: Math.max(...axis.map(axis => axis.length)) }, (_, i) => i + 1),
      datasets: axis.map((_, index) => ({
        label: `Калибровка ${index}`,
        data: axis[index],
        backgroundColor: colors[index],
        borderColor: colors[index],
        borderWidth: 4,
        fill: false
      }))
    };
  }, [xAxis, yAxis, zAxis, xnAxis, ynAxis, znAxis, selectedAxis]);

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='mb-[24px] flex items-center justify-between'>
        <h3 className='text-h3 text-on-surface'>График калибровки</h3>
        <Select name='axis' label='Выбрать ось' control={control} options={options} />
      </header>

      <Line
        data={data}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top'
            }
          }
        }}
      />
    </div>
  );
};
