import { ButtonHTMLAttributes, FC, MouseEvent, ReactNode, useCallback } from 'react';

import { IconSpinner } from 'components';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'bordered' | 'transparent';
  height?: 'big' | 'small';
  wide?: boolean;
  title: string | ReactNode;
  className?: string;
  icon?: ReactNode;
  iconPosition?: 'left' | 'right';
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
};

const Button: FC<ButtonProps> = ({
  type = 'button',
  variant = 'primary',
  height = 'big',
  wide = false,
  title = '',
  className,
  icon,
  iconPosition = 'left',
  onClick,
  isDisabled = false,
  isLoading = false,
  ...props
}) => {
  const getVariantClass = useCallback(() => {
    switch (variant) {
      case 'primary': {
        return (
          'bg-primary text-light hover:enabled:elevation-1 ' +
          'disabled:bg-on-surface-transparent disabled:text-[rgba(29,27,32,0.38)]'
        );
      }
      case 'secondary': {
        return (
          'bg-secondary-container text-on-secondary-fixed ' +
          'hover:enabled:elevation-1 ' +
          'disabled:bg-on-surface-transparent disabled:text-[rgba(29,27,32,0.38)]'
        );
      }
      case 'tertiary': {
        return (
          'bg-tertiary text-light hover:enabled:elevation-1 ' +
          'disabled:bg-on-surface-transparent disabled:text-[rgba(29,27,32,0.38)]'
        );
      }
      case 'bordered': {
        return (
          'border-[1px] bg-transparent border-solid border-outline text-primary ' +
          'hover:enabled:bg-primary-opacity-008 focus:enabled:border-primary ' +
          'active:enabled:border-outline disabled:border-on-surface-transparent ' +
          'disabled:opacity-[0.7]'
        );
      }
      case 'transparent': {
        return (
          'bg-transparent text-primary px-[12px] ' +
          'hover:enabled:bg-primary-opacity-012 disabled:opacity-[0.7] ' +
          'hover:enabled:bg-primary-opacity-012 active:enabled:bg-primary-opacity-008 '
        );
      }
    }
  }, [variant]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      className={
        'flex items-center justify-center gap-[8px] text-ll px-[24px] py-[10px] ' +
        'rounded-[100px] disabled:cursor-auto disabled:text-on-surface ' +
        'shrink-0 select-none transition-all duration-200 ease-in-out ' +
        `${getVariantClass()} ` +
        `${icon && !isLoading ? (iconPosition === 'left' ? 'pl-[16px]' : 'pr-[16px]') : ''} ` +
        `${height === 'big' ? 'h-[56px]' : 'h-[40px]'} ` +
        `${wide ? 'w-full flex-auto' : 'self-start'} ` +
        `${className}`
      }
      disabled={isDisabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <IconSpinner className='h-[24px] w-[24px] animate-spin' />
      ) : (
        <>
          {icon && iconPosition === 'left' && icon}
          {title}
          {icon && iconPosition === 'right' && icon}
        </>
      )}
    </button>
  );
};

export default Button;
