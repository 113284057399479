import { FC } from 'react';
import { Control } from 'react-hook-form';

import { TestFormValues } from 'models/test(old)';

import { TextField } from 'components';

type TestPromocodesProps = {
  control: Control<TestFormValues>;
  className?: string;
};

const TestPromocodes: FC<TestPromocodesProps> = ({ control, className }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <header className='flex items-center h-[56px] mb-[16px]'>
        <h3 className='text-h3 text-on-secondary-fixed'>Промокод для доступа к тесту</h3>
      </header>

      <TextField
        name='promocode'
        label='Промокод'
        control={control}
        rules={{
          maxLength: { value: 64, message: 'Максимальная длина - 64 символа' }
        }}
        className='max-w-[544px]'
      />
    </div>
  );
};

export default TestPromocodes;
