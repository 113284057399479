import { Company, Published, TestFormValues, TestPreview, TestResult, TestType } from 'models/test';

export const DEFAULT_TEST_DURATION = 10;

export const DEFAULT_TEST_FORM_VALUES: TestFormValues = {
  title: '',
  description: '',
  type: TestType.BINARY,
  questionGroups: [],
  results: [],
  isEqualDuration: false,
  duration: 10,
  gratitude: '',
  published: Published.FOR_COMPANIES,
  companies: undefined
};

export const EMPTY_TEST_RESULT: TestResult = {
  from: 0,
  to: 100,
  resultForCompany: undefined,
  recommendationForCompany: undefined,
  resultForEmployee: undefined,
  recommendationForEmployee: undefined
};

export const DUMMY_COMPANIES: Company[] = [
  {
    id: '1',
    name: 'Сбер',
    industry: 'Банк',
    avatarUrl: 'https://static.rustore.ru/45708c9b-4819-486e-8ebc-c31249292480'
  },
  {
    id: '2',
    name: 'СберМегаМаркет',
    industry: 'Маркетплейс',
    avatarUrl: 'https://static.rustore.ru/45708c9b-4819-486e-8ebc-c31249292480'
  },
  {
    id: '3',
    name: 'СберМобайл',
    industry: 'Оператор связи',
    avatarUrl: 'https://static.rustore.ru/45708c9b-4819-486e-8ebc-c31249292480'
  }
];

export const DUMMY_TESTS: TestPreview[] = [
  {
    id: '1',
    title: 'Gallup Q12',
    description:
      'Оценка уровня вовлеченности персонала аналитического департамента компании “Сбер”',
    duration: 5400,
    questionAmount: 123,
    published: Published.UNPUBLISHED
  },
  {
    id: '2',
    title: 'Gallup Q12',
    description: 'Оценка уровня вовлеченности персонала ипотечного департамента компании “Сбер”',
    duration: 2700,
    questionAmount: 73,
    published: Published.FOR_COMPANIES
  },
  {
    id: '3',
    title: 'Gallup Q12',
    description: 'Оценка уровня вовлеченности персонала',
    duration: 960,
    questionAmount: 16,
    published: Published.FOR_ALL
  },
  {
    id: '4',
    title: 'Наименование теста',
    description: 'Краткое описание',
    duration: 1230,
    questionAmount: 123,
    published: Published.UNPUBLISHED
  }
];

export const TESTS_QUERY_KEY = 'tests';
