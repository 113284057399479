import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { DEFAULT_ANIMATION_DELAY } from 'const/animation';

import { Button, IconCross, Portal } from 'components';

interface ModalConfirmProps {
  title?: string;
  children?: ReactNode;
  confirmText: string;
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm: () => void;
  onClose: () => void;
  portal?: boolean;
  lazy?: boolean;
  className?: string;
}

const ModalConfirm: FC<ModalConfirmProps> = props => {
  const {
    title,
    confirmText,
    isOpen,
    isLoading,
    onConfirm,
    onClose,
    portal = false,
    lazy = true,
    children,
    className
  } = props;
  const [isClosing, setIsClosing] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  const handleConfirm = useCallback(() => {
    onConfirm();
    setIsClosing(true);
    timerRef.current = setTimeout(() => {
      setIsClosing(false);
    }, DEFAULT_ANIMATION_DELAY);
  }, [onConfirm]);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    timerRef.current = setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, DEFAULT_ANIMATION_DELAY);
  }, [onClose]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
      document.body.classList.add('overflow-hidden');
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      document.body.classList.remove('overflow-hidden');
      clearTimeout(timerRef.current);
    };
  }, [isOpen, onKeyDown]);

  if (lazy && !isMounted) {
    return null;
  }

  if (portal)
    return (
      <Portal>
        <div
          className={
            'fixed top-0 right-0 bottom-0 left-0 flex ' +
            'justify-center items-center transition-all duration-300 ease-in-out ' +
            `${
              isClosing
                ? 'opacity-0 z-[var(--z-index-hidden)] invisible pointer-events-none'
                : isOpen
                ? 'opacity-100 z-[var(--z-index-modal)] visible pointer-events-auto'
                : 'opacity-0 z-[var(--z-index-hidden)] invisible pointer-events-none'
            }`
          }
        >
          <div
            className={
              'h-full w-full flex justify-center items-center bg-[var(--color-overlay)] cursor-pointer'
            }
            onClick={handleClose}
          >
            <div
              className={
                'max-h-[90vh] mx-[20px] p-[40px] flex flex-col ' +
                'bg-surface rounded-[16px] elevation-5 overflow-y-auto overflow-x-hidden ' +
                'transition-transform duration-300 ease-in-out ' +
                `${isClosing ? 'scale-[0.6]' : isOpen ? 'scale-[1]' : 'scale-[0.6]'} ` +
                className
              }
              onClick={event => event.stopPropagation()}
            >
              <button
                type='button'
                className='absolute h-[32px] w-[32px] bg-transparent top-[20px] right-[20px]'
                onClick={handleClose}
              >
                <IconCross className='h-full w-full text-outline' />
              </button>

              {title && <h3 className='mb-[36px] text-h3 text-on-surface text-center'>{title}</h3>}

              {children}

              <div className='flex gap-[8px]'>
                <Button
                  variant='secondary'
                  title='Отменить'
                  onClick={handleClose}
                  className='min-w-[160px]'
                  disabled={isLoading}
                />
                <Button
                  variant='primary'
                  title={confirmText}
                  onClick={handleConfirm}
                  className='min-w-[160px]'
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );

  return (
    <div
      className={
        'fixed top-0 right-0 bottom-0 left-0 flex ' +
        'justify-center items-center transition-all duration-300 ease-in-out ' +
        `${
          isClosing
            ? 'opacity-0 z-[var(--z-index-hidden)] invisible pointer-events-none'
            : isOpen
            ? 'opacity-100 z-[var(--z-index-modal)] visible pointer-events-auto'
            : 'opacity-0 z-[var(--z-index-hidden)] invisible pointer-events-none'
        }`
      }
    >
      <div
        className={
          'h-full w-full flex justify-center items-center bg-[var(--color-overlay)] cursor-pointer'
        }
        onClick={handleClose}
      >
        <div
          className={
            'max-h-[90vh] mx-[20px] p-[40px] flex flex-col ' +
            'bg-surface rounded-[16px] elevation-5 overflow-y-auto overflow-x-hidden ' +
            'transition-transform duration-300 ease-in-out ' +
            `${isClosing ? 'scale-[0.6]' : isOpen ? 'scale-[1]' : 'scale-[0.6]'} ` +
            className
          }
          onClick={event => event.stopPropagation()}
        >
          <button
            type='button'
            className='absolute h-[32px] w-[32px] bg-transparent top-[20px] right-[20px]'
            onClick={handleClose}
          >
            <IconCross className='h-full w-full text-outline' />
          </button>

          {title && <h3 className='mb-[36px] text-h3 text-on-surface text-center'>{title}</h3>}

          {children}

          <div className='flex gap-[8px]'>
            <Button
              variant='secondary'
              title='Отменить'
              onClick={handleClose}
              className='min-w-[160px]'
              disabled={isLoading}
            />
            <Button
              variant='primary'
              title={confirmText}
              onClick={handleConfirm}
              className='min-w-[160px]'
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalConfirm;
