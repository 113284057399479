import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Title,
  Tooltip
} from 'chart.js';
import { FC, useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { useForm } from 'react-hook-form';

import { Select } from 'components';
import { AccelerometerData } from 'models/accelerometer';
import { CalibrationData } from 'models/calibration';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  TimeScale
);

const colors: string[] = [
  'rgba(255, 99, 132, 0.9)',
  'rgba(54, 162, 235, 0.25)',
  'rgba(255, 206, 86, 0.25)',
  'rgba(75, 192, 192, 0.25)',
  'rgba(153, 102, 255, 0.25)',
  'rgba(255, 159, 64, 0.25)',
  'rgba(255, 99, 100, 0.25)',
  'rgba(54, 162, 100, 0.25)',
  'rgba(255, 106, 20, 0.25)',
  'rgba(75, 192, 102, 0.25)',
  'rgba(103, 102, 255, 0.25)',
  'rgba(205, 109, 64, 0.25)'
];

enum Axis {
  X = 'x',
  Y = 'y',
  Z = 'z',
  Xn = 'xn',
  Yn = 'yn',
  Zn = 'zn'
}

const options = [
  { value: Axis.X, label: 'Ось X' },
  { value: Axis.Y, label: 'Ось Y' },
  { value: Axis.Z, label: 'Ось Z' },
  { value: Axis.Xn, label: 'Ось Xn' },
  { value: Axis.Yn, label: 'Ось Yn' },
  { value: Axis.Zn, label: 'Ось Zn' }
];

type ChartProps = {
  title: string;
  accelerometerData: AccelerometerData;
  calibration?: CalibrationData;
  className?: string;
};

export const AccelerometerChart: FC<ChartProps> = props => {
  const { title, accelerometerData, calibration, className } = props;

  const xAxis = accelerometerData.x;
  const yAxis = accelerometerData.y;
  const zAxis = accelerometerData.z;
  const xnAxis = accelerometerData.x_norm;
  const ynAxis = accelerometerData.y_norm;
  const znAxis = accelerometerData.z_norm;

  const calibrationXAxes: number[][] =
    calibration?.accelerometerData.reduce((acc, current) => {
      acc.push(current.x);
      return acc;
    }, [] as number[][]) || [];

  const calibrationYAxes: number[][] =
    calibration?.accelerometerData.reduce((acc, current) => {
      acc.push(current.y);
      return acc;
    }, [] as number[][]) || [];

  const calibrationZAxes: number[][] =
    calibration?.accelerometerData.reduce((acc, current) => {
      acc.push(current.z);
      return acc;
    }, [] as number[][]) || [];

  const calibrationXnAxes: number[][] =
    calibration?.accelerometerData.reduce((acc, current) => {
      acc.push(current.x_norm);
      return acc;
    }, [] as number[][]) || [];

  const calibrationYnAxes: number[][] =
    calibration?.accelerometerData.reduce((acc, current) => {
      acc.push(current.y_norm);
      return acc;
    }, [] as number[][]) || [];

  const calibrationZnAxes: number[][] =
    calibration?.accelerometerData.reduce((acc, current) => {
      acc.push(current.z_norm);
      return acc;
    }, [] as number[][]) || [];

  const { control, watch } = useForm<{ axis: Axis }>({ defaultValues: { axis: Axis.X } });
  const selectedAxis = watch('axis');

  const data: ChartData<'line', number[], number> = useMemo(() => {
    const getAxis = (): number[] => {
      switch (selectedAxis) {
        case Axis.X:
          return xAxis;
        case Axis.Y:
          return yAxis;
        case Axis.Z:
          return zAxis;
        case Axis.Xn:
          return xnAxis;
        case Axis.Yn:
          return ynAxis;
        case Axis.Zn:
          return znAxis;
      }
    };
    const axis = getAxis();

    const getAccelerometerAxes = (): number[][] => {
      switch (selectedAxis) {
        case Axis.X:
          return calibrationXAxes;
        case Axis.Y:
          return calibrationYAxes;
        case Axis.Z:
          return calibrationZAxes;
        case Axis.Xn:
          return calibrationXnAxes;
        case Axis.Yn:
          return calibrationYnAxes;
        case Axis.Zn:
          return calibrationZnAxes;
      }
    };
    const accelerometerAxes = getAccelerometerAxes();

    return {
      labels: Array.from(
        { length: Math.max(axis.length, ...accelerometerAxes.map(axis => axis.length)) },
        (_, i) => i + 1
      ),
      datasets: [
        {
          label: title,
          data: axis,
          backgroundColor: colors[0],
          borderColor: colors[0],
          borderWidth: 4,
          fill: false
        },
        ...accelerometerAxes.map((_, index) => ({
          label: `Калибровка ${index}`,
          data: accelerometerAxes[index],
          backgroundColor: colors[index + 1],
          borderColor: colors[index + 1],
          borderWidth: 4,
          fill: false
        }))
      ]
    };
  }, [
    selectedAxis,
    xAxis,
    yAxis,
    zAxis,
    xnAxis,
    ynAxis,
    znAxis,
    calibrationXAxes,
    calibrationYAxes,
    calibrationZAxes,
    calibrationXnAxes,
    calibrationYnAxes,
    calibrationZnAxes
  ]);

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='mb-[24px] flex items-center justify-between'>
        <h3 className='text-h3 text-on-surface'>{title}</h3>
        <Select name='axis' label='Выбрать ось' control={control} options={options} />
      </header>

      <Line
        data={data}
        options={{
          responsive: true,
          plugins: {
            legend: {
              position: 'top'
            }
          }
        }}
      />
    </div>
  );
};
