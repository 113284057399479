import { FC, useMemo, useState } from 'react';

import { getAverageScore } from 'lib';
import { GroupedByTestTesting } from 'models/testing';

import { Button, Drawer, GroupedByTestTestingResult, Ratio } from 'components';

type GroupedByTestTestingCardProps = {
  groupedByTestTesting: GroupedByTestTesting;
  className?: string;
};

const GroupedByTestTestingCard: FC<GroupedByTestTestingCardProps> = props => {
  const { groupedByTestTesting, className } = props;
  const [resultsModalOpen, setResultsModal] = useState(false);
  const averageScore = useMemo(() => getAverageScore(groupedByTestTesting), [groupedByTestTesting]);

  const handleResults = () => {
    setResultsModal(true);
  };

  return (
    <div className={`flex justify-between items-center gap-[16px] ${className}`}>
      <div className='max-w-[440px] flex flex-col gap-[8px] ellipsis'>
        <div className='flex gap-[8px] ellipsis'>
          <h4 className='text-h4 text-tertiary'>Тест:</h4>
          <h4 title={groupedByTestTesting.testName} className='text-h4 text-on-surface ellipsis'>
            {groupedByTestTesting.testName}
          </h4>
        </div>
      </div>

      <div className='shrink-0 flex gap-[16px] items-center'>
        <Ratio value={averageScore} />
        <Button variant='secondary' title='Результаты теста' onClick={handleResults} />
      </div>

      <Drawer
        isOpen={resultsModalOpen}
        anchor='right'
        portal
        onClose={() => setResultsModal(false)}
      >
        <GroupedByTestTestingResult
          groupedByTestTesting={groupedByTestTesting}
          onClose={() => setResultsModal(false)}
        />
      </Drawer>
    </div>
  );
};

export default GroupedByTestTestingCard;
