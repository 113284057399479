import { FC } from 'react';
import { Control } from 'react-hook-form';

import { InputTime, TestPromocodes, TestReward, TextField } from 'components';
import { FORM_ERROR_MESSAGES } from 'const/form';
import { DEFAULT_TEST_DURATION } from 'const/test(old)';
import { TestFormValues } from 'models/test(old)';

type GeneralInfoProps = {
  control: Control<TestFormValues>;
  className?: string;
};

const GeneralInfo: FC<GeneralInfoProps> = props => {
  const { control, className } = props;

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='flex items-center h-[56px] mb-[16px]'>
        <h3 className='text-h3 text-on-secondary-fixed'>Общая информация</h3>
      </header>

      <TextField
        name='name'
        label='Наименование теста'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
        }}
        className='mb-[16px]'
      />

      <TextField
        name='desc'
        label='Краткое описание'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 256, message: 'Максимальная длина - 256 символов' }
        }}
        className='mb-[16px]'
      />

      <div className='mb-[16px] flex items-center gap-[8px]'>
        <label htmlFor='isEqualDuration' className='text-h5 text-on-surface-variant'>
          Время на тест
        </label>

        <InputTime
          name='duration'
          variant='minutes'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            min: {
              value: DEFAULT_TEST_DURATION,
              message: `Минимальное значение - ${DEFAULT_TEST_DURATION}`
            }
          }}
          containerClassName='w-[80px] shrink-0'
        />
      </div>

      <TextField
        name='mediaDTO.imageURL'
        label='Ссылка на изображение'
        control={control}
        rules={{
          required: { value: false, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 256, message: 'Максимальная длина - 256 символов' }
        }}
        className='mb-[16px]'
      />

      <TextField
        name='mediaDTO.videoURL'
        label='Ссылка на видео'
        control={control}
        rules={{
          required: { value: false, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 256, message: 'Максимальная длина - 256 символов' }
        }}
        className='mb-[36px]'
      />

      <TestReward control={control} className='mb-[36px]' />

      <TestPromocodes control={control} />
    </div>
  );
};

export default GeneralInfo;
