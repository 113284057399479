import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, useState } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useFieldArray
} from 'react-hook-form';

import { TestFormValues } from 'models/test(old)';

import { AddTestFormQuestion, Button, Drawer, FormQuestionCard, IconPlus } from 'components';

type TestFormProps = {
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  className?: string;
};

const TestForm: FC<TestFormProps> = ({
  control,
  trigger,
  watch,
  getValues,
  setValue,
  className
}) => {
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState<boolean>(false);

  const {
    fields: formQuestionFields,
    append: appendFormQuestion,
    remove: removeFormQuestion,
    move: moveFormQuestion
  } = useFieldArray({ control, name: 'formQuestions' });

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = formQuestionFields.findIndex(question => question.id === active.id);
      const newIndex = formQuestionFields.findIndex(question => question.id === over.id);
      moveFormQuestion(oldIndex, newIndex);
    }
  };

  const handleAddGroup = () => {
    setAddQuestionModalOpen(true);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='flex items-center h-[56px] mb-[16px]'>
        <h3 className='text-h3 text-on-secondary-fixed'>Анкета</h3>
      </header>

      <div className='flex flex-col gap-[16px]'>
        {!!formQuestionFields.length && (
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext items={formQuestionFields} strategy={verticalListSortingStrategy}>
              <ul className='flex flex-col gap-[8px]'>
                {formQuestionFields.map((formQuestion, index) => (
                  <FormQuestionCard
                    key={formQuestion.id}
                    formQuestion={formQuestion}
                    index={index}
                    order={index + 1}
                    control={control}
                    trigger={trigger}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    remove={removeFormQuestion}
                  />
                ))}
              </ul>
            </SortableContext>
          </DndContext>
        )}

        <Button
          variant='bordered'
          title='Добавить поле в анкету'
          icon={<IconPlus />}
          onClick={handleAddGroup}
        />
      </div>

      <Drawer
        isOpen={addQuestionModalOpen}
        anchor='right'
        portal
        onClose={() => setAddQuestionModalOpen(false)}
      >
        <AddTestFormQuestion
          append={appendFormQuestion}
          onClose={() => setAddQuestionModalOpen(false)}
        />
      </Drawer>
    </div>
  );
};

export default TestForm;
