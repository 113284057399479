import { Dispatch, FC, SetStateAction } from 'react';

type TabsProps = {
  show: 'feedback' | 'recommendation' | 'calibration';
  setShow: Dispatch<SetStateAction<'feedback' | 'recommendation' | 'calibration'>>;
  className?: string;
};

const Tabs: FC<TabsProps> = props => {
  const { show, setShow, className } = props;

  return (
    <div
      className={`flex gap-[2px] flex-wrap border-b-[1px] border-solid border-[#C5C6D0] ${className}`}
    >
      <button
        className={
          `${show === 'feedback' ? 'bg-secondary-container' : 'bg-[var(--color-primary-008)]'} ` +
          'h-[40px] py-[10px] px-[40px] text-ll text-on-secondary-fixed ' +
          'rounded-[16px_16px_0_0] duration-200'
        }
        onClick={() => setShow('feedback')}
      >
        Результаты
      </button>

      <button
        className={
          `${
            show === 'recommendation' ? 'bg-secondary-container' : 'bg-[var(--color-primary-008)]'
          } ` +
          'h-[40px] py-[10px] px-[40px] text-ll text-on-secondary-fixed ' +
          'rounded-[16px_16px_0_0] duration-200'
        }
        onClick={() => setShow('recommendation')}
      >
        Рекомендации
      </button>

      <button
        className={
          `${
            show === 'calibration' ? 'bg-secondary-container' : 'bg-[var(--color-primary-008)]'
          } ` +
          'h-[40px] py-[10px] px-[40px] text-ll text-on-secondary-fixed ' +
          'rounded-[16px_16px_0_0] duration-200'
        }
        onClick={() => setShow('calibration')}
      >
        Калибровка
      </button>
    </div>
  );
};

export default Tabs;
