import { useMutation, useQueryClient } from 'react-query';

import { deleteCompany } from 'api/company';
import { COMPANIES_QUERY_KEY } from 'const/company';
import { notifySuc } from 'helpers';
import { DeleteCompanyDto, GetCompaniesResponse } from 'models/company';

export const useDeleteCompanyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DeleteCompanyDto) => {
      return deleteCompany(data);
    },
    {
      onSuccess(_, { companyId }) {
        notifySuc('Компания удалена');

        queryClient.invalidateQueries([COMPANIES_QUERY_KEY], {
          refetchActive: false,
          refetchInactive: false
        });

        const oldData = queryClient.getQueryData<GetCompaniesResponse>([COMPANIES_QUERY_KEY]);
        if (oldData) {
          const updatedData = oldData.filter(company => company._id !== companyId);
          queryClient.setQueryData([COMPANIES_QUERY_KEY], updatedData);
        }
      }
    }
  );
};
