import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { UnauthorizedRouteConfig } from 'const/routes/unauthorized-routes';

import { DefaultLayout } from 'layouts';

const UnauthorizedRoutes: FC = () => {
  return (
    <DefaultLayout>
      <Routes>
        {Object.values(UnauthorizedRouteConfig).map(({ path, Component, element }) => (
          <Route key={path} path={path} Component={Component} element={element} />
        ))}
      </Routes>
    </DefaultLayout>
  );
};

export default UnauthorizedRoutes;
