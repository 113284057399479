import { FC } from 'react';
import {
  Control,
  Controller,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray
} from 'react-hook-form';

import { Button, IconPlus } from 'components';
import { sumToBeEqual100 } from 'const/form';
import {
  EMPTY_FEEDBACK,
  EMPTY_FEEDBACK_0_33,
  EMPTY_FEEDBACK_34_66,
  EMPTY_FEEDBACK_67_100
} from 'const/question(old)';
import { TestFormValues } from 'models/test(old)';
import RecommendCard from './RecommendCard';

type RecommendMasterManagerProps = {
  side: 'conditionalLeft' | 'conditionalRight';
  groupIndex: number;
  questionIndex: number;
  control: Control<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  className?: string;
};

const RecommendMasterManager: FC<RecommendMasterManagerProps> = props => {
  const { side, groupIndex, questionIndex, control, watch, getValues, setValue, className } = props;

  const {
    fields: feedbackFields,
    append: appendFeedback,
    remove: removeFeedback
  } = useFieldArray({
    control,
    name: `groupedQuestions.${groupIndex}.innerQuestions.${questionIndex}.feedback.${side}.recommendationMasterManager`
  });

  const handleAddFeedback = () => {
    if (!feedbackFields.length) {
      appendFeedback(EMPTY_FEEDBACK_0_33);
      appendFeedback(EMPTY_FEEDBACK_34_66);
      appendFeedback(EMPTY_FEEDBACK_67_100);
    } else {
      appendFeedback(EMPTY_FEEDBACK);
    }
  };

  return (
    <div className={`flex flex-col ${className}`}>
      {!!feedbackFields.length && (
        <Controller
          name={`groupedQuestions.${groupIndex}.innerQuestions.${questionIndex}.feedback.${side}.recommendationMasterManager`}
          control={control}
          rules={{ validate: { sumToBeEqual100 } }}
          render={({ fieldState: { error } }) => (
            <div className='mb-[16px] flex flex-col gap-[6px]'>
              <ul className='flex flex-col gap-[16px]'>
                {feedbackFields.map((feedback, index) => (
                  <RecommendCard
                    key={feedback.id}
                    side={side}
                    index={index}
                    groupIndex={groupIndex}
                    questionIndex={questionIndex}
                    control={control}
                    getValues={getValues}
                    watch={watch}
                    setValue={setValue}
                    removeFeedback={removeFeedback}
                  />
                ))}
              </ul>

              {error?.root && (
                <span className='ml-[16px] text-bs text-error'>{error.root.message}</span>
              )}
            </div>
          )}
        />
      )}

      <Button
        variant='bordered'
        title='Добавить результаты'
        icon={<IconPlus />}
        onClick={handleAddFeedback}
      />
    </div>
  );
};

export default RecommendMasterManager;
