import { CreateOpenQuestionDto } from 'models/open-question';
import {
  AnswerPreset,
  CreateGroupedQuestionDto,
  Feedback,
  Question,
  QuestionFeedback,
  QuestionType
} from 'models/question(old)';

export const EMPTY_FEEDBACK: Feedback = {
  min: 0,
  max: 40,
  feedback: ''
};

export const EMPTY_FEEDBACK_0_33: Feedback = {
  min: 0,
  max: 33,
  feedback: ''
};

export const EMPTY_FEEDBACK_34_66: Feedback = {
  min: 34,
  max: 66,
  feedback: ''
};

export const EMPTY_FEEDBACK_67_100: Feedback = {
  min: 67,
  max: 100,
  feedback: ''
};

export const EMPTY_QUESTION_FEEEDBACK: QuestionFeedback = {
  feedbackUser: [],
  feedbackCompany: [],
  feedbackMasterManager: [],
  recommendationUser: [],
  recommendationCompany: [],
  recommendationMasterManager: []
};

export const EMPTY_GROUPED_QUESTION: CreateGroupedQuestionDto = {
  question: '',
  feedback: EMPTY_QUESTION_FEEEDBACK,
  innerQuestions: []
};

export const EMPTY_QUESTION: Question = {
  question: '',
  binaryAnswer: {
    conditionalLeft: {
      default: '',
      male: '',
      female: '',
      negative: false
    },
    conditionalRight: {
      default: '',
      male: '',
      female: '',
      negative: false
    }
  },
  equalAnswers: true,
  duration: 2000,
  reverse: false,
  flipped: false,
  questionType: QuestionType.CONTROL,
  feedback: {
    conditionalLeft: EMPTY_QUESTION_FEEEDBACK,
    conditionalRight: EMPTY_QUESTION_FEEEDBACK
  }
};

export const EMPTY_OPEN_QUESTION: CreateOpenQuestionDto = {
  question: '',
  imageURL: ''
};

export const answerPresets: AnswerPreset[] = [
  {
    title: 'Да/Нет',
    fields: {
      left: 'Нет',
      leftMale: 'Нет',
      leftFemale: 'Нет',
      right: 'Да',
      rightMale: 'Да',
      rightFemale: 'Да'
    }
  },
  {
    title: 'Согласен/Не согласен',
    fields: {
      left: 'Не согласен',
      leftMale: 'Не согласен',
      leftFemale: 'Не согласна',
      right: 'Согласен',
      rightMale: 'Согласен',
      rightFemale: 'Согласна'
    }
  },
  {
    title: 'Верно/Неверно',
    fields: {
      left: 'Неверно',
      leftMale: 'Неверно',
      leftFemale: 'Неверно',
      right: 'Верно',
      rightMale: 'Верно',
      rightFemale: 'Верно'
    }
  },
  {
    title: 'Часто/Редко',
    fields: {
      left: 'Редко',
      leftMale: 'Редко',
      leftFemale: 'Редко',
      right: 'Часто',
      rightMale: 'Часто',
      rightFemale: 'Часто'
    }
  }
];
