import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useFieldArray
} from 'react-hook-form';

import { TestFormValues } from 'models/test(old)';

import { Button, IconPlus, OpenQuestion } from 'components';

type OpenQuestionsProps = {
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  className?: string;
};

const OpenQuestions: FC<OpenQuestionsProps> = ({ control, className }) => {
  const {
    fields: openQuestionFields,
    append: appendOpenQuestion,
    remove: removeOpenQuestion,
    move: moveQuestion
  } = useFieldArray({ control, name: 'openQuestions' });

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = openQuestionFields.findIndex(question => question.id === active.id);
      const newIndex = openQuestionFields.findIndex(question => question.id === over.id);
      moveQuestion(oldIndex, newIndex);
    }
  };

  const handleAddQuestion = () => {
    appendOpenQuestion({
      question: '',
      imageURL: ''
    });
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='flex items-center h-[56px] mb-[16px]'>
        <h3 className='text-h3 text-on-secondary-fixed'>Открытые вопросы</h3>
      </header>

      {!!openQuestionFields.length && (
        <DndContext onDragEnd={handleDragEnd}>
          <SortableContext items={openQuestionFields} strategy={verticalListSortingStrategy}>
            <ul className='flex flex-col gap-[16px] mb-[16px]'>
              {openQuestionFields.map((question, index) => (
                <OpenQuestion
                  key={question.id}
                  question={question}
                  index={index}
                  order={index + 1}
                  control={control}
                  remove={removeOpenQuestion}
                />
              ))}
            </ul>
          </SortableContext>
        </DndContext>
      )}

      <Button
        variant='bordered'
        title='Добавить открытый вопрос'
        icon={<IconPlus />}
        onClick={handleAddQuestion}
      />
    </div>
  );
};

export default OpenQuestions;
