import { useMutation } from 'react-query';

import { setOpenQuestions } from 'api/open-questions';
import { handleError, notifySuc } from 'helpers';
import { SetOpenQuestionsDto } from 'models/open-question';

export const useSetOpenQuestionsMutation = () => {
  return useMutation(
    (data: SetOpenQuestionsDto) => {
      return setOpenQuestions(data);
    },
    {
      onSuccess() {
        notifySuc('Открытые вопросы добавлены');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
