import { useMutation, useQueryClient } from 'react-query';

import { createCompany } from 'api/company';
import { COMPANIES_QUERY_KEY } from 'const/company';
import { handleError, notifySuc } from 'helpers';
import { CreateCompanyDto } from 'models/company';

export const useCreateCompanyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateCompanyDto) => {
      return createCompany(data);
    },
    {
      onSuccess() {
        notifySuc('Компания создана');

        queryClient.invalidateQueries([COMPANIES_QUERY_KEY]);

        // TODO update cache when Backend will be ready
        // queryClient.invalidateQueries([COMPANIES_QUERY_KEY], {
        //   refetchActive: false,
        //   refetchInactive: false
        // });

        // const oldData = queryClient.getQueryData<GetCompaniesResponse>([COMPANIES_QUERY_KEY]);
        // if (oldData) {
        //   const updatedData = [...oldData, createdCompany];
        //   queryClient.setQueryData([COMPANIES_QUERY_KEY], updatedData);
        // }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
