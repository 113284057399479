import { FC, ReactNode } from 'react';

import { Sidebar } from 'components';

type SidebarLayoutProps = {
  children: ReactNode;
};

const SidebarLayout: FC<SidebarLayoutProps> = ({ children }) => {
  return (
    <div className='wrapper'>
      <Sidebar />
      <main className='main bg-surface relative'>
        <div className='container'>
          {/* <div className='h-full max-w-[100%] w-full'> */}
          {children}
          {/* </div> */}
        </div>
      </main>
    </div>
  );
};

export default SidebarLayout;
