import jwtDecode from 'jwt-decode';
import { USER_ROLES, UserInfo } from 'models/user';

export const useUserRole = (): USER_ROLES | null => {
  const token = localStorage.getItem('token');

  if (token) {
    const userInfo = jwtDecode(token) as UserInfo;
    switch (userInfo.role) {
      case 'admin':
        return USER_ROLES.ADMIN;
      case 'master':
        return USER_ROLES.MASTER;
      case 'manager':
        return USER_ROLES.MANAGER;
    }
  }

  return null;
};
