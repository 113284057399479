import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getCompanyUsers } from 'api/company';
import { handleError } from 'helpers';
import { GetCompanyUsersDto, GetCompanyUsersResponse } from 'models/company';

export const useGetCompanyUsers = (data: GetCompanyUsersDto) => {
  return useQuery<GetCompanyUsersResponse, AxiosError>(
    ['company/users'],
    () => getCompanyUsers(data),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
