import { DEFAULT_FEEDBACK_SUM } from 'const/feedback';
import { Feedback } from 'models/question(old)';

export enum FORM_ERROR_MESSAGES {
  REQUIRED_FIELD = 'Необходимое поле',
  DOESNT_CONSIST_OF_SPACES = 'Поле не должно состоять из одних пробелов',
  DOESNT_CONTAIN_SPACES = 'Поле не должно содержать пробелов',
  INCORRECT_WORD = 'Слово введено неверно',
  INCORRECT_EMAIL = 'Пожалуйста, введите действующий адрес электронной почты',
  INCORRECT_SUM = 'Сумма баллов должна быть равна 100'
}

// validation methods
export const htmlToNotBeEmpty = (value: any): true | FORM_ERROR_MESSAGES.REQUIRED_FIELD => {
  return value !== '<p><br></p>' ? true : FORM_ERROR_MESSAGES.REQUIRED_FIELD;
};

export const sumToBeEqual100 = (value: Feedback[]): true | FORM_ERROR_MESSAGES.INCORRECT_SUM => {
  const sum = value.reduce((acc, { min, max }, index) => acc + (max - min + (index ? 1 : 0)), 0);

  const isValid = sum === DEFAULT_FEEDBACK_SUM;
  return isValid ? true : FORM_ERROR_MESSAGES.INCORRECT_SUM;
};
