export enum ForWhom {
  USER = 'user',
  MASTER_MANAGER = 'masterManager',
  COMPANY = 'company'
}

export enum FeedbackType {
  FEEDBACK = 'feedback',
  RECOMMENDATION = 'recommendation'
}
