import { FC } from 'react';

import { IconChevronLeft } from 'components';

type AccordionProps = {
  title: string;
  collapsed: boolean;
  toggle: () => void;
  className?: string;
};

const Accordion: FC<AccordionProps> = ({ title, collapsed, toggle, className }) => {
  return (
    <div className={`flex flex-col gap-[16px] ${className}`}>
      <button
        type='button'
        onClick={toggle}
        className={
          'flex justify-between items-center gap-[10px] ' +
          'bg-transparent hover:bg-primary-opacity-008 ' +
          'transition-colors duration-300 ease-in-out'
        }
      >
        <h4 className='text-h4 text-tertiary'>{title}</h4>

        <div className='h-[40px] w-[40px] flex justify-center items-center'>
          <IconChevronLeft
            className={
              'h-[24px] w-[24px] text-primary ' +
              `${collapsed ? 'rotate-[270deg]' : 'rotate-[90deg]'}`
            }
          />
        </div>
      </button>

      <div className='h-[1px] w-full bg-tertiary' />
    </div>
  );
};

export default Accordion;
