import { useMutation, useQueryClient } from 'react-query';

import { updateAccount } from 'api/account';
import { handleError, notifySuc } from 'helpers';
import { UpdateAccountDto } from 'models/user';

export const useUpdateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: UpdateAccountDto) => {
      return updateAccount(dto);
    },
    {
      onSuccess(_, { userId }) {
        notifySuc('Профиль обновлен');

        // TODO update cache instead of invalidating
        queryClient.invalidateQueries(['account/users']);

        // TODO why userId is required?
        queryClient.invalidateQueries(['account/user', userId]);
        queryClient.invalidateQueries(['account']);
        // if (userId) {
        //   queryClient.invalidateQueries(['account/user', userId]);
        // } else {
        //   queryClient.invalidateQueries(['account']);
        // }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
