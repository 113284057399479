import {
  GetCompanyTestingsResponse,
  GetMyTestingsResponse,
  GetTestingDto,
  GetTestingReponse,
  GetUserTestingDto,
  GetUserTestingResponse,
  GetUserTestingsDto,
  GetUserTestingsResponse
} from 'models/testing';
import { instance } from './instance';

export const getMyTestings = async () => {
  const response = await instance.get<GetMyTestingsResponse>('testing');
  return response.data;
};

export const getUserTestings = async ({ userId, type }: GetUserTestingsDto) => {
  const query = type ? `testing/supervisor/${userId}?type=${type}` : `testing/supervisor/${userId}`;
  const response = await instance.get<GetUserTestingsResponse>(query);
  return response.data;
};

export const getUserTesting = async ({ userId, testingId }: GetUserTestingDto) => {
  // TODO testingId or testId is required? check it
  const response = await instance.get<GetUserTestingResponse>(
    `testing/supervisor/testing/${testingId}?userId=${userId}`
  );
  return response.data;
};

export const getCompanyTestings = async () => {
  // earlier a get response
  const response = await instance.post<GetCompanyTestingsResponse>(
    'testing/supervisor/testings/all'
  );
  return response.data;
};

export const getTesting = async ({ testingId }: GetTestingDto) => {
  const response = await instance.get<GetTestingReponse>(`testing/${testingId}`);
  return response.data;
};
