export type User = {
  _id?: string;
  id: string;
  name: string;
  email: string;
  gender: boolean;
  companyId?: string;
  company?: string;
  balance: number;
  role?: USER_ROLES;
  phone?: string;
  birthday?: string;
  promocodesIds: string[];
};

export type UserInfo = {
  id: string;
  role: USER_ROLES;
  exp: number;
  iat: number;
};

export enum USER_ROLES {
  ADMIN = 'admin',
  MASTER = 'master',
  MANAGER = 'manager'
}

export enum Gender {
  MALE = 'male',
  FEMALE = 'female'
}

export type GetMyAccountResponse = User;

export type GetAccountDto = {
  userId: string;
};
export type GetAccountResponse = User;

export type GetAccountsResponse = User[];

export type UpdateAccountDto = {
  userId?: string;
  body: {
    name?: string;
    birthday?: string;
    phone?: string;
    gender?: boolean;
  };
};

export type GetMyAccountBalanceResponse = number;

export type UpdateAccountFormValues = {
  name?: string;
  birthday?: string;
  phone?: string;
  gender?: Gender;
};
