import { FC, useMemo } from 'react';
import { Link, NavLink } from 'react-router-dom';

import AvatarPlaceholderPNG from 'assets/images/png/sidebar/avatar-placeholder.png';
import LogoSVG from 'assets/images/svg/ineo-logo.svg';

import {
  AdminPaths,
  AdminRoutes,
  adminNavigationLinks,
  managerNavigationLinks,
  masterNavigationLinks
} from 'const/routes';
import { useGetMyAccount } from 'hooks/account';
import { useLogout, useUserRole } from 'hooks/auth';
import { USER_ROLES } from 'models/user';

import { Button, IconEdit, IconExit } from 'components';

const Sidebar: FC = () => {
  const userRole = useUserRole();
  const { data: viewer, isLoading: viewerLoading } = useGetMyAccount();
  const logout = useLogout();

  const navigationLinks = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.ADMIN:
        return adminNavigationLinks;
      case USER_ROLES.MASTER:
        return masterNavigationLinks;
      case USER_ROLES.MANAGER:
        return managerNavigationLinks;
      case null:
        return [];
    }
  }, [userRole]);

  const role = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.ADMIN:
        return 'Администратор IN-EO (Admin)';
      case USER_ROLES.MASTER:
        return 'Ответственное лицо (Master)';
      case USER_ROLES.MANAGER:
        return 'Руководитель отдела (Manager)';
      case null:
        return '';
    }
  }, [userRole]);

  const profileLink = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.ADMIN:
        return AdminPaths[AdminRoutes.PROFILE];
      case USER_ROLES.MASTER:
        return AdminPaths[AdminRoutes.PROFILE];
      case USER_ROLES.MANAGER:
        return AdminPaths[AdminRoutes.PROFILE];
      case null:
        return '';
    }
  }, [userRole]);

  const handleExit = () => {
    logout();
  };

  return (
    <>
      <div className='w-[var(--width-sidebar)] shrink-0'></div>
      <aside
        className={
          'flex flex-col gap-[32px] shrink-0 pt-[32px] px-[20px] pb-[8px] ' +
          'bg-primary-container overflow-hidden fixed top-0 h-[100vh] w-[var(--width-sidebar)]'
        }
      >
        <div
          className={
            'h-[483px] w-[483px] rounded-full blur-[50px] absolute right-0 bottom-0 ' +
            'bg-gradient-to-l from-[#C0E8FF] to-[#FFABF3] z-[1]'
          }
        />

        <div className='max-w-[120px] w-full self-center z-[2]'>
          <img src={LogoSVG} alt='ineo-logo.svg' className='h-full w-full' />
        </div>

        <nav className='flex flex-col gap-[8px] mb-auto z-[2]'>
          {navigationLinks.map(({ title, link, Icon, disabled }) => (
            <NavLink
              key={link}
              to={link}
              title={title}
              className={
                'flex gap-[20px] items-center px-[16px] py-[8px] select-none ' +
                `${disabled ? 'cursor-not-allowed opacity-60' : ''}`
              }
              onClick={e => disabled && e.preventDefault()}
              aria-disabled={disabled}
            >
              {({ isActive }) => (
                <>
                  <div
                    className={
                      'h-[40px] w-[40px] shrink-0 rounded-[50%] flex justify-center items-center ' +
                      `${
                        isActive ? 'bg-light shadow-[0_4px_8px_0_rgba(0,0,0,0.12)]' : 'bg-[#EEF0FF]'
                      }`
                    }
                  >
                    <Icon
                      className={`h-[24px] w-[24px] ${
                        isActive ? 'text-primary' : 'text-[#718EE0]'
                      }`}
                    />
                  </div>

                  <span
                    className={
                      'ellipsis ' +
                      `${
                        isActive
                          ? 'text-on-primary-fixed-variant text-[14px] leading-[20px] font-[600]'
                          : 'text-[#5774C4] text-ll'
                      }`
                    }
                  >
                    {title}
                  </span>
                </>
              )}
            </NavLink>
          ))}
        </nav>

        <div className='flex flex-col items-center gap-[16px] z-[2]'>
          <div className='h-[64px] w-[64px] rounded-full border-[2px] border-solid border-surface overflow-hidden'>
            <img
              src={AvatarPlaceholderPNG}
              alt='avatar-placeholder.png'
              className='h-full w-full'
            />
          </div>

          <div className='flex flex-col items-center gap-[8px] z-[2]'>
            <p className='text-bm text-on-primary-fixed text-center'>
              {viewerLoading ? 'Загрузка...' : viewer ? viewer.name : 'Неизвестно'}
            </p>
            <p className='text-lm text-[#5774C4] text-center'>{role}</p>
          </div>
        </div>

        <div className='flex z-[2]'>
          <Link to={profileLink}>
            <Button variant='transparent' title='Профиль' icon={<IconEdit />} />
          </Link>
          <Button variant='transparent' title='Выйти' icon={<IconExit />} onClick={handleExit} />
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
