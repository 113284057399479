import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC, useState } from 'react';
import { Control, FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';
import { TestFormValues } from 'models/test(old)';

import { ButtonIcon, IconDragHandle, IconTrash, ModalConfirm, TextField } from 'components';

type OpenQuestionProps = {
  question: FieldArrayWithId<TestFormValues, 'openQuestions', 'id'>;
  index: number;
  order: number;
  control: Control<TestFormValues>;
  remove: UseFieldArrayRemove;
};

const OpenQuestion: FC<OpenQuestionProps> = ({
  question: { id: questionId },
  index,
  control,
  remove
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: questionId
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleRemove = () => {
    setDeleteModalOpen(true);
  };

  const confirmRemove = () => {
    remove(index);
  };

  return (
    <li ref={setNodeRef} {...attributes} style={style} className='flex items-center gap-[8px]'>
      <button
        type='button'
        ref={setActivatorNodeRef}
        {...listeners}
        className={
          'h-[32px] w-[32px] flex justify-center items-center shrink-0 ' +
          'bg-transparent text-outline cursor-grab'
        }
      >
        <IconDragHandle />
      </button>

      <TextField
        name={`openQuestions.${index}.question`}
        label='Открытый вопрос'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 128, message: 'Максимальная длина - 128 символов' }
        }}
      />

      <ButtonIcon Icon={IconTrash} variant='transparent' size='big' onClick={handleRemove} />

      <ModalConfirm
        title='Удалить вопрос?'
        confirmText='Удалить'
        isOpen={deleteModalOpen}
        portal
        onConfirm={confirmRemove}
        onClose={() => setDeleteModalOpen(false)}
      />
    </li>
  );
};

export default OpenQuestion;
