import axios from 'axios';

import {
  GenerateByInstructionDto,
  GenerateByInstructionResponse,
  GenerateCharacteristicResponse,
  GenerateCompanyCharacteristicDto,
  GenerateEmployeeCharacteristicDto,
  GenerateFeedbackForGroupDto,
  GenerateFeedbackForGroupResponse,
  GenerateFeedbackForQuestionDto,
  GenerateFeedbackForQuestionResponse
} from 'models/microservice';

const baseUrl = 'https://gpt.in-eo.tech';

export const generateByInstruction = async (body: GenerateByInstructionDto) => {
  const response = await axios.post<GenerateByInstructionResponse>(`${baseUrl}/generate`, body);
  return response.data;
};

export const generateFeedbackForQuestion = async (body: GenerateFeedbackForQuestionDto) => {
  const response = await axios.post<GenerateFeedbackForQuestionResponse>(
    `${baseUrl}/generateFeedbackForQuestion`,
    body
  );
  return response.data;
};

export const generateFeedbackForGroup = async (body: GenerateFeedbackForGroupDto) => {
  const response = await axios.post<GenerateFeedbackForGroupResponse>(
    `${baseUrl}/generateFeedbackForQuestionGroup`,
    body
  );
  return response.data;
};

export const generateEmployeeCharacteristic = async (body: GenerateEmployeeCharacteristicDto) => {
  const response = await axios.post<GenerateCharacteristicResponse>(
    `${baseUrl}/generateCharacteristicForEmployee`,
    body
  );
  return response.data;
};

export const generateCompanyCharacteristic = async (body: GenerateCompanyCharacteristicDto) => {
  const response = await axios.post<GenerateCharacteristicResponse>(
    `${baseUrl}/generateCharacteristicForCompany`,
    body
  );
  return response.data;
};
