import { ChangeEvent, InputHTMLAttributes, KeyboardEvent, useState } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';

import { IconError } from 'components';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: 'hours' | 'minutes' | 'seconds' | 'milliseconds';
  wide?: boolean;
  name: string;
  placeholder?: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
}

export type InputTimeProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const InputTime = <T extends FieldValues>({
  variant = 'milliseconds',
  wide = false,
  name,
  placeholder,
  control,
  rules = {},
  className,
  containerClassName,
  disabled = false,
  loading = false,
  ...rest
}: InputTimeProps<T>) => {
  const [inputFocus, setInputFocus] = useState(false);
  const [key, setKey] = useState(0);

  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const getLabel = () => {
    switch (variant) {
      case 'hours':
        return 'h';
      case 'minutes':
        return 'm';
      case 'seconds':
        return 's';
      case 'milliseconds':
        return 'ms';
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valueAsNumber = Number(value);

    if (Number.isNaN(valueAsNumber)) return;
    field.onChange(valueAsNumber);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    setInputFocus(false);
    setKey(key + 1);
  };

  return (
    <div
      className={
        'flex relative flex-col min-w-[120px] ' +
        `${wide ? 'w-full flex-auto' : ''} ` +
        `${containerClassName ? containerClassName : ''}`
      }
    >
      <input
        key={key}
        type='number'
        id={name}
        className={
          'h-[56px] flex-auto border-solid rounded-[4px] text-bl ' +
          'select-none duration-300 ease-out transition-colors ' +
          `${
            inputFocus && !fieldState.error
              ? 'border-primary border-[2px] p-[15px] text-primary'
              : 'border-outline-variant border-[1px] p-[16px] text-on-surface hover:border-on-surface'
          } ` +
          `${disabled ? 'bg-surface' : 'bg-light'} ` +
          `${fieldState.error ? 'mb-[4px] !border-error border-[2px]' : ''} ` +
          `${className ? className : ''}`
        }
        placeholder={placeholder}
        disabled={disabled || loading}
        readOnly={disabled || loading}
        value={field.value || 0}
        onChange={handleChange}
        onFocus={() => setInputFocus(true)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        {...rest}
      />

      <label
        htmlFor={name}
        className={
          'absolute top-[16px] right-[16px] ' +
          'text-bl text-on-surface-variant pointer-events-none ' +
          `${inputFocus ? 'hidden' : ''}`
        }
      >
        {getLabel()}
      </label>

      {!!fieldState.error && (
        <>
          <span className='ml-[16px] text-bs text-error'>{fieldState.error.message}</span>

          <span
            title={fieldState.error.message}
            className='absolute top-[16px] right-[12px] h-[24px] w-[24px]'
          >
            <IconError color='#BA1A1A' className='h-full w-full' />
          </span>
        </>
      )}
    </div>
  );
};

export default InputTime;
