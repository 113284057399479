import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC, useState } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayInsert,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';
import { useToggle } from 'hooks/common';
import { TestFormValues } from 'models/test(old)';

import {
  Accordion,
  Button,
  ButtonIcon,
  Drawer,
  IconCopy,
  IconDragHandle,
  IconFeedback,
  IconMoveHorizontal,
  IconTrash,
  InputTime,
  ModalConfirm,
  QuestionAnswers,
  QuestionResults,
  TextArea,
  TextField
} from 'components';

type QuestionProps = {
  question: FieldArrayWithId<TestFormValues, `groupedQuestions.${number}.innerQuestions`, 'id'>;
  index: number;
  order: number;
  groupIndex: number;
  groupOrder: number;
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  insertQuestion: UseFieldArrayInsert<TestFormValues, `groupedQuestions.${number}.innerQuestions`>;
  remove: UseFieldArrayRemove;
};

const Question: FC<QuestionProps> = ({
  question: { id: questionId },
  index,
  order,
  groupIndex,
  groupOrder,
  control,
  trigger,
  watch,
  getValues,
  setValue,
  insertQuestion,
  remove
}) => {
  const [mediaCollapsed, toggleMediaCollapsed] = useToggle(true);
  const [resultsModalOpen, setResultsModal] = useState<boolean>(false);
  const [answersModalOpen, setAnswersModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: questionId
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const handleRemove = () => {
    setDeleteModalOpen(true);
  };

  const confirmRemove = () => {
    remove(index);
  };

  const handleAnswers = () => {
    setAnswersModal(true);
  };

  const handleResults = () => {
    setResultsModal(true);
  };

  const handleCopy = () => {
    const currentQuestion = getValues(`groupedQuestions.${groupIndex}.innerQuestions.${index}`);
    insertQuestion(index + 1, currentQuestion);
  };

  return (
    <li ref={setNodeRef} {...attributes} style={style} className='flex items-start gap-[8px]'>
      <button
        type='button'
        ref={setActivatorNodeRef}
        {...listeners}
        className={
          'h-[32px] w-[32px] flex justify-center items-center shrink-0 ' +
          'bg-transparent text-outline cursor-grab'
        }
      >
        <IconDragHandle />
      </button>

      <span
        className={
          'shrink-0 h-[32px] min-w-[40px] p-[4px] flex justify-center items-center ' +
          'text-on-surface-variant text-bm ' +
          'bg-surface-variant rounded-[8px]'
        }
      >
        {groupOrder}-{order}
      </span>

      <div className='flex flex-col flex-grow gap-[16px]'>
        <TextArea
          name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.question`}
          label='Вопрос'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 256, message: 'Максимальная длина - 256 символов' }
          }}
          rows={3}
        />

        <div className='flex flex-col gap-[16px]'>
          <Accordion
            title='Ссылки на медиа'
            collapsed={mediaCollapsed}
            toggle={toggleMediaCollapsed}
          />

          {!mediaCollapsed && (
            <div className='flex flex-col gap-[16px]'>
              <TextField
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.mediaDTO.imageURL`}
                label='Ссылка на изображение'
                control={control}
                rules={{
                  required: { value: false, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 256, message: 'Максимальная длина - 256 символов' }
                }}
              />

              <TextField
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.mediaDTO.videoURL`}
                label='Ссылка на видео'
                control={control}
                rules={{
                  required: { value: false, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 256, message: 'Максимальная длина - 256 символов' }
                }}
              />
            </div>
          )}
        </div>

        <div className='flex justify-between flex-wrap gap-[16px]'>
          <div className='flex gap-[8px] items-center'>
            <h5 className='text-h5 text-on-surface-variant'>Время на ответ:</h5>

            <InputTime
              name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.duration`}
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                min: { value: 1000, message: 'Минимальное значение - 1000' }
              }}
              containerClassName='w-[96px] shrink-0'
            />
          </div>

          <div className='flex gap-[8px]'>
            <Button
              variant='secondary'
              title='Ответы'
              icon={<IconMoveHorizontal className='h-[18px] w-[18px]' />}
              onClick={handleAnswers}
            />

            <Button
              variant='secondary'
              title='Фидбэк по вопросу'
              icon={<IconFeedback className='h-[18px] w-[18px]' />}
              onClick={handleResults}
            />
          </div>
        </div>
      </div>

      <div className='flex gap-[8px]'>
        <ButtonIcon Icon={IconCopy} variant='transparent' size='big' onClick={handleCopy} />
        <ButtonIcon Icon={IconTrash} variant='transparent' size='big' onClick={handleRemove} />
      </div>

      <Drawer
        isOpen={answersModalOpen}
        anchor='right'
        portal
        onClose={() => setAnswersModal(false)}
      >
        <QuestionAnswers
          index={index}
          groupIndex={groupIndex}
          control={control}
          trigger={trigger}
          getValues={getValues}
          setValue={setValue}
          onClose={() => setAnswersModal(false)}
        />
      </Drawer>

      <Drawer
        isOpen={resultsModalOpen}
        anchor='right'
        portal
        onClose={() => setResultsModal(false)}
      >
        <QuestionResults
          groupIndex={groupIndex}
          groupOrder={groupOrder}
          questionIndex={index}
          questionOrder={order}
          control={control}
          trigger={trigger}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          onClose={() => setResultsModal(false)}
        />
      </Drawer>

      <ModalConfirm
        title='Удалить вопрос?'
        confirmText='Удалить'
        isOpen={deleteModalOpen}
        portal
        onConfirm={confirmRemove}
        onClose={() => setDeleteModalOpen(false)}
      />
    </li>
  );
};

export default Question;
