import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getUserTestings } from 'api/testing';
import { handleError } from 'helpers';
import { GetUserTestingsDto, GetUserTestingsResponse } from 'models/testing';

export const useGetUserTestings = (data: GetUserTestingsDto) => {
  return useQuery<GetUserTestingsResponse, AxiosError>(
    ['testing/supervisor', data.userId, data.type],
    () => getUserTestings(data),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
