export const toolbarOptions = [
  [{ header: [1, 2, 3, false] }],

  ['bold', 'italic', 'underline', 'strike'], // toggled buttons

  [{ list: 'ordered' }, { list: 'bullet' }], // ordered and unordered lists

  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript

  ['clean'] // remove formatting button
];
