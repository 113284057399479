import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC, useState } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useFieldArray
} from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';
import { EMPTY_QUESTION } from 'const/question(old)';
import { TestFormValues } from 'models/test(old)';

import {
  Button,
  ButtonIcon,
  Drawer,
  IconFeedback,
  IconPlus,
  IconTrash,
  ModalConfirm,
  Question,
  TextField
} from 'components';
import GroupResults from '../GroupResults/GroupResults';

type QuestionGroupProps = {
  questionGroup: FieldArrayWithId<TestFormValues, 'groupedQuestions', 'id'>;
  index: number;
  order: number;
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  remove: UseFieldArrayRemove;
};

const QuestionGroup: FC<QuestionGroupProps> = ({
  index,
  order,
  control,
  trigger,
  watch,
  getValues,
  setValue,
  remove
}) => {
  const [resultsModalOpen, setResultsModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const {
    fields: questionFields,
    append: appendQuestion,
    insert: insertQuestion,
    remove: removeQuestion,
    move: moveQuestion
  } = useFieldArray({ control, name: `groupedQuestions.${index}.innerQuestions` });

  const handleRemove = () => {
    setDeleteModalOpen(true);
  };

  const confirmRemove = () => {
    remove(index);
  };

  const handleResults = () => {
    setResultsModal(true);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = questionFields.findIndex(question => question.id === active.id);
      const newIndex = questionFields.findIndex(question => question.id === over.id);
      moveQuestion(oldIndex, newIndex);
    }
  };

  const handleAddQuestion = () => {
    appendQuestion(EMPTY_QUESTION);
  };

  return (
    <li
      className={
        'flex flex-grow flex-col rounded-[16px] bg-light ' +
        'border-[1px] border-solid border-outline-variant'
      }
    >
      <header
        className={
          'p-[24px] flex flex-grow items-center gap-[8px] ' +
          'border-b-[1px] border-solid border-outline-variant'
        }
      >
        <span
          className={
            'shrink-0 h-[32px] min-w-[32px] p-[4px] flex justify-center items-center ' +
            'bg-[#718EE0] rounded-[8px] text-surface text-bm'
          }
        >
          {order}
        </span>

        <TextField
          name={`groupedQuestions.${index}.question`}
          label='Группа вопросов'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
          }}
        />

        <Button
          variant='secondary'
          title='Фидбэк по группе'
          icon={<IconFeedback className='h-[18px] w-[18px]' />}
          onClick={handleResults}
        />

        <ButtonIcon Icon={IconTrash} variant='transparent' size='big' onClick={handleRemove} />
      </header>

      <div className='p-[24px] flex flex-col'>
        {!!questionFields.length && (
          <DndContext onDragEnd={handleDragEnd}>
            <SortableContext items={questionFields} strategy={verticalListSortingStrategy}>
              <ul className='flex flex-col gap-[16px] mb-[16px]'>
                {questionFields.map((question, questionIndex) => (
                  <Question
                    key={question.id}
                    question={question}
                    index={questionIndex}
                    order={questionIndex + 1}
                    groupIndex={index}
                    groupOrder={order}
                    control={control}
                    trigger={trigger}
                    watch={watch}
                    getValues={getValues}
                    setValue={setValue}
                    insertQuestion={insertQuestion}
                    remove={removeQuestion}
                  />
                ))}
              </ul>
            </SortableContext>
          </DndContext>
        )}

        <Button
          variant='bordered'
          title='Добавить вопрос'
          icon={<IconPlus />}
          onClick={handleAddQuestion}
          className='self-center'
        />
      </div>

      <Drawer
        isOpen={resultsModalOpen}
        anchor='right'
        portal
        onClose={() => setResultsModal(false)}
      >
        <GroupResults
          groupIndex={index}
          control={control}
          trigger={trigger}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          onClose={() => setResultsModal(false)}
        />
      </Drawer>

      <ModalConfirm
        title='Удалить группу?'
        confirmText='Удалить'
        isOpen={deleteModalOpen}
        portal
        onConfirm={confirmRemove}
        onClose={() => setDeleteModalOpen(false)}
      />
    </li>
  );
};

export default QuestionGroup;
