import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Button, IconMagic, TextEditor, TextPreview } from 'components';
import { DUMMY_COMPANY_CHARACTERISTIC } from 'const/company';
import { FORM_ERROR_MESSAGES, htmlToNotBeEmpty } from 'const/form';
import { useGenerateCompanyCharacteristic } from 'hooks/microservice';
import { useGetCompanyTestings } from 'hooks/testing';

type FormValues = {
  characteristic: string;
};

type CompanyCharacteristicProps = {
  companyId: string;
  editable?: boolean;
  className?: string;
};

const CompanyCharacteristic: FC<CompanyCharacteristicProps> = props => {
  const { editable, className } = props;
  const { data: testings, isLoading: testingsLoading } = useGetCompanyTestings();
  const { mutate: generateCharacteristic, isLoading: generateCharacteristicLoading } =
    useGenerateCompanyCharacteristic();
  const newData = false;

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      characteristic: DUMMY_COMPANY_CHARACTERISTIC
    }
  });

  const handleGenerate = () => {
    if (!testings) return;

    const characteristics = testings
      .map(testing => testing.groupedAnswers)
      .flat()
      .map(groupedAnswersResult => groupedAnswersResult.feedbackResult?.feedbackCompany)
      .map(feedbackResult => (feedbackResult ? feedbackResult : ''))
      .filter(feedbackResult => !!feedbackResult);

    generateCharacteristic(
      { characteristics },
      {
        onSuccess: response => {
          setValue('characteristic', response.result);
        }
      }
    );
  };

  const handleReset = () => {
    reset();
  };

  const onSubmit = (data: FormValues) => {
    console.log(data);
  };

  return (
    <div
      className={
        `p-[24px_24px_24px_32px] flex flex-col gap-[12px] ${className} ` +
        'rounded-[20px] bg-light elevation-2'
      }
    >
      <header className='flex justify-between items-center gap-[10px]'>
        <div className='flex flex-col gap-[4px]'>
          <h3 className='text-h3 text-on-surface'>Характеристика компании</h3>
          <p className='text-lm text-on-surface-variant'>по общим результатам исследований</p>
        </div>

        <div className='flex items-center gap-[8px]'>
          {newData && (
            <span className='text-bm text-error'>
              Есть новые результаты тестирований, <b className='font-[600]'>обновите данные</b>!
            </span>
          )}
          {editable && (
            <Button
              variant='tertiary'
              height='big'
              title='Сгенерировать'
              icon={<IconMagic className='h-[18px] w-[18px]' />}
              iconPosition='right'
              onClick={handleGenerate}
              isLoading={generateCharacteristicLoading || testingsLoading}
            />
          )}
        </div>
      </header>

      {editable ? (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-[12px]'>
          <TextEditor
            name='characteristic'
            placeholder='Характеристика компании по общим результатам тестирований, пройденных сотрудниками компании'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              validate: {
                HtmlToNotBeEmpty: htmlToNotBeEmpty
              }
            }}
          />

          <div className='flex gap-[8px] justify-end'>
            <Button
              variant='secondary'
              title='Сбросить'
              onClick={handleReset}
              disabled={!isDirty}
              className='min-w-[160px]'
            />
            <Button
              type='submit'
              variant='primary'
              title='Сохранить'
              // disabled={!isDirty}
              disabled
              className='min-w-[160px]'
            />
          </div>
        </form>
      ) : (
        <TextPreview value={DUMMY_COMPANY_CHARACTERISTIC} />
      )}
    </div>
  );
};

export default CompanyCharacteristic;
