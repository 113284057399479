import { useAuthStore } from 'store/auth';

export const useLogout = () => {
  const setLogin = useAuthStore(state => state.setLogin);

  const logout = () => {
    localStorage.removeItem('token');
    setLogin(false);
  };

  return logout;
};
