import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { ManagerRouteConfig } from 'const/routes';

import { SidebarLayout } from 'layouts';

const ManagerRoutes: FC = () => {
  return (
    <SidebarLayout>
      <Routes>
        {Object.values(ManagerRouteConfig).map(({ path, Component, element }) => (
          <Route key={path} path={path} Component={Component} element={element} />
        ))}
      </Routes>
    </SidebarLayout>
  );
};

export default ManagerRoutes;
