import { Option } from 'models/option';
import { TestFormValues, TestVisibility } from 'models/test(old)';
import { FormQuestionAnswerType, TestFormQuestion } from 'models/test-form';

export const DEFAULT_TEST_DURATION = 5;
export const DEFAULT_QUESTION_DURATION = 2000;

export const MINIMUM_TEST_REWARD = 1;
export const DEFAULT_TEST_REWARD = 100;

export const DEFAULT_TEST_FORM_VALUES: TestFormValues = {
  name: '',
  desc: '',
  duration: DEFAULT_TEST_DURATION,
  reward: DEFAULT_TEST_REWARD,
  visibility: TestVisibility.PUBLIC,
  groupedQuestions: [],
  openQuestions: [],
  formQuestions: []
};

export const EMPTY_FORM_QUESTION: TestFormQuestion = {
  question: '',
  answerOptions: [],
  formQuestionAnswerType: FormQuestionAnswerType.SINGLE,
  toProfileParam: ''
};

export const TEST_FORM_QUESTION_OPTIONS: Option[] = [
  { label: 'Один вариант ответа', value: FormQuestionAnswerType.SINGLE },
  { label: 'Несколько вариантов ответа', value: FormQuestionAnswerType.MULTIPLE },
  { label: 'Свободный ответ', value: FormQuestionAnswerType.TEXT }
];
