import { FC } from 'react';

import { IconChevronLeft, IconChevronRight } from 'components';

const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (_, i) => start + i);

type PaginationProps = {
  pageCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
};

const Pagination: FC<PaginationProps> = ({ pageCount, onPageChange, currentPage }) => {
  const MIN_PAGE = 1;
  const MAX_PAGE = pageCount;

  const calculatePages = () => {
    if (pageCount <= 5) {
      return range(1, pageCount + 1);
    }
    if (currentPage === 3) {
      return [...range(1, 5), '...', MAX_PAGE];
    }
    if (currentPage < 4) {
      return [...range(1, 4), '...', MAX_PAGE];
    }
    if (currentPage === pageCount - 2) {
      return [MIN_PAGE, '...', ...range(pageCount - 3, pageCount + 1)];
    }
    if (currentPage > pageCount - 3) {
      return [MIN_PAGE, '...', ...range(pageCount - 2, pageCount + 1)];
    }
    return [MIN_PAGE, '...', currentPage - 1, currentPage, currentPage + 1, '...', MAX_PAGE];
  };

  return (
    <div className='flex justify-center items-center gap-[16px] pb-[36px]'>
      <button
        type='button'
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={
          'h-[56px] w-[56px] flex justify-center items-center group ' +
          'bg-transparent border-solid border-[1px] border-[#C5C6D0] rounded-md text-on-primary-fixed ' +
          'select-none transition-all duration-200 ease-in-out ' +
          'enabled:hover:bg-[#3D5BA9] enabled:hover:text-[#FFFFFF] ' +
          'disabled:opacity-60 disabled:cursor-not-allowed'
        }
      >
        <IconChevronLeft className='h-[24px] w-[24px]' />
      </button>

      <ul className='flex gap-[16px]'>
        {calculatePages().map((page, i) => (
          <li key={i}>
            {typeof page === 'number' ? (
              <button
                key={page}
                onClick={() => onPageChange(page)}
                disabled={currentPage === page}
                className={
                  `${
                    currentPage === page
                      ? 'bg-[#3D5BA9] text-[#FFFFFF]'
                      : 'bg-transparent text-on-primary-fixed'
                  } ` +
                  'h-[56px] w-[56px] flex justify-center items-center text-ll ' +
                  'border-solid border-[1px] border-[#C5C6D0] rounded-md ' +
                  'select-none transition-all duration-200 ease-in-out ' +
                  'enabled:hover:bg-[#3D5BA9] enabled:hover:text-[#FFFFFF] disabled:cursor-auto'
                }
              >
                {page}
              </button>
            ) : (
              <p
                className={
                  'h-[56px] w-[56px] flex justify-center items-center ' +
                  'text-on-primary-fixed text-[16px] font-[400] leading-[24px] select-none'
                }
              >
                ...
              </p>
            )}
          </li>
        ))}
      </ul>

      <button
        type='button'
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === pageCount}
        className={
          'h-[56px] w-[56px] flex justify-center items-center group ' +
          'bg-transparent border-solid border-[1px] border-[#C5C6D0] rounded-md text-on-primary-fixed ' +
          'select-none transition-all duration-200 ease-in-out ' +
          'enabled:hover:bg-[#3D5BA9] enabled:hover:text-[#FFFFFF] ' +
          'disabled:opacity-60 disabled:cursor-not-allowed'
        }
      >
        <IconChevronRight className='h-[24px] w-[24px]' />
      </button>
    </div>
  );
};

export default Pagination;
