import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconFeedback: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' fill='none' {...props}>
      <path
        d='M5.925 15C7.35643 15.7343 9.00306 15.9332 10.5682 15.5608C12.1333 15.1885 13.5139 14.2694 14.4613 12.9691C15.4087 11.6689 15.8606 10.073 15.7354 8.46913C15.6103 6.86521 14.9164 5.35873 13.7789 4.22115C12.6413 3.08357 11.1348 2.38969 9.53088 2.26457C7.92697 2.13944 6.3311 2.59129 5.03086 3.53869C3.73063 4.48609 2.81152 5.86674 2.43917 7.43184C2.06682 8.99694 2.26571 10.6436 3 12.075L1.5 16.5L5.925 15Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='square'
        strokeLinejoin='round'
      />
      <path
        d='M11.85 6.9C11.4995 6.55645 11.0283 6.36401 10.5375 6.36401C10.0467 6.36401 9.57547 6.55645 9.22498 6.9L8.99998 7.2L8.73748 6.975C8.56554 6.78889 8.35655 6.64085 8.12394 6.5404C7.89133 6.43995 7.64027 6.38931 7.38692 6.39175C7.13356 6.39418 6.88352 6.44963 6.65288 6.55454C6.42225 6.65944 6.21614 6.81147 6.04781 7.00084C5.87948 7.19021 5.75266 7.41273 5.67552 7.65407C5.59838 7.89541 5.57263 8.15023 5.59991 8.40213C5.6272 8.65402 5.70692 8.89741 5.83395 9.11664C5.96098 9.33586 6.1325 9.52606 6.33748 9.675L9.03748 12.3L11.7375 9.675C12.6375 8.775 12.5625 7.65 11.8875 6.9'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='square'
        strokeLinejoin='round'
      />
    </svg>
  );
};
