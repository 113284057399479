import { FC } from 'react';
import { Link } from 'react-router-dom';

import AvatarFemalePlaceholder from 'assets/images/png/avatar-female-placeholder.png';
import AvatarMalePlaceholder from 'assets/images/png/avatar-male-placeholder.png';

import { ButtonIcon, IconLink } from 'components/atoms';
import { MasterPaths, MasterRoutes } from 'const/routes';
import { calculateAge, getGender } from 'lib';
import { User } from 'models/user';

type EmployeeCardProps = {
  employee: User;
  className?: string;
};

const EmployeeCard: FC<EmployeeCardProps> = props => {
  const {
    employee: { _id, id, name, gender, birthday, email },
    className
  } = props;

  return (
    <div
      className={
        'relative min-h-[180px] p-[24px_8px_24px_24px] ' +
        'flex gap-[24px] rounded-[20px] bg-light elevation-2 ' +
        className
      }
    >
      <Link
        to={`${MasterPaths[MasterRoutes.EMPLOYEES]}/${id || _id}`}
        className={'absolute top-[12px] right-[15px] ' + 'flex gap-[24px]'}
      >
        <ButtonIcon Icon={IconLink} variant='transparent' size='big' />
      </Link>

      <div
        className={
          'shrink-0 h-[64px] w-[64px] p-[12px_6px_0_6px] ' +
          'bg-primary-container rounded-[32px] overflow-hidden'
        }
      >
        <img
          src={gender ? AvatarMalePlaceholder : AvatarFemalePlaceholder}
          alt={name}
          className='h-full w-full object-cover'
        />
      </div>

      <div className='flex flex-col ellipsis'>
        <h4 title={name} className='mb-[4px] text-h4 text-on-surface ellipsis'>
          {name}
        </h4>

        <p
          title={`${getGender(gender)} ${birthday ? `, ${calculateAge(birthday)} лет` : ''}`}
          className='mb-[4px] text-lm text-on-surface-variant ellipsis'
        >
          {getGender(gender)} {birthday ? `, ${calculateAge(birthday)} лет` : ''}
        </p>

        <p title={email} className='text-lm text-primary ellipsis'>
          {email}
        </p>
      </div>
    </div>
  );
};

export default EmployeeCard;
