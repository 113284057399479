import { FC, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Button, IconChevronLeft, PublicationSelect } from 'components';
import { AdminPaths, AdminRoutes } from 'const/routes';
import { DEFAULT_TEST_FORM_VALUES } from 'const/test(old)';
import { useGetOpenQuestionsQuery, useUpdateOpenQuestionsMutation } from 'hooks/open-question';
import { useAddPromocodeToTests, useGetPromocodes } from 'hooks/promocode';
import {
  useGetTestQuestionsQuery,
  useGetTestsQuery,
  useUpdateTestMutation,
  useUpdateTestQuestionsMutation
} from 'hooks/test';
import { useGetTestFormQuestionsQuery, useUpdateTestFormQuestionsMutation } from 'hooks/test-form';
import { Test, TestFormValues, UpdateTestDto } from 'models/test(old)';
import { TEST_AUTO_SAVE_INTERVAL } from './const';
import { Tabs } from './ui/Tabs';

type Params = {
  testId: string;
};

const TestPage: FC = () => {
  const navigate = useNavigate();
  const { testId } = useParams<keyof Params>() as Params;
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutateAsync: updateTestMutate, isLoading: updateTestLoading } = useUpdateTestMutation();
  const { mutateAsync: updateTestFormQuestionsMutate, isLoading: updateTestFormQuestionsLoading } =
    useUpdateTestFormQuestionsMutation();
  const { mutateAsync: updateTestQuestionsMutate, isLoading: updateTestQuestionsLoading } =
    useUpdateTestQuestionsMutation();
  const { mutateAsync: updateOpenQuestionsMutate, isLoading: updateOpenQuestionsLoading } =
    useUpdateOpenQuestionsMutation();
  const { mutateAsync: addPromocodeToTestsMutate, isLoading: addPromocodeToTestsLoading } =
    useAddPromocodeToTests();

  const isUpdateLoading = useMemo<boolean>(
    () =>
      updateTestLoading ||
      updateTestFormQuestionsLoading ||
      updateTestQuestionsLoading ||
      updateOpenQuestionsLoading ||
      addPromocodeToTestsLoading,
    [
      updateTestLoading,
      updateTestFormQuestionsLoading,
      updateTestQuestionsLoading,
      updateOpenQuestionsLoading,
      addPromocodeToTestsLoading
    ]
  );

  const { data: tests, isLoading: testsLoading } = useGetTestsQuery();
  const { data: testFormQuestions, isLoading: testFormQuestionsLoading } =
    useGetTestFormQuestionsQuery({ testId });
  const { data: testQuestions, isLoading: testQuestionsLoading } = useGetTestQuestionsQuery({
    testId
  });
  const { data: openQuestions, isLoading: openQuestionsLoading } = useGetOpenQuestionsQuery({
    testId
  });
  const { data: promocodes, isLoading: promocodesLoading } = useGetPromocodes();
  const isGetLoading = useMemo<boolean>(
    () =>
      testsLoading ||
      testFormQuestionsLoading ||
      testQuestionsLoading ||
      openQuestionsLoading ||
      promocodesLoading,
    [
      testsLoading,
      testFormQuestionsLoading,
      testQuestionsLoading,
      openQuestionsLoading,
      promocodesLoading
    ]
  );

  const {
    control,
    handleSubmit,
    reset,
    trigger,
    watch,
    getValues,
    setValue,
    formState: { isDirty }
  } = useForm<TestFormValues>({
    mode: 'onSubmit',
    defaultValues: DEFAULT_TEST_FORM_VALUES
  });

  useEffect(() => {
    if (!tests || !testFormQuestions || !testQuestions || !openQuestions || !promocodes) return;

    const test = tests?.find(test => test._id === testId);
    if (!test) return;

    const fullTest: TestFormValues = {
      ...test,
      formQuestions: testFormQuestions.map(({ answerOptions, ...rest }) => ({
        answerOptions: answerOptions.map(value => ({ value })),
        ...rest
      })),
      openQuestions,
      groupedQuestions: testQuestions,
      promocode: promocodes
        .reverse()
        .find(promocode => promocode.availableTestsIds.includes(testId))?.code
    };

    reset(fullTest);
  }, [tests, testFormQuestions, testQuestions, openQuestions, promocodes]);

  const handleBack = () => {
    navigate(AdminPaths[AdminRoutes.TESTS]);
  };

  const handleReset = () => {
    reset();
  };

  const onSubmit = async (data: TestFormValues) => {
    searchParams.set('tab', '0');
    setSearchParams(searchParams);
    const result1 = await trigger();
    if (!result1) return;

    searchParams.set('tab', '1');
    setSearchParams(searchParams);
    const result2 = await trigger();
    if (!result2) return;

    searchParams.set('tab', '2');
    setSearchParams(searchParams);
    const result3 = await trigger();
    if (!result3) return;

    searchParams.set('tab', '3');
    setSearchParams(searchParams);
    const result4 = await trigger();
    if (!result4) return;

    const {
      name,
      mediaDTO,
      desc,
      duration,
      reward,
      visibility,
      groupedQuestions,
      openQuestions,
      formQuestions,
      promocode
    } = data;

    const updateTestBody: UpdateTestDto['body'] = {
      name,
      mediaDTO,
      desc,
      duration,
      reward,
      visibility
    };

    let updatedTest: Test | undefined = undefined;

    try {
      updatedTest = await updateTestMutate({ testId, body: updateTestBody });
    } catch (error) {
      console.log(error);
    }

    if (updatedTest) {
      if (formQuestions.length) {
        try {
          await updateTestFormQuestionsMutate({
            testId: updatedTest._id,
            body: formQuestions.map(({ answerOptions, ...rest }) => ({
              answerOptions: answerOptions.map(({ value }) => value),
              ...rest
            }))
          });
        } catch (error) {
          console.log(error);
        }
      }

      if (openQuestions.length) {
        try {
          await updateOpenQuestionsMutate({
            testId: updatedTest._id,
            body: openQuestions
          });
        } catch (error) {
          console.log(error);
        }
      }

      if (groupedQuestions.length) {
        try {
          await updateTestQuestionsMutate({
            testId: updatedTest._id,
            body: groupedQuestions
          });
        } catch (error) {
          console.log(error);
        }
      }

      const oldPromocode = promocodes?.find(promocode =>
        promocode.availableTestsIds.includes(testId)
      )?.code;

      if (promocode && promocode !== oldPromocode) {
        try {
          addPromocodeToTestsMutate({
            body: {
              code: promocode,
              validUntil: new Date().toISOString(),
              availableTestsIds: [updatedTest._id]
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  const saveTest = async () => {
    const {
      name,
      mediaDTO,
      desc,
      duration,
      reward,
      visibility,
      groupedQuestions,
      openQuestions,
      formQuestions,
      promocode
    } = getValues();

    const updateTestBody: UpdateTestDto['body'] = {
      name,
      mediaDTO,
      desc,
      duration,
      reward,
      visibility
    };

    let updatedTest: Test | undefined = undefined;

    try {
      updatedTest = await updateTestMutate({ testId, refetch: false, body: updateTestBody });
    } catch (error) {
      console.log(error);
    }

    if (updatedTest) {
      if (formQuestions.length) {
        try {
          await updateTestFormQuestionsMutate({
            testId: updatedTest._id,
            refetch: false,
            body: formQuestions.map(({ answerOptions, ...rest }) => ({
              answerOptions: answerOptions.map(({ value }) => value),
              ...rest
            }))
          });
        } catch (error) {
          console.log(error);
        }
      }

      if (openQuestions.length) {
        try {
          await updateOpenQuestionsMutate({
            testId: updatedTest._id,
            refetch: false,
            body: openQuestions
          });
        } catch (error) {
          console.log(error);
        }
      }

      if (groupedQuestions.length) {
        try {
          await updateTestQuestionsMutate({
            testId: updatedTest._id,
            refetch: false,
            body: groupedQuestions
          });
        } catch (error) {
          console.log(error);
        }
      }

      const oldPromocode = promocodes?.find(promocode =>
        promocode.availableTestsIds.includes(testId)
      )?.code;

      if (promocode && promocode !== oldPromocode) {
        try {
          addPromocodeToTestsMutate({
            refetch: false,
            body: {
              code: promocode,
              validUntil: new Date().toISOString(),
              availableTestsIds: [updatedTest._id]
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };

  // test auto save
  useEffect(() => {
    const intervalId = setInterval(() => {
      saveTest();
    }, TEST_AUTO_SAVE_INTERVAL);

    return () => clearTimeout(intervalId);
  }, []);

  if (isGetLoading) return <h2>Загрузка...</h2>;
  if (!tests || !testFormQuestions || !testQuestions) return <h2>Не удалось получить тест</h2>;

  return (
    <div className='flex flex-auto px-[32px] py-[40px] flex-col'>
      <header className='flex justify-between gap-[10px] mb-[36px]'>
        <div className='flex items-center gap-[10px]'>
          <button
            type='button'
            className={'h-[40px] w-[40px] bg-transparent justify-center items-center'}
            onClick={handleBack}
          >
            <IconChevronLeft className='h-[24px] w-[24px] text-primary' />
          </button>
          <h3 className='text-h3 text-[#333]'>Редактирование теста</h3>
        </div>

        <PublicationSelect control={control} />
      </header>

      <form className='flex flex-auto flex-col' onSubmit={handleSubmit(onSubmit)}>
        <Tabs
          control={control}
          trigger={trigger}
          watch={watch}
          getValues={getValues}
          setValue={setValue}
          className='grow'
        />

        <div className='flex self-end gap-[8px]'>
          <Button
            type='reset'
            variant='secondary'
            title='Отменить'
            className='min-w-[160px]'
            onClick={handleReset}
            isDisabled={!isDirty || isUpdateLoading}
          />
          <Button
            type='submit'
            variant='primary'
            title='Сохранить'
            className='min-w-[160px]'
            isDisabled={!isDirty}
            isLoading={isUpdateLoading}
          />
        </div>
      </form>
    </div>
  );
};

export default TestPage;
