import { FC } from 'react';

import { IconTime } from 'components';

type TimeSmallProps = {
  time: {
    hours: number;
    minutes: number;
    seconds: number;
  };
};

const TimeSmall: FC<TimeSmallProps> = ({ time }) => {
  const { hours, minutes, seconds } = time;

  return (
    <li
      className={
        'h-[32px] p-[8px_16px_8px_16px] flex items-center gap-[8px] ' +
        'bg-primary-container rounded-[28px]'
      }
    >
      <IconTime className='h-[18px] w-[18px] text-on-primary-fixed' />
      {!!hours && <span className='text-ll text-on-primary-fixed'>{hours} часов</span>}
      {!!minutes && <span className='text-ll text-on-primary-fixed'>{minutes} минут</span>}
      {!!seconds && <span className='text-ll text-on-primary-fixed'>{seconds} секунд</span>}
    </li>
  );
};

export default TimeSmall;
