import { Navigate, RouteProps } from 'react-router-dom';

import { IconCompany, IconEmployee, IconHome, IconTest } from 'components';
import {
  CompanyPage,
  CreateTestPage,
  EmployeePage,
  EmployeesPage,
  TestPage,
  TestsPage
} from 'pages/master';
import { ProfilePage } from 'pages/shared';

export enum MasterRoutes {
  MAIN = 'main',
  COMPANY = 'company',
  TESTS = 'tests',
  TEST = 'test',
  CREATE_TEST = 'create-test',
  DEPARTAMENTS = 'departaments',
  EMPLOYEES = 'employees',
  EMPLOYEE = 'employee',
  PROFILE = 'profile',
  NOT_FOUND = 'not-found'
}

export const MasterPaths: Record<MasterRoutes, string> = {
  [MasterRoutes.MAIN]: '/',
  [MasterRoutes.COMPANY]: '/company',
  [MasterRoutes.TESTS]: '/tests',
  [MasterRoutes.TEST]: '/tests/:testId',
  [MasterRoutes.CREATE_TEST]: '/tests/create',
  [MasterRoutes.DEPARTAMENTS]: '/departments',
  [MasterRoutes.EMPLOYEES]: '/employees',
  [MasterRoutes.EMPLOYEE]: '/employees/:employeeId',
  [MasterRoutes.PROFILE]: '/profile',
  [MasterRoutes.NOT_FOUND]: '*'
};

export const masterNavigationLinks: {
  title: string;
  link: string;
  Icon: typeof IconHome;
  disabled?: boolean;
}[] = [
  { title: 'Главная', link: MasterPaths[MasterRoutes.MAIN], Icon: IconHome, disabled: true },
  { title: 'Компания', link: MasterPaths[MasterRoutes.COMPANY], Icon: IconCompany },
  { title: 'Отделы', link: MasterPaths[MasterRoutes.DEPARTAMENTS], Icon: IconTest, disabled: true },
  { title: 'Сотрудники', link: MasterPaths[MasterRoutes.EMPLOYEES], Icon: IconEmployee },
  { title: 'Тесты', link: MasterPaths[MasterRoutes.TESTS], Icon: IconTest }
];

export const MasterRouteConfig: Record<MasterRoutes, RouteProps> = {
  [MasterRoutes.MAIN]: {
    path: MasterPaths[MasterRoutes.MAIN],
    element: <Navigate to={MasterPaths[MasterRoutes.COMPANY]} replace />
  },
  [MasterRoutes.COMPANY]: { path: MasterPaths[MasterRoutes.COMPANY], Component: CompanyPage },
  [MasterRoutes.TESTS]: {
    path: MasterPaths[MasterRoutes.TESTS],
    Component: TestsPage
  },
  [MasterRoutes.TEST]: {
    path: MasterPaths[MasterRoutes.TEST],
    Component: TestPage
  },
  [MasterRoutes.CREATE_TEST]: {
    path: MasterPaths[MasterRoutes.CREATE_TEST],
    Component: CreateTestPage
  },
  [MasterRoutes.DEPARTAMENTS]: {
    path: MasterPaths[MasterRoutes.DEPARTAMENTS],
    element: <Navigate to={MasterPaths[MasterRoutes.COMPANY]} replace />
  },
  [MasterRoutes.EMPLOYEES]: {
    path: MasterPaths[MasterRoutes.EMPLOYEES],
    Component: EmployeesPage
  },
  [MasterRoutes.EMPLOYEE]: {
    path: MasterPaths[MasterRoutes.EMPLOYEE],
    Component: EmployeePage
  },
  [MasterRoutes.PROFILE]: { path: MasterPaths[MasterRoutes.PROFILE], Component: ProfilePage },
  [MasterRoutes.NOT_FOUND]: {
    path: MasterPaths[MasterRoutes.NOT_FOUND],
    element: <Navigate to={MasterPaths[MasterRoutes.COMPANY]} replace />
  }
};
