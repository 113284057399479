import { FC } from 'react';

import { GroupedAnswer } from 'components';
import { GroupedAnswersResult } from 'models/testing';

type TabFeedbacksProps = {
  groupedAnswers: GroupedAnswersResult[];
  className?: string;
};

const TabFeedbacks: FC<TabFeedbacksProps> = props => {
  const { groupedAnswers, className } = props;

  return (
    <ul className={`flex flex-col gap-[16px] ${className}`}>
      {groupedAnswers.map((groupedAnswer, index) => (
        <GroupedAnswer
          key={index}
          groupedAnswer={groupedAnswer}
          show='feedback'
          order={index + 1}
          className={index === groupedAnswers.length - 1 ? 'mb-[40px]' : ''}
        />
      ))}
    </ul>
  );
};

export default TabFeedbacks;
