import { FC, useMemo, useState } from 'react';

import { useUserRole } from 'hooks/auth';
import { AnswerResult } from 'models/testing';
import { USER_ROLES } from 'models/user';

import {
  Button,
  Feedback,
  IconDraft,
  InnerAnswerGraphs,
  Modal,
  Ratio,
  Recommendation
} from 'components';
import { CalibrationData } from 'models/calibration';

type InnerAnswerProps = {
  innerAnswer: AnswerResult;
  calibration?: CalibrationData;
  show: 'feedback' | 'recommendation' | 'calibration' | 'both';
  groupOrder: number;
  order: number;
  className?: string;
};

const InnerAnswer: FC<InnerAnswerProps> = props => {
  const {
    innerAnswer,
    innerAnswer: { question, feedbackResult, answer, correlationResultAverageXYZ },
    calibration,
    show,
    groupOrder,
    order,
    className
  } = props;
  const userRole = useUserRole();
  const [isShowGraphs, setShowGraphs] = useState(false);

  const answerScore = useMemo(
    () => Math.round(correlationResultAverageXYZ?.accelerometer || 0),
    [correlationResultAverageXYZ]
  );

  const { feedback, recommendation } = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.ADMIN:
      case USER_ROLES.MASTER:
      case USER_ROLES.MANAGER:
      default:
        return {
          feedback: feedbackResult?.feedbackCompany,
          recommendation: feedbackResult?.recommendationCompany
        };
    }
  }, [userRole, feedbackResult]);

  return (
    <div className={`py-[16px] flex flex-col gap-[16px] ${className}`}>
      <header className='flex min-h-[40px] justify-between items-center gap-[8px]'>
        <h4 className='text-h4 text-on-surface'>
          {groupOrder}-{order}. {question}
        </h4>

        <Ratio value={answerScore} />
      </header>

      <div className='flex justify-between items-start gap-[8px]'>
        <p className='text-lm text-secondary ellipsis'>Ответ пользователя: {answer} </p>

        {/* disable graphs for grouped by test testings */}
        {!!calibration && (
          <Button
            title='Графики'
            variant='secondary'
            height='small'
            icon={<IconDraft />}
            onClick={() => setShowGraphs(true)}
          />
        )}
      </div>

      {show === 'feedback' && feedback && <Feedback feedback={feedback} />}
      {show === 'recommendation' && recommendation && (
        <Recommendation recommendation={recommendation} />
      )}
      {show === 'both' && feedback && recommendation && (
        <>
          <Feedback feedback={feedback} />
          <Recommendation recommendation={recommendation} />
        </>
      )}

      <Modal
        isOpen={isShowGraphs}
        onClose={() => setShowGraphs(false)}
        portal
        className='pb-[40px] flex flex-col overflow-y-auto overflow-x-hidden'
      >
        <InnerAnswerGraphs
          innerAnswer={innerAnswer}
          calibration={calibration}
          groupOrder={groupOrder}
          order={order}
        />
      </Modal>
    </div>
  );
};

export default InnerAnswer;
