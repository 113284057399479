import { FC } from 'react';
import { Control } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';
import { MINIMUM_TEST_REWARD } from 'const/test(old)';
import { TestFormValues } from 'models/test(old)';

import { InputNumber } from 'components';

type TestRewardProps = {
  control: Control<TestFormValues>;
  className?: string;
};

const TestReward: FC<TestRewardProps> = ({ control, className }) => {
  return (
    <div className={`flex flex-col ${className}`}>
      <header className='flex items-center h-[56px] mb-[16px]'>
        <h3 className='text-h3 text-on-secondary-fixed'>
          Благодарность пользователю за прохождение теста
        </h3>
      </header>

      <div className='flex items-center gap-[8px]'>
        <label htmlFor='reward' className='text-h5 text-on-surface-variant'>
          Награда за прохождение теста
        </label>

        <InputNumber
          name='reward'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            min: {
              value: MINIMUM_TEST_REWARD,
              message: `Минимальное значение: ${MINIMUM_TEST_REWARD}`
            }
          }}
          containerClassName='w-[80px] shrink-0'
        />

        <label htmlFor='reward' className='text-h5 text-on-surface-variant'>
          баллов
        </label>
      </div>
    </div>
  );
};

export default TestReward;
