import { useRef, useState } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';
import ReactQuill from 'react-quill';

import { toolbarOptions } from './const';

import { IconError } from 'components';

type InputProps = {
  name: string;
  placeholder?: string;
  label?: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  readonly?: boolean;
};

export type TextEditorProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const TextEditor = <T extends FieldValues>({
  name,
  placeholder,
  label,
  control,
  rules,
  className,
  containerClassName,
  disabled,
  loading,
  readonly,
  ...rest
}: TextEditorProps<T>) => {
  const ref = useRef<ReactQuill>(null);
  const [inputFocus, setInputFocus] = useState(false);
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const handleClick = () => {
    if (inputFocus || disabled || readonly) return;
    ref.current?.focus();
  };

  const handleChange = (value: string) => {
    field.onChange(value);
  };

  return (
    <label
      htmlFor={name}
      className={
        'relative flex-1 flex flex-col cursor-text ' +
        `${containerClassName ? containerClassName : ''}`
      }
      onClick={handleClick}
    >
      {label && (
        <label
          htmlFor={name}
          className={
            'absolute font-[400] select-none pointer-events-none ' +
            'duration-[125ms] ease-out transition-all ' +
            `${
              inputFocus || (!!field.value && field.value !== '<p><br></p>')
                ? 'top-[-8px] left-[17px] bg-surface px-[4px] text-[12px] leading-[16px]'
                : 'top-[16px] left-[16px] text-bl'
            } ` +
            `${inputFocus ? 'text-primary' : 'text-on-surface'} ` +
            `${fieldState.error ? 'text-error' : ''} `
          }
        >
          {label}
        </label>
      )}

      <ReactQuill
        id={name}
        ref={ref}
        theme='snow'
        placeholder={label ? '' : placeholder}
        readOnly={readonly || disabled || loading}
        value={field.value || ''}
        onChange={handleChange}
        onFocus={() => !disabled && !readonly && setInputFocus(true)}
        onBlur={() => !disabled && !readonly && setInputFocus(false)}
        modules={{ toolbar: toolbarOptions }}
        className={
          'w-full rounded-[4px] border-solid ' +
          'duration-300 ease-out transition-colors ' +
          `${
            inputFocus && !fieldState.error
              ? 'border-primary border-[2px] p-[15px]'
              : 'border-outline-variant hover:border-on-surface border-[1px] p-[16px]'
          } ` +
          `${disabled ? 'bg-surface' : 'bg-light'} ` +
          `${fieldState.error ? 'mb-[4px] !border-error border-[2px] pr-[48px]' : ''} ` +
          `${className ? className : ''}`
        }
        {...rest}
      />

      {!!fieldState.error && (
        <>
          <span className='ml-[16px] text-bs text-error'>{fieldState.error.message}</span>

          <span
            title={fieldState.error.message}
            className='absolute top-[16px] right-[12px] h-[24px] w-[24px]'
          >
            <IconError color='#BA1A1A' className='h-full w-full' />
          </span>
        </>
      )}
    </label>
  );
};

export default TextEditor;
