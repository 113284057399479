import { Tab } from '@headlessui/react';
import { FC, Fragment, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GroupedAnswersResult } from 'models/testing';
import { tabs } from '../../const';
import { TabFeedbacks } from '../TabFeedbacks';
import { TabRecommendations } from '../TabRecommendations';
import { TabUsers } from '../TabUsers';

type TabsProps = {
  groupedAnswers: GroupedAnswersResult[];
  userIds: string[];
  className?: string;
};

const Tabs: FC<TabsProps> = props => {
  const { groupedAnswers, userIds, className } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedIndex = useMemo(() => {
    return Number(searchParams.get('tab')) || 0;
  }, [searchParams]);

  const handleTabChange = (index: number) => {
    searchParams.set('tab', index.toString());
    setSearchParams(searchParams);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <Tab.Group as={Fragment} selectedIndex={selectedIndex} onChange={handleTabChange}>
        <Tab.List
          className={
            'mb-[36px] flex gap-[2px] flex-wrap border-b-[1px] border-solid border-[#C5C6D0]'
          }
        >
          {tabs.map(tab => (
            <Tab
              key={tab}
              className={({ selected }) =>
                `${selected ? 'bg-secondary-container' : 'bg-[var(--color-primary-008)]'} ` +
                'h-[40px] py-[10px] px-[40px] text-ll text-on-secondary-fixed ' +
                'rounded-[16px_16px_0_0] duration-200'
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels as={Fragment}>
          <Tab.Panel>
            <TabFeedbacks groupedAnswers={groupedAnswers} />
          </Tab.Panel>

          <Tab.Panel>
            <TabRecommendations groupedAnswers={groupedAnswers} />
          </Tab.Panel>

          <Tab.Panel>
            <TabUsers userIds={userIds} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Tabs;
