import { useMutation, useQueryClient } from 'react-query';

import { setTestFormQuestions } from 'api/test-form';
import { handleError, notifySuc } from 'helpers';
import { SetTestFormQuestionsDto } from 'models/test-form';

export const useSetTestFormQuestionsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: SetTestFormQuestionsDto) => {
      return setTestFormQuestions(data);
    },
    {
      onSuccess(newData, { testId }) {
        notifySuc('Анкета теста добавлена');

        const queryKey = `tests/${testId}/form`;

        queryClient.invalidateQueries(queryKey, {
          refetchActive: false,
          refetchInactive: false
        });

        queryClient.setQueryData(queryKey, newData);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
