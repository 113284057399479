import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconChevronLeft: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g id='Icons'>
        <path
          id='Vector'
          d='M10 12L6 8L10 4'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='square'
        />
      </g>
    </svg>
  );
};
