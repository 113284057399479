import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { apiTest } from 'api/test';
import { handleError } from 'helpers';
import { GetTestQuestionsDto, GetTestQuestionsResponse } from 'models/test(old)';

export const useGetTestQuestionsQuery = ({ testId }: GetTestQuestionsDto) => {
  return useQuery<GetTestQuestionsResponse, AxiosError>(
    [`tests/${testId}/questions`],
    () => apiTest.getTestQuestions({ testId }),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
