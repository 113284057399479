import { FC } from 'react';

import { AccelerometerChart } from 'components';
import { CalibrationData } from 'models/calibration';
import { AnswerResult } from 'models/testing';

type InnerAnswerGraphsProps = {
  innerAnswer: AnswerResult;
  calibration?: CalibrationData;
  groupOrder: number;
  order: number;
  className?: string;
};

export const InnerAnswerGraphs: FC<InnerAnswerGraphsProps> = props => {
  const {
    innerAnswer: { question, accelerometerData, accelerometerFlippedData },
    calibration,
    groupOrder,
    order,
    className
  } = props;

  return (
    <div className={`w-[720px] flex flex-col ${className}`}>
      <h4 className='mb-[32px] text-h4 text-on-surface'>
        {groupOrder}-{order}. {question}
      </h4>

      <AccelerometerChart
        title='Прямой вопрос'
        accelerometerData={accelerometerData}
        calibration={calibration}
        className='mb-[32px]'
      />

      {accelerometerFlippedData && (
        <AccelerometerChart
          title='Перевернутый вопрос'
          accelerometerData={accelerometerFlippedData}
          calibration={calibration}
        />
      )}
    </div>
  );
};
