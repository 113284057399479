import { useMutation, useQueryClient } from 'react-query';

import { updateCompanyCode } from 'api/company';
import { COMPANIES_QUERY_KEY } from 'const/company';
import { handleError, notifySuc } from 'helpers';
import { UpdateCompanyCodeDto } from 'models/company';

export const useUpdateCompanyMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateCompanyCodeDto) => {
      return updateCompanyCode(data);
    },
    {
      onSuccess(updatedCompany) {
        notifySuc('Данные компании обновлены');

        const queryKey = [COMPANIES_QUERY_KEY, updatedCompany._id];
        queryClient.invalidateQueries(queryKey);

        // TODO update cache when Backend will send updated company (not old)
        // queryClient.invalidateQueries(queryKey, {
        //   refetchActive: false,
        //   refetchInactive: false
        // });

        // const oldData = queryClient.getQueryData<GetCompanyResponse>(queryKey);
        // console.log('oldData', oldData);
        // console.log('updatedCompany', updatedCompany);
        // if (oldData) {
        //   queryClient.setQueryData(queryKey, updatedCompany);
        // }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
