import { FC, ReactNode } from 'react';

interface DefaultLayoutProps {
  children: ReactNode;
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  return (
    <div className='wrapper'>
      <main className='main bg-surface relative'>
        <div className='container'>{children}</div>
      </main>
    </div>
  );
};

export default DefaultLayout;
