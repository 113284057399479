import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconEyeHide: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_143_5397)'>
        <path
          d='M12.2949 7C15.0549 7 17.2949 9.24 17.2949 12C17.2949 12.65 17.1649 13.26 16.9349 13.83L19.8549 16.75C21.3649 15.49 22.5549 13.86 23.2849 12C21.5549 7.61 17.2849 4.5 12.2849 4.5C10.8849 4.5 9.54492 4.75 8.30492 5.2L10.4649 7.36C11.0349 7.13 11.6449 7 12.2949 7ZM2.29492 4.27L4.57492 6.55L5.03492 7.01C3.37492 8.3 2.07492 10.02 1.29492 12C3.02492 16.39 7.29492 19.5 12.2949 19.5C13.8449 19.5 15.3249 19.2 16.6749 18.66L17.0949 19.08L20.0249 22L21.2949 20.73L3.56492 3L2.29492 4.27ZM7.82492 9.8L9.37492 11.35C9.32492 11.56 9.29492 11.78 9.29492 12C9.29492 13.66 10.6349 15 12.2949 15C12.5149 15 12.7349 14.97 12.9449 14.92L14.4949 16.47C13.8249 16.8 13.0849 17 12.2949 17C9.53492 17 7.29492 14.76 7.29492 12C7.29492 11.21 7.49492 10.47 7.82492 9.8ZM12.1349 9.02L15.2849 12.17L15.3049 12.01C15.3049 10.35 13.9649 9.01 12.3049 9.01L12.1349 9.02Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_143_5397'>
          <rect width='24' height='24' fill='currentColor' transform='translate(0.294922)' />
        </clipPath>
      </defs>
    </svg>
  );
};
