import { useMutation, useQueryClient } from 'react-query';

import { createAdmin } from 'api/auth';
import { handleError, notifySuc } from 'helpers';
import { CreateAdminDto } from 'models/auth';

export const useCreateAdmin = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: CreateAdminDto) => {
      return createAdmin(dto);
    },
    {
      onSuccess() {
        notifySuc('Администратор создан');

        queryClient.invalidateQueries(['account/users']);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
