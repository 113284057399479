import { useMutation, useQueryClient } from 'react-query';

import { apiTest } from 'api/test';
import { TESTS_QUERY_KEY } from 'const/test';
import { handleError, notifySuc } from 'helpers';
import { CreateTestDto, GetTestsResponse } from 'models/test(old)';

export const useCreateTestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: CreateTestDto) => {
      return apiTest.createTest(data);
    },
    {
      onSuccess(createdTest) {
        notifySuc('Тест создан');

        queryClient.invalidateQueries(TESTS_QUERY_KEY, {
          refetchActive: false,
          refetchInactive: false
        });

        const oldData = queryClient.getQueryData<GetTestsResponse>(TESTS_QUERY_KEY);
        if (oldData) {
          const updatedData = [...oldData, createdTest];
          queryClient.setQueryData(TESTS_QUERY_KEY, updatedData);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
