import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconMagic: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' fill='none' {...props}>
      <path
        d='M9 2.25L7.566 6.60975C7.49261 6.83288 7.36785 7.03566 7.20175 7.20175C7.03566 7.36785 6.83288 7.49261 6.60975 7.566L2.25 9L6.60975 10.434C6.83288 10.5074 7.03566 10.6322 7.20175 10.7982C7.36785 10.9643 7.49261 11.1671 7.566 11.3903L9 15.75L10.434 11.3903C10.5074 11.1671 10.6322 10.9643 10.7982 10.7982C10.9643 10.6322 11.1671 10.5074 11.3903 10.434L15.75 9L11.3903 7.566C11.1671 7.49261 10.9643 7.36785 10.7982 7.20175C10.6322 7.03566 10.5074 6.83288 10.434 6.60975L9 2.25Z'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.75 2.25V5.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.25 12.75V15.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.25 3.75H5.25'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.75 14.25H15.75'
        stroke='currentColor'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
