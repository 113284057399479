import { useMutation } from 'react-query';

import { generateFeedbackForGroup } from 'api/microservice';
import { handleError, notifySuc } from 'helpers';
import { GenerateFeedbackForGroupDto } from 'models/microservice';

export const useGenerateFeedbackForGroup = () => {
  return useMutation(
    (dto: GenerateFeedbackForGroupDto) => {
      return generateFeedbackForGroup(dto);
    },
    {
      onSuccess() {
        notifySuc('Данные сгенерированы');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
