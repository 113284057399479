import { QuestionGroup } from 'models/question';

export type Test = {
  _id?: string;
  title: string;
  description: string;
  type: TestType;
  questionGroups: QuestionGroup[];
  results: TestResult[];
  isEqualDuration: boolean;
  duration?: number;
  gratitude: string;
  published?: Published;
  companies?: Company[];
};

export enum TestType {
  BINARY = 'binary'
}

export type TestResult = {
  from: number;
  to: number;
  resultForCompany?: string;
  recommendationForCompany?: string;
  resultForEmployee?: string;
  recommendationForEmployee?: string;
};

export enum Published {
  FOR_ALL = 'all',
  FOR_COMPANIES = 'companies',
  UNPUBLISHED = 'unpublished'
}

export type Company = {
  id: string;
  name: string;
  industry: string;
  avatarUrl: string;
};

export type TestFormValues = Omit<Test, '_id'>;

// temporary. Remove it later.
export type TestPreview = {
  id: string;
  title: string;
  description: string;
  duration: number;
  questionAmount: number;
  published: Published;
};
