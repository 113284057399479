import { FC, useMemo } from 'react';

import { Button, ButtonIcon, IconCross, IconDownload, Ratio } from 'components';
import { useGetTestQuestionsQuery } from 'hooks/test';
import { getAverageScore } from 'lib';
import { GroupedAnswersResult, GroupedByTestTesting } from 'models/testing';
import { getAverageGroupedAnswers } from '../../lib/getAverageGroupedAnswers';
import { getUniqueUserIds } from '../../lib/getUniqueUserIds';
import { Filters } from '../Filters';
import { Tabs } from '../Tabs';

type GroupedByTestTestingResultProps = {
  groupedByTestTesting: GroupedByTestTesting;
  onClose: () => void;
  className?: string;
};

const GroupedByTestTestingResult: FC<GroupedByTestTestingResultProps> = props => {
  const {
    groupedByTestTesting,
    groupedByTestTesting: { testId, testName, testings },
    onClose
  } = props;
  const averageScore = useMemo(() => getAverageScore(groupedByTestTesting), [groupedByTestTesting]);
  const { data: testQuestions } = useGetTestQuestionsQuery({ testId });

  const groupedAnswers = useMemo<GroupedAnswersResult[]>(
    () => getAverageGroupedAnswers(averageScore, testings, testQuestions),
    [averageScore, testings, testQuestions]
  );

  const userIds = useMemo(() => getUniqueUserIds(testings), [testings]);

  return (
    <div className='grow flex flex-col'>
      <header className='mb-[36px] flex justify-between gap-[8px] items-center'>
        <h3 className='text-h3 text-on-surface'>Результаты тестирования</h3>

        <div className='flex items-center gap-[40px]'>
          <Button
            variant='primary'
            height='small'
            title='Скачать в .pdf'
            icon={<IconDownload className='h-[18px] w-[18px]' />}
            iconPosition='right'
            isDisabled
          />
          <ButtonIcon Icon={IconCross} variant='transparent' size='big' onClick={onClose} />
        </div>
      </header>

      <div className='mb-[36px] flex gap-[8px]'>
        <h4 className='text-h4 text-tertiary'>Тест:</h4>
        <h4 className='text-h4 text-on-surface'>{testName}</h4>
      </div>

      <Filters className='mb-[36px]' />

      <div className='mb-[36px] flex justify-between items-center gap-[8px]'>
        <div className='flex items-center gap-[8px]'>
          <h4 className='text-h4 text-tertiary'>Количество участников:</h4>
          <h4 className='text-h4 text-on-surface'>{userIds.length}</h4>
        </div>

        <Ratio value={averageScore} />
      </div>

      <Tabs groupedAnswers={groupedAnswers} userIds={userIds} className='mb-[36px]' />
    </div>
  );
};

export default GroupedByTestTestingResult;
