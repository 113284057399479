import { SelectHTMLAttributes, useMemo, useState } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';
import RSelect, { SingleValue, StylesConfig } from 'react-select';

import { Option } from 'models/option';

const defaultSelectStyles: StylesConfig = {
  control: (styles, { isFocused, isDisabled }) => ({
    ...styles,
    height: '56px',
    minWidth: '100px',
    padding: isFocused ? '15px' : '16px',
    background: isDisabled ? '#EEF0FF' : '#FFFFFF',
    borderStyle: 'solid',
    borderWidth: isFocused ? '2px' : '1px',
    borderColor: isFocused ? '#3D5BA9' : '#C5C6D0',
    '&:hover': {
      borderWidth: isFocused ? '2px' : '1px',
      borderColor: isFocused ? '#3D5BA9' : '#C5C6D0'
    },
    boxShadow: isFocused ? 'none' : 'inherit',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    transition: 'none'
  }),
  menu: styles => ({
    ...styles,
    zIndex: 5
  }),
  option: (styles, { data, isFocused, isDisabled }) => ({
    ...styles,
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: (data as Option).color || '#1B1B1F',
    backgroundColor: isFocused ? '#EFF1F4' : 'none',
    '&:active': {
      backgroundColor: '#EFF1F4'
    },
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  singleValue: (styles, { data, isDisabled }) => ({
    ...styles,
    margin: '0',
    paddingRight: '0',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: (data as Option).color || '#1B1B1F',
    cursor: isDisabled ? 'not-allowed' : 'pointer'
  }),
  dropdownIndicator: (styles, { isFocused }) => ({
    ...styles,
    color: '#45464F',
    rotate: isFocused ? '180deg' : '0deg',
    transition: 'all 200ms ease-out'
  })
};

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  name: string;
  options: Option[];
  placeholder?: string;
  label?: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  height?: number | string;
  width?: number | string;
  borderRadius?: number | string;
};

export type SelectFieldProps<T extends FieldValues> = UseControllerProps<T> & SelectProps;

const Select = <T extends FieldValues>({
  name,
  options,
  placeholder = '',
  label,
  control,
  rules = {},
  className,
  containerClassName,
  disabled = false,
  loading = false,
  height,
  width,
  borderRadius
}: SelectFieldProps<T>) => {
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const [focus, setFocus] = useState(false);

  const selectStyles: StylesConfig = useMemo(
    () => ({
      ...defaultSelectStyles,
      control: (styles, { isFocused, isDisabled }) => ({
        ...styles,
        marginBottom: fieldState.error ? '4px' : '',
        minWidth: '100px',
        height: height || '56px',
        width: width,
        borderRadius: borderRadius,
        padding: fieldState.error ? '15px' : isFocused ? '15px' : '16px',
        background: isDisabled ? '#EEF0FF' : '#FFFFFF',
        borderStyle: 'solid',
        borderWidth: fieldState.error ? '2px' : isFocused ? '2px' : '1px',
        borderColor: fieldState.error ? '#BA1A1A' : isFocused ? '#3D5BA9' : '#C5C6D0',
        '&:hover': 'none',
        boxShadow: isFocused ? 'none' : 'inherit',
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        transition: 'none'
      })
    }),
    [fieldState.error]
  );

  return (
    <div className={`relative flex flex-col ${containerClassName}`}>
      {label && (
        <label
          htmlFor={name}
          className={
            'absolute font-[400] z-[1] ' +
            'select-none duration-[125ms] ease-out transition-all ' +
            `${
              focus || field.value !== undefined
                ? 'top-[-8px] left-[17px] bg-surface px-[4px] text-[12px] leading-[16px]'
                : 'top-[16px] left-[16px] text-bl'
            } ` +
            `${focus ? 'text-primary' : 'text-on-surface'} ` +
            `${fieldState.error ? 'text-error' : ''} `
          }
          onClick={() => setFocus(true)}
        >
          {label}
        </label>
      )}

      <RSelect
        value={options.find(({ value }) => value === field.value)}
        classNamePrefix='custom-select'
        styles={selectStyles}
        placeholder={label ? '' : placeholder}
        options={options}
        onChange={(newValue: SingleValue<unknown>) => {
          field.onChange((newValue as Option).value);
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        isSearchable={false}
        className={`${fieldState.error ? '!border-error border-[2px]' : ''} ` + `${className}`}
        isDisabled={disabled}
        isLoading={loading}
      />

      {!!fieldState.error && (
        <span className='ml-[16px] text-bs text-error'>{fieldState.error.message}</span>
      )}
    </div>
  );
};

export default Select;
