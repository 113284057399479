import { FC } from 'react';
import { Link } from 'react-router-dom';

import {
  AdminPaths,
  AdminRoutes,
  ManagerPaths,
  ManagerRoutes,
  MasterPaths,
  MasterRoutes
} from 'const/routes';
import { useUserRole } from 'hooks/auth';
import { USER_ROLES } from 'models/user';

type TabUsersProps = {
  userIds: string[];
  className?: string;
};

const TabUsers: FC<TabUsersProps> = props => {
  const { userIds, className } = props;
  const viewerRole = useUserRole();

  return (
    <div className={`flex ${className}`}>
      <ul className='flex flex-col'>
        {userIds.map(userId => (
          <li key={userId}>
            <Link
              to={
                viewerRole === USER_ROLES.ADMIN
                  ? `${AdminPaths[AdminRoutes.USERS]}/${userId}`
                  : viewerRole === USER_ROLES.MASTER
                  ? `${MasterPaths[MasterRoutes.EMPLOYEES]}/${userId}`
                  : `${ManagerPaths[ManagerRoutes.EMPLOYEES]}/${userId}`
              }
              className='text-h5 text-tertiary'
            >
              {userId}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabUsers;
