import axios from 'axios';

import { notifyErr } from './notification';

// TODO move to const folder
export const DEFAULT_ERROR_MESSAGE = 'Что-то пошло не так';

export function handleError(error: unknown) {
  if (axios.isAxiosError(error) && error.response) {
    const errorMessage = error.response.data.message;
    notifyErr(errorMessage);
  } else {
    notifyErr(DEFAULT_ERROR_MESSAGE);
  }
}
