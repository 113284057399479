import { ButtonHTMLAttributes, FC, MouseEvent, useCallback } from 'react';

import { IconSpinner } from 'components';

type ButtonIconProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  Icon: typeof IconSpinner;
  variant?: 'filled' | 'transparent';
  size?: 'big' | 'small';
  className?: string;
  onClick?: () => void;
  isDisabled?: boolean;
  isLoading?: boolean;
};

const ButtonIcon: FC<ButtonIconProps> = ({
  Icon,
  type = 'button',
  variant = 'filled',
  size = 'small',
  className,
  onClick,
  isDisabled = false,
  isLoading = false,
  ...props
}) => {
  const getVariantClass = useCallback(() => {
    switch (variant) {
      case 'transparent': {
        return (
          'bg-transparent text-primary hover:enabled:bg-primary-opacity-008 ' +
          'focus:enabled:bg-primary-opacity-012 active:enabled:bg-primary-opacity-012 ' +
          'disabled:text-on-surface'
        );
      }
      case 'filled': {
        return (
          'bg-surface-variant text-primary hover:enabled:bg-primary-opacity-008 ' +
          'focus:enabled:bg-primary-opacity-012 active:enabled:bg-primary-opacity-012 ' +
          'disabled:bg-on-surface-transparent disabled:text-on-surface-variant'
        );
      }
    }
  }, [variant]);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (onClick) {
      onClick();
    }
  };

  return (
    <button
      type={type}
      onClick={handleClick}
      className={
        'shrink-0 flex items-center justify-center disabled:cursor-auto ' +
        'select-none transition-all duration-200 ease-in-out ' +
        `${getVariantClass()} ` +
        `${
          size === 'small' ? 'h-[32px] w-[32px] rounded-[8px]' : 'h-[40px] w-[40px] rounded-[50%]'
        } ` +
        className
      }
      disabled={isDisabled || isLoading}
      {...props}
    >
      {isLoading ? (
        <IconSpinner
          className={
            'animate-spin ' + `${size === 'small' ? 'h-[18px] w-[18px]' : 'h-[24px] w-[24px]'}`
          }
        />
      ) : (
        <Icon className={`${size === 'small' ? 'h-[18px] w-[18px]' : 'h-[24px] w-[24px]'}`} />
      )}
    </button>
  );
};

export default ButtonIcon;
