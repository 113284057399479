import { FC, useMemo } from 'react';

import { DEFAULT_GREEN_THRESHOLD, DEFAULT_OUT_OF, DEFAULT_RED_THRESHOLD } from 'const/ratio';
import { RatioColors } from 'models/ratio';

type RatioProps = {
  value: number;
  className?: string;
};

const Ratio: FC<RatioProps> = props => {
  const { value: rowRatio, className } = props;

  const ratio = useMemo<number>(() => {
    if (rowRatio < 0) {
      return 0;
    }
    if (rowRatio > DEFAULT_OUT_OF) {
      return DEFAULT_OUT_OF;
    }
    return rowRatio;
  }, [rowRatio]);

  const color = useMemo<RatioColors>(() => {
    if (ratio < DEFAULT_RED_THRESHOLD) {
      return RatioColors.RED;
    }
    if (ratio < DEFAULT_GREEN_THRESHOLD) {
      return RatioColors.ORANGE;
    }
    return RatioColors.GREEN;
  }, [ratio]);

  return (
    <div
      className={`flex shrink-0 h-[24px] w-[247px] items-center gap-[1px] relative ${className}`}
    >
      <div
        style={{ left: `${ratio}%` }}
        className='absolute bottom-[18px] translate-x-[-50%] flex flex-col items-center gap-[2px]'
      >
        <span
          style={{ color }}
          className='text-[12px] leading-[16px] font-[500] tracking-[0.06px] text-center'
        >
          {ratio}%
        </span>
        <div
          style={{ borderTopColor: color }}
          className={
            'h-0 w-0 border-solid border-l-[4px] border-r-[4px] border-t-[6px] ' +
            'border-l-transparent border-r-transparent'
          }
        />
      </div>

      <div
        style={{ width: `${ratio}%`, backgroundColor: color }}
        className='h-[8px] rounded-[4px] shrink-0'
      />
      <div className='h-[8px] rounded-[4px] grow bg-primary-container' />
    </div>
  );
};

export default Ratio;
