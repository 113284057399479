import { useMutation } from 'react-query';

import { generateEmployeeCharacteristic } from 'api/microservice';
import { handleError, notifySuc } from 'helpers';
import { GenerateEmployeeCharacteristicDto } from 'models/microservice';

export const useGenerateEmployeeCharacteristic = () => {
  return useMutation(
    (dto: GenerateEmployeeCharacteristicDto) => {
      return generateEmployeeCharacteristic(dto);
    },
    {
      onSuccess() {
        notifySuc('Данные сгенерированы');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
