import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getAccounts } from 'api/account';
import { handleError } from 'helpers';
import { GetAccountsResponse } from 'models/user';

export const useGetAccounts = () => {
  return useQuery<GetAccountsResponse, AxiosError>(['account/users'], () => getAccounts(), {
    onError(error) {
      handleError(error);
    }
  });
};
