import { Dispatch, FC, SetStateAction } from 'react';
import { FeedbackType, ForWhom } from '../types';

type TabsProps = {
  forWhom: ForWhom;
  setForWhom: Dispatch<SetStateAction<ForWhom>>;
  feedbackType: FeedbackType;
  setFeedbackType: Dispatch<SetStateAction<FeedbackType>>;
  side: 'conditionalLeft' | 'conditionalRight';
  setSide: Dispatch<SetStateAction<'conditionalLeft' | 'conditionalRight'>>;
  className?: string;
};

const Tabs: FC<TabsProps> = props => {
  const { forWhom, setForWhom, feedbackType, setFeedbackType, side, setSide, className } = props;

  return (
    <div className={`flex flex-col ${className}`}>
      <div className='flex flex-wrap gap-[8px] mb-[16px]'>
        <button
          className={
            'p-[8px_20px] rounded-[18px] text-ll ' +
            `${
              side === 'conditionalLeft'
                ? 'text-light bg-on-surface-variant'
                : 'text-on-surface-variant bg-surface-variant'
            }`
          }
          onClick={() => setSide('conditionalLeft')}
        >
          Ответ первый
        </button>

        <button
          className={
            'p-[8px_20px] rounded-[18px] text-ll ' +
            `${
              side === 'conditionalRight'
                ? 'text-light bg-on-surface-variant'
                : 'text-on-surface-variant bg-surface-variant'
            }`
          }
          onClick={() => setSide('conditionalRight')}
        >
          Ответ второй
        </button>
      </div>

      <div className='flex flex-wrap gap-[8px] mb-[16px]'>
        <button
          className={
            'p-[8px_20px] rounded-[18px] text-ll ' +
            `${
              forWhom === ForWhom.USER
                ? 'text-light bg-on-surface-variant'
                : 'text-on-surface-variant bg-surface-variant'
            }`
          }
          onClick={() => setForWhom(ForWhom.USER)}
        >
          Для пользователя
        </button>

        <button
          className={
            'p-[8px_20px] rounded-[18px] text-ll ' +
            `${
              forWhom === ForWhom.MASTER_MANAGER
                ? 'text-light bg-on-surface-variant'
                : 'text-on-surface-variant bg-surface-variant'
            }`
          }
          onClick={() => setForWhom(ForWhom.MASTER_MANAGER)}
        >
          Для руководителя
        </button>

        <button
          className={
            'p-[8px_20px] rounded-[18px] text-ll ' +
            `${
              forWhom === ForWhom.COMPANY
                ? 'text-light bg-on-surface-variant'
                : 'text-on-surface-variant bg-surface-variant'
            }`
          }
          onClick={() => setForWhom(ForWhom.COMPANY)}
        >
          Для компании
        </button>
      </div>

      <div className='flex flex-wrap gap-[8px]'>
        <button
          className={
            'p-[8px_20px] rounded-[18px] text-ll ' +
            `${
              feedbackType === FeedbackType.FEEDBACK
                ? 'text-light bg-on-surface-variant'
                : 'text-on-surface-variant bg-surface-variant'
            }`
          }
          onClick={() => setFeedbackType(FeedbackType.FEEDBACK)}
        >
          Результаты
        </button>

        <button
          className={
            'p-[8px_20px] rounded-[18px] text-ll ' +
            `${
              feedbackType === FeedbackType.RECOMMENDATION
                ? 'text-light bg-on-surface-variant'
                : 'text-on-surface-variant bg-surface-variant'
            }`
          }
          onClick={() => setFeedbackType(FeedbackType.RECOMMENDATION)}
        >
          Рекомендации
        </button>
      </div>
    </div>
  );
};

export default Tabs;
