import { ChangeEvent, TextareaHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';

import { IconError } from 'components';

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  // variant?: 'dark' | 'light' | 'bordered';
  name: string;
  placeholder?: string;
  label?: string;
  rules?: RegisterOptions;
  background?: 'transparent' | 'light';
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  rows?: number;
  resize?: boolean;
}

export type TextAreaProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const leading = 24;
const paddings = 32;

const TextArea = <T extends FieldValues>({
  name,
  placeholder,
  label,
  control,
  rules = {},
  background = 'light',
  className,
  containerClassName,
  disabled = false,
  loading = false,
  rows = 4,
  resize = false,
  ...rest
}: TextAreaProps<T>) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  const [inputFocus, setInputFocus] = useState(false);
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(event.target.value);
  };

  const minHeight = useMemo<number>(() => {
    const defaultMinHeight = rows * leading + paddings;
    if (!field.value) return defaultMinHeight;

    const calculatedRows = field.value.split('\n').length;
    const calculatedHeight = calculatedRows * leading + paddings;

    return Math.max(calculatedHeight, defaultMinHeight);
  }, [rows, field.value]);

  useEffect(() => {
    if (!ref || !ref.current) return;

    ref.current.style.height = minHeight + 'px';
  }, [ref, minHeight]);

  return (
    <label
      htmlFor={name}
      className={
        'flex relative w-full flex-col flex-1 ' + `${containerClassName ? containerClassName : ''}`
      }
    >
      {label && (
        <label
          htmlFor={name}
          className={
            'absolute font-[400] select-none pointer-events-none ' +
            'duration-[125ms] ease-out transition-all ' +
            `${
              inputFocus || !!field.value
                ? 'top-[-8px] left-[17px] bg-surface px-[4px] text-[12px] leading-[16px]'
                : 'top-[16px] left-[16px] text-bl'
            } ` +
            `${inputFocus ? 'text-primary' : 'text-on-surface'} ` +
            `${fieldState.error ? 'text-error' : ''} `
          }
          onClick={() => setInputFocus(true)}
        >
          {label}
        </label>
      )}

      <textarea
        id={name}
        ref={ref}
        style={{ minHeight: `${minHeight}px` }}
        className={
          'w-full border-solid rounded-[4px] text-bl ' +
          'select-none duration-300 ease-out transition-colors overflow-hidden ' +
          `${resize && !disabled ? 'enabled:resize-y' : 'resize-none'} ` +
          `${
            inputFocus && !fieldState.error
              ? 'border-primary border-[2px] p-[15px] text-primary'
              : 'border-outline-variant border-[1px] p-[16px] text-on-surface hover:border-on-surface'
          } ` +
          `${
            disabled ? 'bg-surface' : background === 'transparent' ? 'bg-transparent' : 'bg-light'
          } ` +
          `${fieldState.error ? 'mb-[4px] !border-error border-[2px] pr-[48px]' : ''} ` +
          `${className ? className : ''}`
        }
        placeholder={label ? '' : placeholder}
        disabled={disabled || loading}
        readOnly={disabled || loading}
        value={field.value || ''}
        onChange={handleChange}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        rows={rows}
        {...rest}
      />

      {!!fieldState.error && (
        <>
          <span className='ml-[16px] text-bs text-error'>{fieldState.error.message}</span>

          <span
            title={fieldState.error.message}
            className='absolute top-[16px] right-[12px] h-[24px] w-[24px]'
          >
            <IconError color='#BA1A1A' className='h-full w-full' />
          </span>
        </>
      )}
    </label>
  );
};

export default TextArea;
