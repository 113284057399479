import { FC } from 'react';
import { Link } from 'react-router-dom';

import CompanyPlaceholder from 'assets/images/svg/company-placeholder.svg';
import { AdminPaths, AdminRoutes } from 'const/routes';
import { copyText } from 'lib';
import { Company } from 'models/company';

import { ButtonIcon, IconCopy, IconEdit, IconLink } from 'components';

type CompanyCardProps = {
  company: Company;
  isTestings?: boolean;
  className?: string;
};

const CompanyCard: FC<CompanyCardProps> = props => {
  const {
    company: { _id, name, companyCode },
    isTestings = false,
    className
  } = props;

  const handleCopyCode = () => {
    copyText(companyCode);
  };

  const handleCopyId = () => {
    copyText(_id);
  };

  return (
    <li className={'relative rounded-[20px] bg-light elevation-2 overflow-hidden ' + className}>
      <div className='absolute top-[12px] right-[8px] flex gap-[8px]'>
        <Link
          to={
            isTestings
              ? `${AdminPaths[AdminRoutes.TESTINGS]}/${_id}`
              : `${AdminPaths[AdminRoutes.COMPANIES]}/${_id}`
          }
        >
          <ButtonIcon Icon={IconLink} variant='transparent' size='big' />
        </Link>

        {!isTestings && (
          <Link to={`${AdminPaths[AdminRoutes.COMPANIES]}/${_id}/edit`}>
            <ButtonIcon Icon={IconEdit} variant='transparent' size='big' />
          </Link>
        )}
      </div>

      <div className='p-[24px_8px_24px_24px] flex items-center gap-[24px]'>
        <img src={CompanyPlaceholder} alt={name} className='w-[56px] h-[56px]' />
        <div className='pt-[12px] flex flex-col gap-[4px] ellipsis'>
          <h4 className='text-h4 text-primary ellipsis'>{name}</h4>
        </div>
      </div>

      <div className='p-[12px_8px_8px_24px] flex gap-[16px] bg-secondary-container'>
        <div className='flex flex-col gap-[4px]'>
          <p className='text-lm text-secondary ellipsis'>Промокод компании</p>
          <div className='flex gap-[4px] items-center'>
            <h4 className='text-h4 text-on-secondary-container ellipsis'>{companyCode}</h4>
            <ButtonIcon Icon={IconCopy} size='big' variant='transparent' onClick={handleCopyCode} />
          </div>
        </div>

        <div className='flex flex-col gap-[4px] overflow-hidden'>
          <p className='text-lm text-secondary ellipsis'>ID компании</p>
          <div className='flex gap-[4px] items-center overflow-hidden'>
            <h4 className='text-h4 text-on-secondary-container ellipsis truncate'>{_id}</h4>
            <ButtonIcon Icon={IconCopy} size='big' variant='transparent' onClick={handleCopyId} />
          </div>
        </div>
      </div>
    </li>
  );
};

export default CompanyCard;
