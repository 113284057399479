import { MediaDTO } from './media';

export type GroupedQuestion = {
  _id: string;
  question: string;
  imageURL?: string;
  feedback?: QuestionFeedback;
  innerQuestions: Question[];
  groupedQuestionUUID: string;
};

export type CreateGroupedQuestionDto = {
  question: string;
  imageURL?: string;
  feedback?: QuestionFeedback;
  innerQuestions: Question[];
};

export type Question = {
  questionUUID?: string;
  question: string;
  binaryAnswer: BinaryAnswer;
  equalAnswers: boolean;
  duration: number;
  reverse?: boolean;
  flipped?: boolean;
  mediaDTO?: MediaDTO;
  questionType: QuestionType;
  feedback?: {
    conditionalLeft: QuestionFeedback;
    conditionalRight: QuestionFeedback;
  };
  specialId?: string;
};

export type BinaryAnswer = {
  conditionalLeft: {
    default: string;
    male: string;
    female: string;
    negative: boolean;
  };
  conditionalRight: {
    default: string;
    male: string;
    female: string;
    negative: boolean;
  };
};

export enum QuestionType {
  CONTROL = 'control',
  TEST = 'test',
  NEUTRAL = 'neutral'
}

export type QuestionFeedback = {
  feedbackUser: Feedback[];
  feedbackCompany: Feedback[];
  feedbackMasterManager: Feedback[];
  recommendationUser: Feedback[];
  recommendationCompany: Feedback[];
  recommendationMasterManager: Feedback[];
};

export type Feedback = {
  min: number;
  max: number;
  feedback: string;
};

export type AnswerPreset = {
  title: string;
  fields: {
    left: string;
    leftMale: string;
    leftFemale: string;
    right: string;
    rightMale: string;
    rightFemale: string;
  };
};
