import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { FC } from 'react';
import { Controller, UseFieldArrayAppend, useFieldArray, useForm } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';
import { TEST_FORM_QUESTION_OPTIONS } from 'const/test(old)';
import { AddTestFormQuestionFormValues, TestFormValues } from 'models/test(old)';
import { FormQuestionAnswerType } from 'models/test-form';

import { Button, IconCross, IconPlus, Select, TextField } from 'components';
import OptionCard from './OptionCard';

type AddTestFormQuestionProps = {
  append: UseFieldArrayAppend<TestFormValues, 'formQuestions'>;
  onClose: () => void;
};

const AddTestFormQuestion: FC<AddTestFormQuestionProps> = ({ append, onClose }) => {
  const {
    control,
    reset,
    watch,
    getValues,
    trigger,
    formState: { isDirty }
  } = useForm<AddTestFormQuestionFormValues>({
    mode: 'onSubmit',
    defaultValues: {
      question: '',
      answerOptions: [],
      formQuestionAnswerType: FormQuestionAnswerType.TEXT,
      toProfileParam: ''
    }
  });

  const questionType = watch('formQuestionAnswerType');
  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
    move: moveOption
  } = useFieldArray({ control, name: 'answerOptions', rules: {} });

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    if (over && active.id !== over.id) {
      const oldIndex = optionFields.findIndex(option => option.id === active.id);
      const newIndex = optionFields.findIndex(option => option.id === over.id);
      moveOption(oldIndex, newIndex);
    }
  };

  const handleAddOption = () => {
    appendOption({ value: '' });
  };

  const handleReset = () => {
    reset();
  };

  const handleSave = async () => {
    const isQuestionValid = await trigger('question');
    const isAnswerOptionsValid = await trigger('answerOptions');
    const isFormQuestionAnswerTypeValid = await trigger('formQuestionAnswerType');
    const isToProfileParamValid = await trigger('toProfileParam');

    if (
      isQuestionValid &&
      isAnswerOptionsValid &&
      isFormQuestionAnswerTypeValid &&
      isToProfileParamValid
    ) {
      const data = getValues();
      append(data);
      reset();
      onClose();
    }
  };

  return (
    <div className='flex flex-col grow'>
      <header className='mb-[36px] flex justify-between items-center gap-[8px]'>
        <h3 className='text-h3 text-on-surface'>Добавить поле в анкету</h3>
        <button type='button' className='h-[32px] w-[32px] bg-transparent' onClick={onClose}>
          <IconCross className='h-full w-full text-outline' />
        </button>
      </header>

      <div className='flex flex-col gap-[16px] mb-[36px]'>
        <TextField
          name='question'
          label='Вопрос'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
          }}
        />

        <TextField
          name='toProfileParam'
          label='Тег'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
          }}
        />

        <Select
          name='formQuestionAnswerType'
          label='Выберите тип вопроса'
          control={control}
          rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
          options={TEST_FORM_QUESTION_OPTIONS}
        />
      </div>

      <div className='mb-auto flex flex-col'>
        <div className='flex h-[1px] bg-outline-variant mb-[36px]' />

        {(questionType === FormQuestionAnswerType.SINGLE ||
          questionType === FormQuestionAnswerType.MULTIPLE) && (
          <div className='flex flex-col'>
            <Controller
              name='answerOptions'
              control={control}
              rules={{
                required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                minLength: { value: 2, message: 'Добавьте хотя бы 2 варианта ответа' }
              }}
              render={({ fieldState: { error } }) => (
                <div className='flex flex-col gap-[6px] mb-[16px]'>
                  <DndContext onDragEnd={handleDragEnd}>
                    <SortableContext items={optionFields} strategy={verticalListSortingStrategy}>
                      <ul className='flex flex-col gap-[16px]'>
                        {optionFields.map((option, optionIndex) => (
                          <OptionCard
                            key={option.id}
                            option={option}
                            index={optionIndex}
                            control={control}
                            remove={removeOption}
                          />
                        ))}
                      </ul>
                    </SortableContext>
                  </DndContext>

                  {error?.root && (
                    <span className='ml-[16px] text-bs text-error'>{error.root.message}</span>
                  )}
                </div>
              )}
            />

            <Button
              variant='bordered'
              title='Добавить вариант ответа'
              icon={<IconPlus />}
              onClick={handleAddOption}
              className='mb-[36px]'
            />

            <div className='flex h-[1px] bg-outline-variant' />
          </div>
        )}
      </div>

      <div className='flex gap-[8px] self-end pb-[40px] mt-[40px]'>
        <Button
          variant='secondary'
          title='Сбросить'
          onClick={handleReset}
          disabled={!isDirty}
          className='min-w-[160px]'
        />
        <Button
          variant='primary'
          title='Сохранить'
          disabled={!isDirty}
          onClick={handleSave}
          className='min-w-[160px]'
        />
      </div>
    </div>
  );
};

export default AddTestFormQuestion;
