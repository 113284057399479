import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getCompanyTestings } from 'api/testing';
import { handleError } from 'helpers';
import { GetCompanyTestingsResponse } from 'models/testing';

export const useGetCompanyTestings = () => {
  return useQuery<GetCompanyTestingsResponse, AxiosError>(
    ['testing/supervisor/testings/all'],
    () => getCompanyTestings(),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
