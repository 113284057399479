import { Navigate, RouteProps } from 'react-router-dom';

import { IconCompany, IconEmployee, IconHome, IconTest } from 'components';
import {
  CompaniesPage,
  CompanyPage,
  CompanyTestingsPage,
  CreateTestPage,
  CreateUserPage,
  EditCompanyPage,
  EditUserPage,
  MainPage,
  TestPage,
  TestingsPage,
  TestsPage,
  UserPage,
  UsersPage
} from 'pages/admin';
import { ProfilePage } from 'pages/shared';

export enum AdminRoutes {
  MAIN = 'main',
  COMPANIES = 'companies',
  COMPANY = 'company',
  EDIT_COMPANY = 'edit-company',
  CREATE_COMPANY = 'create-company',

  TESTINGS = 'testings',
  COMPANY_TESTINGS = 'company-testings',

  USERS = 'users',
  USER = 'user',
  CREATE_USER = 'create-user',
  EDIT_USER = 'edit-user',
  PROFILE = 'profile',
  TESTS = 'tests',
  TEST = 'test',
  CREATE_TEST = 'create-test',
  NOT_FOUND = 'not-found'
}

export const AdminPaths: Record<AdminRoutes, string> = {
  [AdminRoutes.MAIN]: '/',
  [AdminRoutes.COMPANIES]: '/companies',
  [AdminRoutes.COMPANY]: '/companies/:companyId',
  [AdminRoutes.EDIT_COMPANY]: '/companies/:companyId/edit',
  [AdminRoutes.CREATE_COMPANY]: '/companies/create',

  [AdminRoutes.TESTINGS]: '/testings',
  [AdminRoutes.COMPANY_TESTINGS]: '/testings/:companyId',

  [AdminRoutes.USERS]: '/users',
  [AdminRoutes.USER]: '/users/:userId',
  [AdminRoutes.CREATE_USER]: '/users/create',
  [AdminRoutes.EDIT_USER]: '/users/:userId/edit',
  [AdminRoutes.PROFILE]: '/profile',
  [AdminRoutes.TESTS]: '/tests',
  [AdminRoutes.TEST]: '/tests/:testId',
  [AdminRoutes.CREATE_TEST]: '/tests/create',
  [AdminRoutes.NOT_FOUND]: '*'
};

export const adminNavigationLinks: {
  title: string;
  link: string;
  Icon: typeof IconHome;
  disabled?: boolean;
}[] = [
  { title: 'Главная', link: AdminPaths[AdminRoutes.MAIN], Icon: IconHome, disabled: true },
  { title: 'Компании', link: AdminPaths[AdminRoutes.COMPANIES], Icon: IconCompany },
  { title: 'Тестирования', link: AdminPaths[AdminRoutes.TESTINGS], Icon: IconTest },
  { title: 'Пользователи', link: AdminPaths[AdminRoutes.USERS], Icon: IconEmployee },
  { title: 'Тесты', link: AdminPaths[AdminRoutes.TESTS], Icon: IconTest }
];

export const AdminRouteConfig: Record<AdminRoutes, RouteProps> = {
  [AdminRoutes.MAIN]: {
    path: AdminPaths[AdminRoutes.MAIN],
    Component: MainPage
  },
  [AdminRoutes.COMPANIES]: {
    path: AdminPaths[AdminRoutes.COMPANIES],
    Component: CompaniesPage
  },
  [AdminRoutes.COMPANY]: {
    path: AdminPaths[AdminRoutes.COMPANY],
    Component: CompanyPage
  },
  [AdminRoutes.EDIT_COMPANY]: {
    path: AdminPaths[AdminRoutes.EDIT_COMPANY],
    Component: EditCompanyPage
  },
  [AdminRoutes.CREATE_COMPANY]: {
    path: AdminPaths[AdminRoutes.CREATE_COMPANY]
  },

  [AdminRoutes.TESTINGS]: {
    path: AdminPaths[AdminRoutes.TESTINGS],
    Component: TestingsPage
  },
  [AdminRoutes.COMPANY_TESTINGS]: {
    path: AdminPaths[AdminRoutes.COMPANY_TESTINGS],
    Component: CompanyTestingsPage
  },

  [AdminRoutes.USERS]: {
    path: AdminPaths[AdminRoutes.USERS],
    Component: UsersPage
  },
  [AdminRoutes.USER]: {
    path: AdminPaths[AdminRoutes.USER],
    Component: UserPage
  },
  [AdminRoutes.CREATE_USER]: {
    path: AdminPaths[AdminRoutes.CREATE_USER],
    Component: CreateUserPage
  },
  [AdminRoutes.EDIT_USER]: {
    path: AdminPaths[AdminRoutes.EDIT_USER],
    Component: EditUserPage
  },
  [AdminRoutes.PROFILE]: {
    path: AdminPaths[AdminRoutes.PROFILE],
    Component: ProfilePage
  },
  [AdminRoutes.TESTS]: {
    path: AdminPaths[AdminRoutes.TESTS],
    Component: TestsPage
  },
  [AdminRoutes.TEST]: {
    path: AdminPaths[AdminRoutes.TEST],
    Component: TestPage
  },
  [AdminRoutes.CREATE_TEST]: {
    path: AdminPaths[AdminRoutes.CREATE_TEST],
    Component: CreateTestPage
  },
  [AdminRoutes.NOT_FOUND]: {
    path: AdminPaths[AdminRoutes.NOT_FOUND],
    element: <Navigate to={AdminPaths[AdminRoutes.TESTS]} replace />
  }
};
