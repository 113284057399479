import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export const IconEye: FC<
  DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>
> = props => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_143_5394)'>
        <path
          d='M12.2949 4.5C7.29492 4.5 3.02492 7.61 1.29492 12C3.02492 16.39 7.29492 19.5 12.2949 19.5C17.2949 19.5 21.5649 16.39 23.2949 12C21.5649 7.61 17.2949 4.5 12.2949 4.5ZM12.2949 17C9.53492 17 7.29492 14.76 7.29492 12C7.29492 9.24 9.53492 7 12.2949 7C15.0549 7 17.2949 9.24 17.2949 12C17.2949 14.76 15.0549 17 12.2949 17ZM12.2949 9C10.6349 9 9.29492 10.34 9.29492 12C9.29492 13.66 10.6349 15 12.2949 15C13.9549 15 15.2949 13.66 15.2949 12C15.2949 10.34 13.9549 9 12.2949 9Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_143_5394'>
          <rect width='24' height='24' fill='currentColor' transform='translate(0.294922)' />
        </clipPath>
      </defs>
    </svg>
  );
};
