import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getOpenQuestions } from 'api/open-questions';
import { handleError } from 'helpers';
import { GetOpenQuestionsDto, GetOpenQuestionsResponse } from 'models/open-question';

export const useGetOpenQuestionsQuery = ({ testId }: GetOpenQuestionsDto) => {
  return useQuery<GetOpenQuestionsResponse, AxiosError>(
    [`open_questions/${testId}`],
    () => getOpenQuestions({ testId }),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
