export const allocateTime = (
  initialSeconds: number
): { hours: number; minutes: number; seconds: number } => {
  let seconds = initialSeconds;

  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;

  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  return { hours, minutes, seconds };
};
