import {
  AttachCompanyToUserDto,
  CreateCompanyDto,
  DeleteCompanyDto,
  GetCompaniesResponse,
  GetCompanyDto,
  GetCompanyResponse,
  GetCompanyUsersDto,
  GetCompanyUsersResponse,
  UpdateCompanyCodeDto
} from 'models/company';
import { instance } from './instance';

export const getCompanies = async () => {
  const response = await instance.get<GetCompaniesResponse>('company/all');
  return response.data;
};

// TODO test this request
export const getCompany = async ({ companyId }: GetCompanyDto) => {
  const response = await instance.get<GetCompanyResponse>(`company/all?companyId=${companyId}`);
  return response.data;
};

export const createCompany = async ({ body }: CreateCompanyDto) => {
  const response = await instance.post<GetCompanyResponse>('company', body);
  return response.data;
};

export const updateCompanyCode = async ({ companyId, body }: UpdateCompanyCodeDto) => {
  const response = await instance.patch<GetCompanyResponse>(`company?companyId=${companyId}`, body);
  return response.data;
};

export const deleteCompany = async ({ companyId }: DeleteCompanyDto) => {
  const response = await instance.delete(`company?companyId=${companyId}`);
  return response.data;
};

export const attachCompanyToUser = async ({ userId, body }: AttachCompanyToUserDto) => {
  const response = await instance.post(`company/attach_to_user?userId=${userId}`, body);
  return response.data;
};

export const getCompanyUsers = async ({ companyId }: GetCompanyUsersDto) => {
  const query = companyId ? `company/users?companyId=${companyId}` : 'company/users';
  const response = await instance.get<GetCompanyUsersResponse>(query);
  return response.data;
};
