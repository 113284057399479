import { ChangeEvent, InputHTMLAttributes, KeyboardEvent, useState } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';

import { IconError, IconEye, IconEyeHide } from 'components';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'email' | 'password' | 'number' | 'tel';
  // variant?: 'dark' | 'light' | 'bordered';
  wide?: boolean;
  name: string;
  placeholder?: string;
  label?: string;
  rules?: RegisterOptions;
  background?: 'transparent' | 'light';
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
  autoComplete?: 'off' | 'on' | 'new-password';
}

export type TextFieldProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const TextField = <T extends FieldValues>({
  type = 'text',
  wide = true,
  name,
  placeholder,
  label,
  control,
  rules = {},
  background = 'light',
  className,
  containerClassName,
  disabled = false,
  loading = false,
  autoComplete = 'off',
  children,
  autoFocus,
  ...rest
}: TextFieldProps<T>) => {
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  const isNumber = type === 'number';
  const isPassword = type === 'password';
  const [key, setKey] = useState(0);
  const [inputFocus, setInputFocus] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isNumber) {
      field.onChange(Number(event.target.value));
    } else {
      field.onChange(event.target.value);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    setInputFocus(false);
    if (!autoFocus) setKey(key + 1);
  };

  return (
    <div
      className={
        'flex relative flex-col ' +
        `${wide ? 'w-full grow' : ''} ` +
        `${containerClassName ? containerClassName : ''}`
      }
    >
      {label && (
        <label
          htmlFor={name}
          className={
            'absolute font-[400] select-none pointer-events-none ' +
            'duration-[125ms] ease-out transition-all ' +
            `${
              inputFocus || !!field.value || field.value === 0
                ? 'top-[-8px] left-[13px] bg-surface px-[4px] text-[12px] leading-[16px]'
                : 'top-[16px] left-[16px] text-bl'
            } ` +
            `${inputFocus ? 'text-primary' : 'text-on-surface'} ` +
            `${fieldState.error ? 'text-error' : ''} `
          }
          onClick={() => setInputFocus(true)}
        >
          {label}
        </label>
      )}

      <input
        key={key}
        type={showPassword ? 'text' : type}
        id={name}
        className={
          'h-[56px] w-full border-solid rounded-[4px] text-bl ' +
          'select-none duration-300 ease-out transition-colors ' +
          `${
            inputFocus && !fieldState.error
              ? 'border-primary border-[2px] p-[15px] text-primary'
              : 'border-outline-variant border-[1px] p-[16px] text-on-surface hover:border-on-surface'
          } ` +
          `${
            disabled ? 'bg-surface' : background === 'transparent' ? 'bg-transparent' : 'bg-light'
          } ` +
          `${fieldState.error ? 'mb-[4px] !border-error border-[2px]' : ''} ` +
          `${fieldState.error && isPassword ? 'pr-[48px]' : ''} ` +
          `${className ? className : ''}`
        }
        placeholder={label ? '' : placeholder}
        disabled={disabled || loading}
        readOnly={disabled || loading}
        autoComplete={autoComplete}
        value={isNumber ? field.value || 0 : field.value || ''}
        onChange={handleChange}
        onFocus={() => setInputFocus(true)}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        autoFocus={autoFocus}
        {...rest}
      />

      {isPassword && !fieldState.error && (
        <button
          type='button'
          className='absolute h-[24px] w-[24px] top-[16px] right-[12px] bg-transparent'
          onClick={() => setShowPassword(prev => !prev)}
          disabled={disabled || loading}
        >
          {showPassword && <IconEyeHide className='bg-none' color='#1B1B1F' />}
          {!showPassword && <IconEye color='#1B1B1F' />}
        </button>
      )}

      {children}

      {!!fieldState.error && (
        <>
          <span className='ml-[16px] text-bs text-error'>{fieldState.error.message}</span>

          <span
            title={fieldState.error.message}
            className='absolute top-[16px] right-[12px] h-[24px] w-[24px]'
          >
            <IconError color='#BA1A1A' className='h-full w-full' />
          </span>
        </>
      )}
    </div>
  );
};

export default TextField;
