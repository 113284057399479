import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { apiTest } from 'api/test';
import { TESTS_QUERY_KEY } from 'const/test';
import { handleError } from 'helpers';
import { GetTestsResponse } from 'models/test(old)';

export const useGetTestsQuery = () => {
  return useQuery<GetTestsResponse, AxiosError>([TESTS_QUERY_KEY], () => apiTest.getTests(), {
    onError(error) {
      handleError(error);
    }
  });
};
