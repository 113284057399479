import { FC, useState } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from 'react-hook-form';

import { Button, IconCross } from 'components';
import { TestFormValues } from 'models/test(old)';
import { FeedbackCompany } from './FeedbackCompany';
import { FeedbackMasterManager } from './FeedbackMasterManager';
import { FeedbackUser } from './FeedbackUser';
import { RecommendCompany } from './RecommendCompany';
import { RecommendMasterManager } from './RecommendMasterManager';
import { RecommendUser } from './RecommendUser';
import { Tabs } from './Tabs';
import { FeedbackType, ForWhom } from './types';

type QuestionResultsProps = {
  groupIndex: number;
  groupOrder: number;
  questionIndex: number;
  questionOrder: number;
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  onClose: () => void;
};

const QuestionResults: FC<QuestionResultsProps> = props => {
  const { groupIndex, questionIndex, control, trigger, getValues, watch, setValue, onClose } =
    props;
  const [forWhom, setForWhom] = useState<ForWhom>(ForWhom.USER);
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(FeedbackType.FEEDBACK);
  const [side, setSide] = useState<'conditionalLeft' | 'conditionalRight'>('conditionalLeft');

  const question = watch(`groupedQuestions.${groupIndex}.innerQuestions.${questionIndex}.question`);
  const leftAnswer = watch(
    `groupedQuestions.${groupIndex}.innerQuestions.${questionIndex}.binaryAnswer.conditionalLeft.default`
  );
  const rightAnswer = watch(
    `groupedQuestions.${groupIndex}.innerQuestions.${questionIndex}.binaryAnswer.conditionalRight.default`
  );
  const leftIsNegative = watch(
    `groupedQuestions.${groupIndex}.innerQuestions.${questionIndex}.binaryAnswer.conditionalLeft.negative`
  );
  const rightIsNegative = watch(
    `groupedQuestions.${groupIndex}.innerQuestions.${questionIndex}.binaryAnswer.conditionalRight.negative`
  );

  const handleSave = async () => {
    const isValid = await trigger(
      `groupedQuestions.${groupIndex}.innerQuestions.${questionIndex}.feedback`
    );

    if (isValid) {
      onClose();
    }
  };

  return (
    <div className='grow flex flex-col max-w-[100%]'>
      <header className='mb-[36px] flex justify-between gap-[8px] items-center'>
        <h3 className='text-h3 text-on-surface'>Фидбек по вопросу</h3>
        <button type='button' className='h-[32px] w-[32px] bg-transparent' onClick={onClose}>
          <IconCross className='h-full w-full text-outline' />
        </button>
      </header>

      <div className='mb-[36px] flex flex-col border-[1px] border-solid border-outline-variant'>
        <div className='flex flex-col p-[8px_16px_7px_16px] border-b-[1px] border-solid border-outline-variant'>
          <p className='text-lm text-on-surface'>Вопрос:</p>
          <p title={question} className='text-bl tracking-[0.08px] text-on-surface ellipsis'>
            {question}
          </p>
        </div>

        <div className='flex'>
          <div
            className={
              'flex-1 flex flex-col ellipsis ' +
              `${
                side === 'conditionalLeft'
                  ? 'p-[8px_16px_6px_16px] border-b-[2px] border-solid border-primary'
                  : 'p-[8px_16px_8px_16px]'
              }`
            }
          >
            <p className='text-lm text-on-surface'>Ответ первый:</p>

            <div className='flex gap-[8px] items-center'>
              <p
                title={`${leftAnswer}${leftIsNegative ? ' (Негативный ответ)' : ''}`}
                className='text-bl tracking-[0.08px] text-on-surface ellipsis'
              >
                {leftAnswer}
              </p>
              {leftIsNegative && <span className='text-lm text-error'>(Негативный ответ)</span>}
            </div>
          </div>

          <div className='shrink-0 h-[100%] w-[1px] border-r-[1px] border-solid border-outline-variant' />

          <div
            className={
              'flex-1 flex flex-col ellipsis ' +
              `${
                side === 'conditionalRight'
                  ? 'p-[8px_16px_6px_16px] border-b-[2px] border-solid border-primary'
                  : 'p-[8px_16px_8px_16px]'
              }`
            }
          >
            <p className='text-lm text-on-surface'>Ответ второй:</p>

            <div className='flex gap-[8px] items-center'>
              <p
                title={`${rightAnswer}${rightIsNegative ? ' (Негативный ответ)' : ''}`}
                className='text-bl tracking-[0.08px] text-on-surface ellipsis'
              >
                {rightAnswer}
              </p>
              {rightIsNegative && <span className='text-lm text-error'>(Негативный ответ)</span>}
            </div>
          </div>
        </div>
      </div>

      <Tabs
        forWhom={forWhom}
        setForWhom={setForWhom}
        feedbackType={feedbackType}
        setFeedbackType={setFeedbackType}
        side={side}
        setSide={setSide}
        className='mb-[36px]'
      />

      {forWhom === ForWhom.USER && feedbackType === FeedbackType.FEEDBACK && (
        <FeedbackUser
          key={side}
          side={side}
          groupIndex={groupIndex}
          questionIndex={questionIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.MASTER_MANAGER && feedbackType === FeedbackType.FEEDBACK && (
        <FeedbackMasterManager
          key={side}
          side={side}
          groupIndex={groupIndex}
          questionIndex={questionIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.COMPANY && feedbackType === FeedbackType.FEEDBACK && (
        <FeedbackCompany
          key={side}
          side={side}
          groupIndex={groupIndex}
          questionIndex={questionIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.USER && feedbackType === FeedbackType.RECOMMENDATION && (
        <RecommendUser
          key={side}
          side={side}
          groupIndex={groupIndex}
          questionIndex={questionIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.MASTER_MANAGER && feedbackType === FeedbackType.RECOMMENDATION && (
        <RecommendMasterManager
          key={side}
          side={side}
          groupIndex={groupIndex}
          questionIndex={questionIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.COMPANY && feedbackType === FeedbackType.RECOMMENDATION && (
        <RecommendCompany
          key={side}
          side={side}
          groupIndex={groupIndex}
          questionIndex={questionIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      <div className='flex gap-[8px] self-end pb-[40px] mt-[40px]'>
        <Button variant='secondary' title='Отменить' className='min-w-[160px]' onClick={onClose} />
        <Button
          variant='primary'
          title='Сохранить'
          className='min-w-[160px]'
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default QuestionResults;
