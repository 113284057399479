import { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import 'styles/main.css';

import { AppRouter } from 'components';

const App: FC = () => {
  return (
    <>
      <AppRouter />
      <ToastContainer />
    </>
  );
};

export default App;
