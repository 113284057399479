import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getTestFormQuestions } from 'api/test-form';
import { handleError } from 'helpers';
import { GetTestFormQuestionsDto, GetTestFormQuestionsResponse } from 'models/test-form';

export const useGetTestFormQuestionsQuery = ({ testId }: GetTestFormQuestionsDto) => {
  return useQuery<GetTestFormQuestionsResponse, AxiosError>(
    [`tests/${testId}/form`],
    () => getTestFormQuestions({ testId }),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
