import { FC, useMemo } from 'react';

import { Feedback, InnerAnswer, Ratio, Recommendation } from 'components';
import { useUserRole } from 'hooks/auth';
import { useToggle } from 'hooks/common';
import { getTestingGroupScore } from 'lib';
import { CalibrationData } from 'models/calibration';
import { GroupedAnswersResult } from 'models/testing';
import { USER_ROLES } from 'models/user';
import Accordion from './Accordion';

type GroupedAnswerProps = {
  groupedAnswer: GroupedAnswersResult;
  calibration?: CalibrationData;
  show: 'feedback' | 'recommendation' | 'calibration';
  order: number;
  className?: string;
};

const GroupedAnswer: FC<GroupedAnswerProps> = props => {
  const {
    groupedAnswer,
    groupedAnswer: { question, feedbackResult, innerAnswers },
    calibration,
    show,
    order,
    className
  } = props;
  const [collapsed, toggleCollapsed] = useToggle(true);
  const [innerCollapsed, toggleInnerCollapsed] = useToggle(true);
  const userRole = useUserRole();
  const testingGroupScore = useMemo(() => getTestingGroupScore(groupedAnswer), [groupedAnswer]);

  const { feedback, recommendation } = useMemo(() => {
    switch (userRole) {
      case USER_ROLES.ADMIN:
      case USER_ROLES.MASTER:
      case USER_ROLES.MANAGER:
      default:
        return {
          feedback: feedbackResult?.feedbackCompany,
          recommendation: feedbackResult?.recommendationCompany
        };
    }
  }, [userRole, feedbackResult]);

  return (
    <div className={`flex flex-col ${className}`}>
      <div className='py-[16px] flex flex-col'>
        <header className='mb-[36px] flex justify-between items-center gap-[8px]'>
          <h3 className='text-h3 text-on-surface'>
            {order}. {question}
          </h3>

          <Ratio value={testingGroupScore} />
        </header>

        <Accordion
          title={
            show === 'feedback' ? 'Результат по группе вопросов' : 'Рекомендации по группе вопросов'
          }
          collapsed={collapsed}
          toggle={toggleCollapsed}
          className='mb-[16px]'
        />

        {!collapsed && show === 'feedback' && feedback && <Feedback feedback={feedback} />}
        {!collapsed && show === 'recommendation' && recommendation && (
          <Recommendation recommendation={recommendation} />
        )}
      </div>

      <Accordion
        title={show === 'feedback' ? 'Результаты по вопросам' : 'Рекомендации по вопросам'}
        collapsed={innerCollapsed}
        toggle={toggleInnerCollapsed}
        className='mb-[16px]'
      />

      {!innerCollapsed && !!innerAnswers.length && (
        <>
          <ul className='flex flex-col gap-[16px]'>
            {innerAnswers.map((innerAnswer, index) => (
              <div key={innerAnswer.questionUUID}>
                <InnerAnswer
                  innerAnswer={innerAnswer}
                  calibration={calibration}
                  show={show}
                  groupOrder={order}
                  order={index + 1}
                />
                {index !== innerAnswers.length - 1 && (
                  <div className='shrink-0 h-[1px] w-full bg-on-surface-transparent' />
                )}
              </div>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default GroupedAnswer;
