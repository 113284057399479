import { useMutation, useQueryClient } from 'react-query';

import { createMasman } from 'api/auth';
import { handleError, notifySuc } from 'helpers';
import { CreateMasmanDto } from 'models/auth';

export const useCreateMasman = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: CreateMasmanDto) => {
      return createMasman(dto);
    },
    {
      onSuccess() {
        notifySuc('Аккаунт создан');

        queryClient.invalidateQueries(['account/users']);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
