import { GroupedByTestTesting, Testing } from 'models/testing';

export const groupTestingsByTest = (testings: Testing[]): GroupedByTestTesting[] => {
  const result = testings?.reduce<GroupedByTestTesting[]>((acc, testing) => {
    if (!acc.length || !acc.find(t => t.testId === testing.testId)) {
      acc.push({
        testId: testing.testId,
        testName: testing.name,
        testings: [testing]
      });
    } else {
      acc.find(t => t.testId === testing.testId)?.testings.push(testing);
    }

    return acc;
  }, []);

  return result;
};
