import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC, useState } from 'react';
import {
  Control,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';
import { TestFormValues } from 'models/test(old)';

import {
  Button,
  ButtonIcon,
  Drawer,
  EditTestFormQuestion,
  IconDragHandle,
  IconEdit,
  IconTrash,
  ModalConfirm,
  TextField
} from 'components';

type FormQuestionCardProps = {
  formQuestion: FieldArrayWithId<TestFormValues, 'formQuestions', 'id'>;
  index: number;
  order: number;
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  remove: UseFieldArrayRemove;
};

const FormQuestionCard: FC<FormQuestionCardProps> = ({
  formQuestion,
  index,
  control,
  trigger,
  watch,
  remove
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: formQuestion.id
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const handleEdit = () => {
    setEditModalOpen(true);
  };

  const handleRemove = () => {
    setDeleteModalOpen(true);
  };

  const confirmRemove = () => {
    remove(index);
  };

  return (
    <li
      ref={setNodeRef}
      {...attributes}
      style={style}
      className='flex flex-grow gap-[8px] items-center'
    >
      <button
        type='button'
        ref={setActivatorNodeRef}
        {...listeners}
        className={
          'h-[32px] w-[32px] flex justify-center items-center shrink-0 ' +
          'bg-transparent text-outline cursor-grab'
        }
      >
        <IconDragHandle />
      </button>

      <TextField
        name={`formQuestions.${index}.question`}
        label='Вопрос анкеты'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
        }}
      />

      <Button
        variant='secondary'
        icon={<IconEdit />}
        title='Варианты ответов'
        onClick={handleEdit}
      />
      <ButtonIcon Icon={IconTrash} variant='transparent' size='big' onClick={handleRemove} />

      <Drawer isOpen={editModalOpen} anchor='right' portal onClose={() => setEditModalOpen(false)}>
        <EditTestFormQuestion
          formQuestion={formQuestion}
          index={index}
          control={control}
          trigger={trigger}
          watch={watch}
          onClose={() => setEditModalOpen(false)}
        />
      </Drawer>

      <ModalConfirm
        title='Удалить вопрос анкеты?'
        confirmText='Удалить'
        isOpen={deleteModalOpen}
        portal
        onConfirm={confirmRemove}
        onClose={() => setDeleteModalOpen(false)}
      />
    </li>
  );
};

export default FormQuestionCard;
