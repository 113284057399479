import { useMutation } from 'react-query';

import { resetPassword } from 'api/auth';
import { handleError, notifySuc } from 'helpers';
import { PasswordResetDto } from 'models/auth';

export const usePasswordResetMutation = () => {
  return useMutation(
    (data: PasswordResetDto) => {
      return resetPassword(data);
    },
    {
      onSuccess() {
        notifySuc('Инструкция по восстановлению пароля была выслана успешно');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
