import { FC } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
  useFieldArray
} from 'react-hook-form';

import { TestFormValues } from 'models/test(old)';

import { Button, IconPlus } from 'components';
import QuestionGroup from '../QuestionGroup/QuestionGroup';

type TestQuestionsProps = {
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  className?: string;
};

const TestQuestions: FC<TestQuestionsProps> = ({
  control,
  trigger,
  watch,
  getValues,
  setValue,
  className
}) => {
  const {
    fields: questionGroupFields,
    append: appendQuestionGroup,
    remove: removeQuestionGroup
  } = useFieldArray({ control, name: 'groupedQuestions' });

  const handleAddGroup = () => {
    appendQuestionGroup({
      question: '',
      feedback: undefined,
      innerQuestions: []
    });
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <header className='flex items-center h-[56px] mb-[16px]'>
        <h3 className='text-h3 text-on-secondary-fixed'>Вопросы теста</h3>
      </header>

      {!!questionGroupFields.length && (
        <ul className='flex flex-col gap-[16px] mb-[16px]'>
          {questionGroupFields.map((group, index) => (
            <QuestionGroup
              key={group.id}
              questionGroup={group}
              index={index}
              order={index + 1}
              control={control}
              trigger={trigger}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              remove={removeQuestionGroup}
            />
          ))}
        </ul>
      )}

      <Button
        variant='bordered'
        title='Добавить группу вопросов'
        icon={<IconPlus />}
        onClick={handleAddGroup}
      />
    </div>
  );
};

export default TestQuestions;
