import { unsecuredCopyText } from './unsecuredCopyText';

export const copyText = async (text: string): Promise<void> => {
  if (!navigator.clipboard) {
    return unsecuredCopyText(text);
  }

  try {
    await navigator.clipboard.writeText(text);
  } catch (error) {
    console.error(error);
  }
};
