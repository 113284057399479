import { FC } from 'react';

import { IconChevronLeft } from 'components';

type AccordionProps = {
  title: string;
  collapsed: boolean;
  toggle: () => void;
  className?: string;
};

const Accordion: FC<AccordionProps> = ({ title, collapsed, toggle, className }) => {
  return (
    <button
      type='button'
      onClick={toggle}
      className={
        'pr-[8px] pl-[16px] flex justify-between items-center gap-[10px] ' +
        'rounded-[16px] bg-transparent hover:bg-primary-opacity-008 ' +
        'transition-colors duration-300 ease-in-out ' +
        className
      }
    >
      <h4 className='text-h4 text-on-surface'>{title}</h4>

      <div className='h-[40px] w-[40px] flex justify-center items-center'>
        <IconChevronLeft
          className={
            'h-[24px] w-[24px] text-primary ' +
            `${collapsed ? 'rotate-[270deg]' : 'rotate-[90deg]'}`
          }
        />
      </div>
    </button>
  );
};

export default Accordion;
