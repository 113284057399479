import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getMyAccount } from 'api/account';
import { handleError } from 'helpers';
import { GetMyAccountResponse } from 'models/user';

export const useGetMyAccount = () => {
  return useQuery<GetMyAccountResponse, AxiosError>(['account'], () => getMyAccount(), {
    onError(error) {
      handleError(error);
    }
  });
};
