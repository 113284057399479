import { FC } from 'react';
import { Link } from 'react-router-dom';

import AvatarFemalePlaceholder from 'assets/images/png/avatar-female-placeholder.png';
import AvatarMalePlaceholder from 'assets/images/png/avatar-male-placeholder.png';

import { ButtonIcon, IconEdit, IconLink } from 'components/atoms';
import { AdminPaths, AdminRoutes } from 'const/routes';
import { calculateAge, getGender } from 'lib';
import { User } from 'models/user';

type UserCardProps = {
  user: User;
  className?: string;
};

const UserCard: FC<UserCardProps> = props => {
  const {
    user: { _id, id, name, email, role, gender, company, birthday },
    className
  } = props;

  return (
    <div className={'relative rounded-[20px] bg-light elevation-2 overflow-hidden ' + className}>
      <div className='absolute top-[12px] right-[15px] flex gap-[8px]'>
        <Link to={`${AdminPaths[AdminRoutes.USERS]}/${_id || id}`}>
          <ButtonIcon Icon={IconLink} variant='transparent' size='big' />
        </Link>

        <Link to={`${AdminPaths[AdminRoutes.USERS]}/${_id || id}/edit`}>
          <ButtonIcon Icon={IconEdit} variant='transparent' size='big' />
        </Link>
      </div>

      <div className='p-[24px_8px_24px_24px] flex gap-[24px]'>
        <div
          className={
            'shrink-0 h-[64px] w-[64px] p-[12px_6px_0_6px] ' +
            'bg-primary-container rounded-[32px] overflow-hidden'
          }
        >
          <img
            src={gender ? AvatarMalePlaceholder : AvatarFemalePlaceholder}
            alt={name}
            className='h-full w-full object-cover'
          />
        </div>

        <div className='flex flex-col ellipsis'>
          <h4 title={name} className='mb-[4px] max-w-[80%] text-h4 text-on-surface ellipsis'>
            {name}
          </h4>

          <p
            title={`${getGender(gender)} ${birthday ? `, ${calculateAge(birthday)} лет` : ''}`}
            className='mb-[4px] text-lm text-on-surface-variant ellipsis'
          >
            {getGender(gender)} {birthday ? `, ${calculateAge(birthday)} лет` : ''}
          </p>

          <p title={email} className='text-lm text-primary ellipsis'>
            {email}
          </p>
        </div>
      </div>

      <div className='p-[12px_24px_12px_24px] flex flex-col bg-secondary-container'>
        <h4
          title={role || 'Неизвестно'}
          className='mb-[4px] text-h4 text-on-secondary-fixed ellipsis'
        >
          {role || 'Неизвестно'}
        </h4>

        <p title={company || 'Неизвестно'} className='text-lm text-on-surface-variant ellipsis'>
          {company || 'Неизвестно'}
        </p>
      </div>
    </div>
  );
};

export default UserCard;
