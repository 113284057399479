import { FC, useMemo, useState } from 'react';

import { getTestingScore } from 'lib';
import { Testing } from 'models/testing';

import { Button, Drawer, Ratio, TestingResult } from 'components';

type TestingCardProps = {
  testing: Testing;
  className?: string;
};

const TestingCard: FC<TestingCardProps> = props => {
  const { testing, className } = props;
  const [resultsModalOpen, setResultsModal] = useState(false);
  const testingScore = useMemo(() => getTestingScore(testing), [testing]);

  const handleResults = () => {
    setResultsModal(true);
  };

  return (
    <div className={`flex justify-between items-center gap-[16px] ${className}`}>
      <div className='max-w-[440px] flex flex-col gap-[8px] ellipsis'>
        <div className='flex gap-[8px] ellipsis'>
          <h4 className='text-h4 text-tertiary'>Тест:</h4>
          <h4 title={testing.name} className='text-h4 text-on-surface ellipsis'>
            {testing.name}
          </h4>
        </div>
      </div>

      <div className='shrink-0 flex gap-[16px] items-center'>
        <Ratio value={testingScore} />
        <Button variant='secondary' title='Результат теста' onClick={handleResults} />
      </div>

      <Drawer
        isOpen={resultsModalOpen}
        anchor='right'
        portal
        onClose={() => setResultsModal(false)}
      >
        <TestingResult testing={testing} onClose={() => setResultsModal(false)} />
      </Drawer>
    </div>
  );
};

export default TestingCard;
