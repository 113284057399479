import { QuestionType } from 'models/question(old)';
import { Testing } from 'models/testing';

export const getTestingScore = (testing: Testing): number => {
  const { groupedAnswers } = testing;

  const sum = groupedAnswers.reduce((acc, groupedAnswer) => {
    return (
      groupedAnswer.innerAnswers
        .filter(innerAnswer => {
          if (innerAnswer.questionType === QuestionType.NEUTRAL) {
            return false;
          }
          return true;
        })
        .reduce((innerAcc, innerAnswer) => {
          if (
            !innerAnswer.correlationResultAverageXYZ ||
            !innerAnswer.correlationResultAverageXYZ.accelerometer
          )
            return innerAcc;
          return innerAnswer.correlationResultAverageXYZ.accelerometer + innerAcc;
        }, 0) + acc
    );
  }, 0);

  const questionAmount = groupedAnswers.reduce(
    (acc, groupedAnswer) =>
      groupedAnswer.innerAnswers.filter(innerAnswer => {
        if (innerAnswer.questionType === QuestionType.NEUTRAL) {
          return false;
        }
        return true;
      }).length + acc,
    0
  );

  const averageScore = sum / questionAmount;
  return averageScore ? Math.round(averageScore) : 0;
};
