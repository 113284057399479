import axios from 'axios';
import { FC, ReactNode } from 'react';

import { instance } from 'api/instance';
import { serverString } from 'appConfig';
import { useAuthStore } from 'store/auth';

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const setLogin = useAuthStore(state => state.setLogin);

  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const response = await axios.post(`${serverString}/auth/refresh/${refreshToken}`);
    return response.data;
  };

  instance.interceptors.response.use(
    response => {
      return response;
    },
    async error => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        try {
          originalRequest._retry = true;
          const { accessToken, refreshToken } = await refreshAccessToken();

          if (accessToken && refreshToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
            localStorage.setItem('token', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            return instance(originalRequest);
          }
        } catch (error) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          setLogin(false);
        }
      }
      return Promise.reject(error);
    }
  );

  instance.interceptors.request.use(
    config => {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    config => {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      };
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  return <>{children}</>;
};

export default AuthProvider;
