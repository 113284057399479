import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getCompanies } from 'api/company';
import { COMPANIES_QUERY_KEY } from 'const/company';
import { handleError } from 'helpers';
import { GetCompaniesResponse } from 'models/company';

export const useGetCompanies = () => {
  return useQuery<GetCompaniesResponse, AxiosError>([COMPANIES_QUERY_KEY], () => getCompanies(), {
    onError(error) {
      handleError(error);
    }
  });
};
