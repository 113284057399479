import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import { MasterRouteConfig } from 'const/routes';

import { SidebarLayout } from 'layouts';

const MasterRoutes: FC = () => {
  return (
    <SidebarLayout>
      <Routes>
        {Object.values(MasterRouteConfig).map(({ path, Component, element }) => (
          <Route key={path} path={path} Component={Component} element={element} />
        ))}
      </Routes>
    </SidebarLayout>
  );
};

export default MasterRoutes;
