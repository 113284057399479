import { FC } from 'react';
import { Control, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';
import { answerPresets } from 'const/question(old)';
import { AnswerPreset } from 'models/question(old)';
import { TestFormValues } from 'models/test(old)';

import {
  Button,
  Checkbox,
  IconAnonymous,
  IconCross,
  IconMan,
  IconRepeat,
  IconWoman,
  TextArea,
  TextField
} from 'components';

type QuestionAnswersProps = {
  index: number;
  groupIndex: number;
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  onClose: () => void;
};

const QuestionAnswers: FC<QuestionAnswersProps> = props => {
  const { index, groupIndex, control, trigger, getValues, setValue, onClose } = props;

  const handleAnswerPreset = (answerPreset: AnswerPreset) => {
    const { left, leftMale, leftFemale, right, rightMale, rightFemale } = answerPreset.fields;

    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.default`,
      left
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.male`,
      leftMale
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.female`,
      leftFemale
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.default`,
      right
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.male`,
      rightMale
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.female`,
      rightFemale
    );
  };

  const switchAnswers = () => {
    const left = getValues(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.default`
    );
    const right = getValues(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.default`
    );
    const leftMale = getValues(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.male`
    );
    const rightMale = getValues(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.male`
    );
    const leftFemale = getValues(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.female`
    );
    const rightFemale = getValues(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.female`
    );

    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.default`,
      right
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.default`,
      left
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.male`,
      rightMale
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.male`,
      leftMale
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.female`,
      rightFemale
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.female`,
      leftFemale
    );
  };

  const handleClear = () => {
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.default`,
      ''
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.default`,
      ''
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.male`,
      ''
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.male`,
      ''
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.female`,
      ''
    );
    setValue(
      `groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.female`,
      ''
    );
  };

  const handleSave = async () => {
    const isValid = await trigger(`groupedQuestions.${groupIndex}.innerQuestions.${index}`);

    if (isValid) {
      onClose();
    }
  };

  return (
    <div className='grow flex flex-col'>
      <header className='mb-[36px] flex justify-between gap-[8px] items-center'>
        <h3 className='text-h3 text-on-surface'>Ответы</h3>
        <button type='button' className='h-[32px] w-[32px] bg-transparent' onClick={onClose}>
          <IconCross className='h-full w-full text-outline' />
        </button>
      </header>

      <TextArea
        name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.question`}
        label='Вопрос'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 256, message: 'Максимальная длина - 256 символов' }
        }}
        rows={3}
        containerClassName='mb-[36px] flex-none'
      />

      <div className='mb-[32px] flex items-center gap-[8px]'>
        <Checkbox
          control={control}
          name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.equalAnswers`}
        />
        <label
          htmlFor={`groupedQuestions.${groupIndex}.innerQuestions.${index}.equalAnswers`}
          className='text-bl text-on-surface'
        >
          Ответы равнозначные
        </label>
      </div>

      <div className='flex flex-col grow mb-auto'>
        <div className='flex items-center gap-[16px] mb-[16px]'>
          <div
            className={
              'p-[20px] flex flex-col flex-auto ' +
              'rounded-[20px] border-[1px] border-solid border-outline-variant'
            }
          >
            <h4 className='mb-[20px] text-h4 text-on-surface'>Ответ первый</h4>

            <div className='mb-[16px] flex gap-[8px] items-center'>
              <TextField
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.default`}
                label='Ответ'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
                }}
              />
              <IconAnonymous className='text-outline h-[24px] w-[24px]' />
            </div>

            <div className='mb-[16px] flex gap-[8px] items-center'>
              <TextField
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.male`}
                label='Ответ мужчине'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
                }}
              />
              <IconMan className='text-primary h-[24px] w-[24px]' />
            </div>

            <div className='mb-[16px] flex gap-[8px] items-center'>
              <TextField
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.female`}
                label='Ответ женщине'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
                }}
              />
              <IconWoman className='text-tertiary h-[24px] w-[24px]' />
            </div>

            <div className='flex items-center gap-[8px]'>
              <Checkbox
                control={control}
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.negative`}
              />
              <label
                htmlFor={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalLeft.negative`}
                className='text-bl text-on-surface'
              >
                Негативный ответ
              </label>
            </div>
          </div>

          <div
            className={
              'p-[20px] flex flex-col flex-auto ' +
              'rounded-[20px] border-[1px] border-solid border-outline-variant'
            }
          >
            <h4 className='mb-[20px] text-h4 text-on-surface'>Ответ второй</h4>

            <div className='mb-[16px] flex gap-[8px] items-center'>
              <TextField
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.default`}
                label='Ответ'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
                }}
              />
              <IconAnonymous className='text-outline h-[24px] w-[24px]' />
            </div>

            <div className='mb-[16px] flex gap-[8px] items-center'>
              <TextField
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.male`}
                label='Ответ мужчине'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
                }}
              />
              <IconMan className='text-primary h-[24px] w-[24px]' />
            </div>

            <div className='mb-[16px] flex gap-[8px] items-center'>
              <TextField
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.female`}
                label='Ответ женщине'
                control={control}
                rules={{
                  required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
                  maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
                }}
              />
              <IconWoman className='text-tertiary h-[24px] w-[24px]' />
            </div>

            <div className='flex items-center gap-[8px]'>
              <Checkbox
                control={control}
                name={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.negative`}
              />
              <label
                htmlFor={`groupedQuestions.${groupIndex}.innerQuestions.${index}.binaryAnswer.conditionalRight.negative`}
                className='text-bl text-on-surface'
              >
                Негативный ответ
              </label>
            </div>
          </div>
        </div>

        <div className='flex gap-[8px] flex-wrap mb-[36px]'>
          {answerPresets.map(answer => (
            <Button
              key={answer.title}
              variant='bordered'
              height='small'
              title={answer.title}
              onClick={() => handleAnswerPreset(answer)}
            />
          ))}

          <Button
            variant='bordered'
            height='small'
            title='Очистить'
            icon={<IconCross className='h-[18px] w-[18px]' />}
            onClick={handleClear}
          />
        </div>

        <Button
          variant='bordered'
          title='Поменять ответы местами'
          icon={<IconRepeat className='h-[18px] w-[18px]' />}
          onClick={switchAnswers}
          className='self-center'
        />
      </div>

      <div className='flex gap-[8px] self-end pb-[40px] mt-[40px]'>
        <Button variant='secondary' title='Отменить' className='min-w-[160px]' onClick={onClose} />
        <Button
          variant='primary'
          title='Сохранить'
          className='min-w-[160px]'
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default QuestionAnswers;
