import { useMutation, useQueryClient } from 'react-query';

import { updateTestFormQuestions } from 'api/test-form';
import { handleError, notifySuc } from 'helpers';
import { SetTestFormQuestionsDto } from 'models/test-form';

export const useUpdateTestFormQuestionsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: SetTestFormQuestionsDto) => {
      return updateTestFormQuestions(data);
    },
    {
      onSuccess(newData, { testId, refetch = true }) {
        notifySuc('Анкета теста обновлена');

        if (refetch) {
          const queryKey = `tests/${testId}/form`;

          queryClient.invalidateQueries(queryKey, {
            refetchActive: false,
            refetchInactive: false
          });

          queryClient.setQueryData(queryKey, newData);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
