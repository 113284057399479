import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { DEFAULT_ANIMATION_DELAY } from 'const/animation';

import { Portal } from 'components';

type DrawerProps = {
  children: React.ReactNode;
  isOpen: boolean;
  anchor: 'left' | 'right';
  onClose: () => void;
  portal?: boolean;
  lazy?: boolean;
  className?: string;
};

const Drawer: FC<DrawerProps> = props => {
  const { children, isOpen, anchor, onClose, portal = false, lazy = true, className } = props;
  const [isClosing, setIsClosing] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    timerRef.current = setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, DEFAULT_ANIMATION_DELAY);
  }, [onClose]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
      document.body.classList.add('overflow-hidden');
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      document.body.classList.remove('overflow-hidden');
      clearTimeout(timerRef.current);
    };
  }, [isOpen, onKeyDown]);

  if (lazy && !isMounted) {
    return null;
  }

  const Component = (
    <div
      className={
        'fixed top-0 left-0 right-0 bottom-0 flex ' +
        'transition-all duration-300 ease-in-out ' +
        `${
          isOpen
            ? 'opacity-100 z-[var(--z-index-modal)] pointer-events-auto'
            : 'opacity-0 z-[var(--z-index-hidden)] pointer-events-none'
        }`
      }
    >
      <div
        className='h-full w-full flex justify-end bg-[var(--color-overlay)] cursor-pointer'
        onClick={handleClose}
      >
        <div
          tabIndex={-1}
          className={
            'h-full max-w-[80%] min-w-[30%] p-[40px] pb-0 flex grow ' +
            'bg-surface shadow-[0_8px_10px_-5px_rgba(0,0,0,0.2)] outline-0 cursor-auto ' +
            'overflow-y-auto overflow-x-hidden transition-[transform] ease-in-out duration-300 ' +
            `${anchor === 'left' ? 'left-0 right-auto' : 'right-0 left-auto'} ` +
            `${
              isClosing
                ? 'translate-x-full shrink-0'
                : isOpen
                ? 'transform-none'
                : 'translate-x-full shrink-0'
            } ` +
            className
          }
          onClick={event => event.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </div>
  );

  if (portal) return <Portal>{Component}</Portal>;
  return Component;
};

export default Drawer;
