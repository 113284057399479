import { useMutation } from 'react-query';

import { generateCompanyCharacteristic } from 'api/microservice';
import { handleError, notifySuc } from 'helpers';
import { GenerateCompanyCharacteristicDto } from 'models/microservice';

export const useGenerateCompanyCharacteristic = () => {
  return useMutation(
    (dto: GenerateCompanyCharacteristicDto) => {
      return generateCompanyCharacteristic(dto);
    },
    {
      onSuccess() {
        notifySuc('Данные сгенерированы');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
