import { FC } from 'react';
import {
  Control,
  UseFieldArrayRemove,
  UseFormGetValues,
  UseFormSetValue,
  UseFormWatch,
  useForm
} from 'react-hook-form';

import {
  Button,
  ButtonIcon,
  IconCross,
  IconMagic,
  IconTrash,
  TextEditor,
  TextField
} from 'components';
import { FORM_ERROR_MESSAGES, htmlToNotBeEmpty } from 'const/form';
import { useGenerateByInstruction, useGenerateFeedbackForGroup } from 'hooks/microservice';
import { TestFormValues } from 'models/test(old)';

type RecommendCardProps = {
  index: number;
  groupIndex: number;
  control: Control<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  removeFeedback: UseFieldArrayRemove;
  className?: string;
};

const RecommendCard: FC<RecommendCardProps> = props => {
  const { index, groupIndex, control, getValues, watch, setValue, removeFeedback, className } =
    props;
  const { mutate: generateFeedback, isLoading: generateFeedbackLoading } =
    useGenerateFeedbackForGroup();
  const { mutate: generateByInstruction, isLoading: generateByInstructionLoading } =
    useGenerateByInstruction();

  const {
    control: control1,
    watch: watch1,
    setValue: setValue1,
    formState: { isDirty: isDirty1 }
  } = useForm<{ instruction: string }>();
  const instruction = watch1('instruction');

  const from = watch(`groupedQuestions.${groupIndex}.feedback.recommendationCompany.${index}.min`);
  const to = watch(`groupedQuestions.${groupIndex}.feedback.recommendationCompany.${index}.max`);

  const handleClear = () => {
    setValue(`groupedQuestions.${groupIndex}.feedback.recommendationCompany.${index}.feedback`, '');
  };

  const handleGenerate = () => {
    if (instruction && instruction !== '<p><br></p>') {
      generateByInstruction(
        {
          instruction
        },
        {
          onSuccess: response => {
            setValue(
              `groupedQuestions.${groupIndex}.feedback.recommendationCompany.${index}.feedback`,
              response
            );
          }
        }
      );
    } else {
      const title = getValues(`groupedQuestions.${groupIndex}.question`);
      const questions = getValues(`groupedQuestions.${groupIndex}.innerQuestions`).map(
        ({ question }) => question
      );

      // TODO get it from form values
      const answer = true;

      generateFeedback(
        {
          title,
          questions,
          answer,
          confidence: { from, to },
          type: 'recommendation',
          recipient: 'company'
        },
        {
          onSuccess: ({ result, instruction }) => {
            setValue(
              `groupedQuestions.${groupIndex}.feedback.recommendationCompany.${index}.feedback`,
              result
            );
            setValue1('instruction', instruction, { shouldDirty: true, shouldTouch: true });
          }
        }
      );
    }
  };

  return (
    <li className={`flex flex-col ${className}`}>
      <header className='mb-[16px] flex justify-between'>
        <div className='flex gap-[8px]'>
          <TextField
            type='number'
            name={`groupedQuestions.${groupIndex}.feedback.recommendationCompany.${index}.min`}
            label='от'
            wide={false}
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              min: { value: 0, message: 'Минимальное значение - 0' },
              max: { value: 100, message: 'Максимальное значение - 100' }
            }}
            containerClassName='w-[80px] shrink-0'
          />
          <TextField
            type='number'
            name={`groupedQuestions.${groupIndex}.feedback.recommendationCompany.${index}.max`}
            label='до'
            wide={false}
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              min: { value: 0, message: 'Минимальное значение - 0' },
              max: { value: 100, message: 'Максимальное значение - 100' }
            }}
            containerClassName='w-[80px] shrink-0'
          />
        </div>

        <ButtonIcon
          Icon={IconCross}
          variant='filled'
          size='small'
          onClick={() => removeFeedback(index)}
        />
      </header>

      <div className='p-[12px_12px_24px_24px] bg-light elevation-2 rounded-[16px]'>
        <header className='mb-[12px] flex justify-between'>
          <h5 className='text-h5 text-tertiary'>
            Рекомендация для компании {from}-{to}
          </h5>

          <Button
            variant='transparent'
            height='small'
            title='Очистить поле'
            icon={<IconTrash className='h-[18px] w-[18px]' />}
            onClick={handleClear}
          />
        </header>

        <TextEditor
          name={`groupedQuestions.${groupIndex}.feedback.recommendationCompany.${index}.feedback`}
          placeholder='Результат для пользователя'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            validate: {
              HtmlToNotBeEmpty: htmlToNotBeEmpty
            }
          }}
          containerClassName='mb-[24px]'
        />

        <div className='flex flex-col gap-[10px]'>
          <TextEditor
            name='instruction'
            label='Инструкция для генерации фидбэка'
            control={control1}
            disabled={!isDirty1}
          />

          <Button
            variant='tertiary'
            height='big'
            title='Сгенерировать'
            icon={<IconMagic className='h-[18px] w-[18px]' />}
            iconPosition='right'
            onClick={handleGenerate}
            isLoading={generateFeedbackLoading || generateByInstructionLoading}
            className='self-end'
          />
        </div>
      </div>
    </li>
  );
};

export default RecommendCard;
