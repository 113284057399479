import { useMutation, useQueryClient } from 'react-query';

import { apiTest } from 'api/test';
import { TESTS_QUERY_KEY } from 'const/test';
import { handleError, notifySuc } from 'helpers';
import { GetTestsResponse, UpdateTestDto } from 'models/test(old)';

export const useUpdateTestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateTestDto) => {
      return apiTest.updateTest(data);
    },
    {
      onSuccess(updatedTest, { refetch = true }) {
        notifySuc('Тест обновлен');

        if (refetch) {
          queryClient.invalidateQueries(TESTS_QUERY_KEY, {
            refetchActive: false,
            refetchInactive: false
          });

          const oldData = queryClient.getQueryData<GetTestsResponse>(TESTS_QUERY_KEY);
          if (oldData) {
            const updatedData = oldData.map(test =>
              test._id === updatedTest._id ? updatedTest : test
            );
            queryClient.setQueryData(TESTS_QUERY_KEY, updatedData);
          }

          // TODO invalidate single test query by id, when it will be ready
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
