import { GroupedAnswersResult } from 'models/testing';

export const getTestingGroupScore = (groupedAnswer: GroupedAnswersResult): number => {
  const { innerAnswers } = groupedAnswer;

  const sum = innerAnswers.reduce((acc, innerAnswer) => {
    if (
      !innerAnswer.correlationResultAverageXYZ ||
      !innerAnswer.correlationResultAverageXYZ.accelerometer
    )
      return acc;
    return innerAnswer.correlationResultAverageXYZ.accelerometer + acc;
  }, 0);

  const questionAmount = innerAnswers.length;

  const averageScore = sum / questionAmount;
  return averageScore ? Math.round(averageScore) : 0;
};
