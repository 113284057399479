import { useMutation, useQueryClient } from 'react-query';

import { apiTest } from 'api/test';
import { handleError, notifySuc } from 'helpers';
import { SetTestQuestionsDto } from 'models/test(old)';

export const useUpdateTestQuestionsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: SetTestQuestionsDto) => {
      return apiTest.updateTestQuestions(data);
    },
    {
      onSuccess(updatedData, { testId, refetch = true }) {
        notifySuc('Вопросы теста обновлены');

        if (refetch) {
          const queryKey = `tests/${testId}/questions`;

          queryClient.invalidateQueries([queryKey], {
            refetchActive: false,
            refetchInactive: false
          });

          queryClient.setQueryData(queryKey, updatedData);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
