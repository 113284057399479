import { useMutation, useQueryClient } from 'react-query';

import { updateOpenQuestions } from 'api/open-questions';
import { handleError, notifySuc } from 'helpers';
import { UpdateOpenQuestionsDto } from 'models/open-question';

export const useUpdateOpenQuestionsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: UpdateOpenQuestionsDto) => {
      return updateOpenQuestions(data);
    },
    {
      onSuccess(updatedData, { testId, refetch = true }) {
        notifySuc('Открытые вопросы обновлены');

        if (refetch) {
          const queryKey = `open_questions/${testId}`;

          queryClient.invalidateQueries([queryKey], {
            refetchActive: false,
            refetchInactive: false
          });

          queryClient.setQueryData(queryKey, updatedData);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
