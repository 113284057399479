import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';

import { Button, TextField } from 'components';
import { useCreateCompanyMutation } from 'hooks/company';
import { CompanyFormValues } from 'models/company';

type CreateCompanyFormProps = {
  onClose?: () => void;
  className?: string;
};

const CreateCompanyForm: FC<CreateCompanyFormProps> = props => {
  const { onClose, className } = props;
  const { mutate: createCompanyMutate, isLoading: createCompanyLoading } =
    useCreateCompanyMutation();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty }
  } = useForm<CompanyFormValues>({ mode: 'onSubmit' });

  const handleReset = () => {
    reset();
  };

  const onSubmit = (data: CompanyFormValues) => {
    createCompanyMutate(
      { body: data },
      {
        onSuccess: () => {
          reset();
          onClose?.();
        }
      }
    );
  };

  return (
    <div className={`flex flex-col w-[720px] ${className}`}>
      <header className='mb-[36px] flex'>
        <h3 className='text-h3 text-on-surface'>Добавить компанию</h3>
      </header>

      <form className='flex flex-col' onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-[36px] flex flex-col gap-[16px]'>
          <TextField
            name='name'
            label='Наименование компании'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
            }}
            disabled={createCompanyLoading}
          />
          <TextField
            name='companyCode'
            label='Промокод компании'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
            }}
            disabled={createCompanyLoading}
          />
        </div>

        <div className='flex gap-[8px] self-end pb-[40px]'>
          <Button
            variant='secondary'
            title='Сбросить'
            onClick={handleReset}
            disabled={!isDirty || createCompanyLoading}
            className='min-w-[160px]'
          />
          <Button
            type='submit'
            variant='primary'
            title='Добавить'
            disabled={!isDirty}
            isLoading={createCompanyLoading}
            className='min-w-[160px]'
          />
        </div>
      </form>
    </div>
  );
};

export default CreateCompanyForm;
