import {
  CreateTestDto,
  DeleteTestDto,
  GetTestQuestionsDto,
  GetTestQuestionsResponse,
  GetTestsResponse,
  SetTestQuestionsDto,
  Test,
  UpdateTestDto
} from 'models/test(old)';
import { instance } from './instance';

export const apiTest = {
  getTests: async () => {
    const response = await instance.get<GetTestsResponse>('tests');
    return response.data;
  },
  createTest: async ({ body }: CreateTestDto) => {
    const response = await instance.post<Test>('tests', body);
    return response.data;
  },
  updateTest: async ({ testId, body }: UpdateTestDto) => {
    const response = await instance.patch<Test>(`tests/${testId}`, body);
    return response.data;
  },
  deleteTest: async ({ testId }: DeleteTestDto) => {
    const response = await instance.delete(`tests/${testId}`);
    return response.data;
  },

  getTestQuestions: async ({ testId }: GetTestQuestionsDto) => {
    const response = await instance.get<GetTestQuestionsResponse>(`tests/${testId}/questions`);
    return response.data;
  },
  setTestQuestions: async ({ testId, body }: SetTestQuestionsDto) => {
    const response = await instance.post<GetTestQuestionsResponse>(
      `tests/${testId}/questions`,
      body
    );
    return response.data;
  },
  updateTestQuestions: async ({ testId, body }: SetTestQuestionsDto) => {
    const response = await instance.put<GetTestQuestionsResponse>(
      `tests/${testId}/questions`,
      body
    );
    return response.data;
  },
  deleteTestQuestions: async ({ testId }: DeleteTestDto) => {
    const response = await instance.delete(`tests/${testId}/questions`);
    return response.data;
  }
};
