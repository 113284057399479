import { ChangeEvent, InputHTMLAttributes, KeyboardEvent, useState } from 'react';
import { FieldValues, RegisterOptions, UseControllerProps, useController } from 'react-hook-form';

import { IconChecked, IconUnchecked } from 'components';

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  rules?: RegisterOptions;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
  loading?: boolean;
};

export type CheckboxProps<T extends FieldValues> = UseControllerProps<T> & InputProps;

const Checkbox = <T extends FieldValues>({
  name,
  control,
  rules = {},
  className,
  containerClassName,
  disabled = false,
  loading = false,
  ...rest
}: CheckboxProps<T>) => {
  const { field, fieldState } = useController<T>({
    name,
    control,
    rules
  });

  // when design is ready add styles for focused state
  const [inputFocus, setInputFocus] = useState(false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.checked);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <label
      htmlFor={name}
      className={
        'h-[24px] w-[24px] shrink-0 select-none relative ' +
        `${disabled ? 'cursor-auto text-[#646464]' : 'cursor-pointer'} ` +
        `${fieldState.error ? 'text-error' : 'text-primary'} ` +
        `${containerClassName ? containerClassName : ''}`
      }
    >
      <input
        type='checkbox'
        id={name}
        className={'appearance-none absolute ' + `${className ? className : ''}`}
        disabled={disabled || loading}
        readOnly={disabled || loading}
        checked={field.value || false}
        onChange={handleChange}
        onFocus={() => setInputFocus(true)}
        onBlur={() => setInputFocus(false)}
        onKeyDown={handleKeyDown}
        {...rest}
      />

      <IconChecked
        className={
          'absolute transition-opacity duration-[150ms] ease-in-out top-0 left-0 ' +
          `${field.value ? 'opacity-100' : 'opacity-0'}`
        }
      />
      <IconUnchecked
        className={
          'absolute transition-opacity duration-[150ms] ease-in-out top-0 left-0 ' +
          `${field.value ? 'opacity-0' : 'opacity-100'}`
        }
      />
    </label>
  );
};

export default Checkbox;
