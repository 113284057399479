import { FC, useState } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from 'react-hook-form';

import { Button, IconCross, TextField } from 'components';
import { FORM_ERROR_MESSAGES } from 'const/form';
import { TestFormValues } from 'models/test(old)';
import { FeedbackCompany } from './FeedbackCompany';
import { FeedbackMasterManager } from './FeedbackMasterManager';
import { FeedbackUser } from './FeedbackUser';
import { RecommendCompany } from './RecommendCompany';
import { RecommendMasterManager } from './RecommendMasterManager';
import { RecommendUser } from './RecommendUser';
import { Tabs } from './Tabs';
import { FeedbackType, ForWhom } from './types';

type GroupResultsProps = {
  groupIndex: number;
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  onClose: () => void;
};

const GroupResults: FC<GroupResultsProps> = props => {
  const { groupIndex, control, trigger, getValues, watch, setValue, onClose } = props;
  const [forWhom, setForWhom] = useState<ForWhom>(ForWhom.USER);
  const [feedbackType, setFeedbackType] = useState<FeedbackType>(FeedbackType.FEEDBACK);

  const handleSave = async () => {
    const isValid = await trigger(`groupedQuestions.${groupIndex}.feedback`);

    if (isValid) {
      onClose();
    }
  };

  return (
    <div className='flex flex-col grow'>
      <header className='mb-[36px] flex justify-between gap-[8px] items-center'>
        <h3 className='text-h3 text-on-surface'>Фидбек по группе вопросов</h3>
        <button type='button' className='h-[32px] w-[32px] bg-transparent' onClick={onClose}>
          <IconCross className='h-full w-full text-outline' />
        </button>
      </header>

      <TextField
        name={`groupedQuestions.${groupIndex}.question`}
        label='Группа вопросов'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
        }}
        containerClassName='mb-[36px] grow-0'
      />

      <Tabs
        forWhom={forWhom}
        setForWhom={setForWhom}
        feedbackType={feedbackType}
        setFeedbackType={setFeedbackType}
        className='mb-[36px]'
      />

      {forWhom === ForWhom.USER && feedbackType === FeedbackType.FEEDBACK && (
        <FeedbackUser
          groupIndex={groupIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.MASTER_MANAGER && feedbackType === FeedbackType.FEEDBACK && (
        <FeedbackMasterManager
          groupIndex={groupIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.COMPANY && feedbackType === FeedbackType.FEEDBACK && (
        <FeedbackCompany
          groupIndex={groupIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.USER && feedbackType === FeedbackType.RECOMMENDATION && (
        <RecommendUser
          groupIndex={groupIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.MASTER_MANAGER && feedbackType === FeedbackType.RECOMMENDATION && (
        <RecommendMasterManager
          groupIndex={groupIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      {forWhom === ForWhom.COMPANY && feedbackType === FeedbackType.RECOMMENDATION && (
        <RecommendCompany
          groupIndex={groupIndex}
          control={control}
          getValues={getValues}
          watch={watch}
          setValue={setValue}
          className='mb-auto'
        />
      )}

      <div className='flex self-end gap-[8px] mt-[36px] pb-[40px]'>
        <Button variant='secondary' title='Отменить' className='min-w-[160px]' onClick={onClose} />
        <Button
          variant='primary'
          title='Сохранить'
          className='min-w-[160px]'
          onClick={handleSave}
        />
      </div>
    </div>
  );
};

export default GroupResults;
