import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getPromocodes } from 'api/promocode';
import { handleError } from 'helpers';
import { GetPromocodesResponse } from 'models/promocode';

export const useGetPromocodes = () => {
  return useQuery<GetPromocodesResponse, AxiosError>(
    ['promocodes/supervisor'],
    () => getPromocodes(),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
