import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FC } from 'react';
import { Control, FieldArrayWithId, UseFieldArrayRemove } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';
import { TestFormValues } from 'models/test(old)';

import { ButtonIcon, IconDragHandle, IconTrash, TextField } from 'components';

type OptionCardProps = {
  option: FieldArrayWithId<TestFormValues, `formQuestions.${number}.answerOptions`, 'id'>;
  questionIndex: number;
  index: number;
  control: Control<TestFormValues>;
  remove: UseFieldArrayRemove;
};

const OptionCard: FC<OptionCardProps> = ({ option, questionIndex, index, control, remove }) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } =
    useSortable({
      id: option.id
    });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <li ref={setNodeRef} {...attributes} style={style} className='flex gap-[8px] items-center'>
      <button
        type='button'
        ref={setActivatorNodeRef}
        {...listeners}
        className={
          'h-[32px] w-[32px] flex justify-center items-center shrink-0 ' +
          'bg-transparent text-outline cursor-grab'
        }
      >
        <IconDragHandle />
      </button>

      <TextField
        name={`formQuestions.${questionIndex}.answerOptions.${index}.value`}
        label='Вариант ответа'
        control={control}
        rules={{
          required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
          maxLength: { value: 64, message: 'Максимальная длина - 64 символов' }
        }}
      />

      <ButtonIcon Icon={IconTrash} variant='transparent' size='big' onClick={() => remove(index)} />
    </li>
  );
};

export default OptionCard;
