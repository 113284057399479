import { FC } from 'react';
import { Control } from 'react-hook-form';

import { Option } from 'models/option';
import { TestFormValues, TestVisibility } from 'models/test(old)';

import { Select } from 'components';

const options: Option[] = [
  { value: TestVisibility.PUBLIC, label: 'Публичный' },
  { value: TestVisibility.PRIVATE, label: 'Приватный' }
];

type PublicationSelectProps = {
  control: Control<TestFormValues>;
};

const PublicationSelect: FC<PublicationSelectProps> = ({ control }) => {
  return (
    <Select
      name='visibility'
      label='Видимость'
      control={control}
      options={options}
      containerClassName='min-w-[200px]'
    />
  );
};

export default PublicationSelect;
