import {
  AddPromocodeToTestsDto,
  AddPromocodeToUserDto,
  DeletePromocodeDto,
  GetPromocodesResponse
} from 'models/promocode';
import { instance } from './instance';

export const getPromocodes = async () => {
  const response = await instance.get<GetPromocodesResponse>('promocodes/supervisor');
  return response.data;
};

export const addPromocodeToUser = async ({ body }: AddPromocodeToUserDto) => {
  const response = await instance.post('promocodes', body);
  return response.data;
};

export const addPromocodeToTests = async ({ body }: AddPromocodeToTestsDto) => {
  const response = await instance.post('promocodes/supervisor', body);
  return response.data;
};

export const deletePromocode = async ({ promocodeId }: DeletePromocodeDto) => {
  const response = await instance.delete(`promocodes/supervisor/${promocodeId}`);
  return response.data;
};
