import { FC } from 'react';
import ReactQuill from 'react-quill';

import { useToggle } from 'hooks/common';

import { Button, IconChevronLeft } from 'components';

type TextPreviewProps = {
  value: string;
};

const TextPreview: FC<TextPreviewProps> = ({ value }) => {
  const [showFull, toggleShowFull] = useToggle(false);

  return (
    <div className='flex flex-col gap-[8px]'>
      <div
        className={
          'transition-all duration-300 ease-in-out overflow-hidden ' +
          `${showFull ? 'max-h-full' : 'max-h-[48px]'}`
        }
      >
        <ReactQuill theme='snow' readOnly value={value} modules={{ toolbar: false }} />
      </div>

      <Button
        variant='transparent'
        height='small'
        title={showFull ? 'Свернуть' : 'Развернуть'}
        icon={
          <IconChevronLeft
            className={'h-[18px] w-[18px] ' + `${showFull ? 'rotate-[90deg]' : 'rotate-[270deg]'}`}
          />
        }
        iconPosition='right'
        onClick={toggleShowFull}
      />
    </div>
  );
};

export default TextPreview;
