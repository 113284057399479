import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { getAccount } from 'api/account';
import { handleError } from 'helpers';
import { GetAccountDto, GetAccountResponse } from 'models/user';

export const useGetAccount = (dto: GetAccountDto) => {
  return useQuery<GetAccountResponse, AxiosError>(
    ['account/user', dto.userId],
    () => getAccount(dto),
    {
      onError(error) {
        handleError(error);
      }
    }
  );
};
