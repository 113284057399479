import { useMutation, useQueryClient } from 'react-query';

import { apiTest } from 'api/test';
import { handleError, notifySuc } from 'helpers';
import { SetTestQuestionsDto } from 'models/test(old)';

export const useSetTestQuestionsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: SetTestQuestionsDto) => {
      return apiTest.setTestQuestions(data);
    },
    {
      onSuccess(newData, { testId }) {
        notifySuc('Вопросы теста добавлены');

        const queryKey = `tests/${testId}/questions`;

        queryClient.invalidateQueries(queryKey, {
          refetchActive: false,
          refetchInactive: false
        });

        queryClient.setQueryData(queryKey, newData);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
