import { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react';

import { DEFAULT_ANIMATION_DELAY } from 'const/animation';

import { IconCross, Portal } from 'components';

interface IModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  portal?: boolean;
  lazy?: boolean;
  className?: string;
}

const Modal: FC<IModalProps> = props => {
  const { children, isOpen, onClose, portal = false, lazy = true, className } = props;
  const [isClosing, setIsClosing] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (isOpen) {
      setIsMounted(true);
    }
  }, [isOpen]);

  const handleClose = useCallback(() => {
    setIsClosing(true);
    timerRef.current = setTimeout(() => {
      onClose();
      setIsClosing(false);
    }, DEFAULT_ANIMATION_DELAY);
  }, [onClose]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('keydown', onKeyDown);
      document.body.classList.add('overflow-hidden');
    }

    return () => {
      window.removeEventListener('keydown', onKeyDown);
      document.body.classList.remove('overflow-hidden');
      clearTimeout(timerRef.current);
    };
  }, [isOpen, onKeyDown]);

  if (lazy && !isMounted) {
    return null;
  }

  if (portal)
    return (
      <Portal>
        <div
          className={
            'fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center ' +
            'transition-all duration-300 ease-in-out ' +
            `${
              isOpen
                ? 'opacity-100 z-[var(--z-index-modal)] pointer-events-auto'
                : 'opacity-0 z-[var(--z-index-hidden)] pointer-events-none'
            }`
          }
        >
          <div
            className={
              'h-full w-full flex justify-center items-center bg-[var(--color-overlay)] cursor-pointer'
            }
            onClick={handleClose}
          >
            <div
              className={
                'max-h-[90vh] mx-[20px] p-[40px] pb-0 flex ' +
                'bg-surface rounded-[16px] elevation-5 ' +
                'transition-[transform] duration-300 ease-in-out cursor-auto ' +
                `${isClosing ? 'scale-[0.6]' : isOpen ? 'scale-[1]' : 'scale-[0.6]'} ` +
                className
              }
              onClick={event => event.stopPropagation()}
            >
              <button
                type='button'
                className='absolute h-[32px] w-[32px] bg-transparent top-[20px] right-[20px]'
                onClick={handleClose}
              >
                <IconCross className='h-full w-full text-outline' />
              </button>
              {children}
            </div>
          </div>
        </div>
      </Portal>
    );

  return (
    <div
      className={
        'fixed top-0 right-0 bottom-0 left-0 flex justify-center items-center ' +
        'transition-all duration-300 ease-in-out ' +
        `${
          isOpen
            ? 'opacity-100 z-[var(--z-index-modal)] pointer-events-auto'
            : 'opacity-0 z-[var(--z-index-hidden)] pointer-events-none'
        }`
      }
    >
      <div
        className={
          'h-full w-full flex justify-center items-center bg-[var(--color-overlay)] cursor-pointer'
        }
        onClick={handleClose}
      >
        <div
          className={
            'max-h-[90vh] mx-[20px] p-[40px] pb-0 flex ' +
            'bg-surface rounded-[16px] elevation-5 ' +
            'transition-[transform] duration-300 ease-in-out cursor-auto ' +
            `${isClosing ? 'scale-[0.6]' : isOpen ? 'scale-[1]' : 'scale-[0.6]'} ` +
            className
          }
          onClick={event => event.stopPropagation()}
        >
          <button
            type='button'
            className='absolute h-[32px] w-[32px] bg-transparent top-[20px] right-[20px]'
            onClick={handleClose}
          >
            <IconCross className='h-full w-full text-outline' />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
