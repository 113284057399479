import { FC } from 'react';

import AvatarFemalePlaceholder from 'assets/images/png/avatar-female-placeholder.png';
import AvatarMalePlaceholder from 'assets/images/png/avatar-male-placeholder.png';

import { calculateAge, getGender } from 'lib';
import { User } from 'models/user';

type EmployeeCardProps = {
  employee: User;
  className?: string;
};

const EmployeeCard: FC<EmployeeCardProps> = props => {
  const {
    employee: { name, gender, birthday },
    className
  } = props;
  // const navigate = useNavigate();

  // const goToEmployeePage = () => {
  //   navigate(`${MasterPaths[MasterRoutes.EMPLOYEES]}/${_id || id}`);
  // };

  return (
    <label
      className={
        'grow min-h-[248px] max-w-[544px] p-[24px_32px_24px_24px] ' +
        'flex gap-[40px] rounded-[20px] bg-light elevation-2 ' +
        className
      }
      // onClick={goToEmployeePage}
    >
      <div
        className={
          'pt-[50px] w-[150px] shrink-0 bg-primary-container rounded-[32px] overflow-hidden'
        }
      >
        <img
          src={gender ? AvatarMalePlaceholder : AvatarFemalePlaceholder}
          alt={name}
          className='h-full w-full object-cover'
        />
      </div>

      <div className='flex flex-col ellipsis'>
        <h3 title={name} className='mb-[12px] text-h3 text-on-surface ellipsis'>
          {name}
        </h3>
        <h5 title='Должность: неизвестно' className='mb-[4px] text-h5 text-primary ellipsis'>
          Должность: неизвестно
        </h5>
        <h5 title='Отдел: неизвестно' className='mb-[16px] text-h5 text-on-surface ellipsis'>
          Отдел: неизвестно
        </h5>
        <p className='text-ts text-on-surface'>
          {getGender(gender)} {birthday && `, ${calculateAge(birthday)} лет`}
        </p>
      </div>
    </label>
  );
};

export default EmployeeCard;
