import {
  GetAccountDto,
  GetAccountResponse,
  GetAccountsResponse,
  GetMyAccountBalanceResponse,
  GetMyAccountResponse,
  UpdateAccountDto
} from 'models/user';
import { instance } from './instance';

export const getMyAccount = async () => {
  const response = await instance.get<GetMyAccountResponse>('account');
  return response.data;
};

export const getAccount = async ({ userId }: GetAccountDto) => {
  const response = await instance.get<GetAccountResponse>(`account/user/${userId}`);
  return response.data;
};

export const getAccounts = async () => {
  const response = await instance.get<GetAccountsResponse>('account/users');
  return response.data;
};

export const updateAccount = async ({ userId, body }: UpdateAccountDto) => {
  const response = await instance.patch<GetAccountResponse>(`account/${userId}`, body);
  return response.data;
};

export const getMyAccountBalance = async () => {
  const response = await instance.get<GetMyAccountBalanceResponse>('account/balance');
  return response.data;
};
