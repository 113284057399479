import { FC } from 'react';

import { useGetUserTestings } from 'hooks/testing';

import { TestingCard } from 'components';

type EmployeeTestsProps = {
  employeeId: string;
  className?: string;
};

const EmployeeTests: FC<EmployeeTestsProps> = ({ employeeId, className }) => {
  const { data: testings, isLoading: testingLoading } = useGetUserTestings({ userId: employeeId });

  if (testingLoading)
    return (
      <div
        className={
          `p-[24px_24px_24px_32px] flex flex-col gap-[24px] ${className} ` +
          'rounded-[20px] bg-light elevation-2'
        }
      >
        <h3 className='text-h3 text-on-surface'>Загрузка...</h3>
      </div>
    );

  if (!testings)
    return (
      <div
        className={
          `p-[24px_24px_24px_32px] flex flex-col gap-[24px] ${className} ` +
          'rounded-[20px] bg-light elevation-2'
        }
      >
        <h3 className='text-h3 text-on-surface'>Не удалось загрузить результаты тестирований</h3>
      </div>
    );

  return (
    <div
      className={
        `p-[24px_24px_24px_32px] flex flex-col gap-[24px] ${className} ` +
        'rounded-[20px] bg-light elevation-2'
      }
    >
      <header className='flex flex-col gap-[24px]'>
        <h3 className='text-h3 text-on-surface'>Результаты тестирований</h3>
        <div className='h-[1px] w-full bg-on-surface-transparent' />
      </header>

      <ul className='flex flex-col gap-[24px]'>
        {testings.map((testing, index) => (
          <div key={testing.testId} className='flex flex-col gap-[24px]'>
            <TestingCard testing={testing} />
            {index !== testings.length - 1 && (
              <div className='h-[1px] w-full bg-on-surface-transparent' />
            )}
          </div>
        ))}
      </ul>
    </div>
  );
};

export default EmployeeTests;
