import { Navigate, RouteProps } from 'react-router-dom';

import { IconCompany, IconEmployee, IconHome, IconTest } from 'components';
import { CompanyPage, EmployeePage, EmployeesPage } from 'pages/manager';
import { ProfilePage } from 'pages/shared';

export enum ManagerRoutes {
  MAIN = 'main',
  COMPANY = 'company',
  DEPARTAMENTS = 'departaments',
  EMPLOYEES = 'employees',
  EMPLOYEE = 'employee',
  PROFILE = 'profile',
  NOT_FOUND = 'not-found'
}

export const ManagerPaths: Record<ManagerRoutes, string> = {
  [ManagerRoutes.MAIN]: '/',
  [ManagerRoutes.COMPANY]: '/companies',
  [ManagerRoutes.DEPARTAMENTS]: '/departments',
  [ManagerRoutes.EMPLOYEES]: '/employees',
  [ManagerRoutes.EMPLOYEE]: '/employees/:employeeId',
  [ManagerRoutes.PROFILE]: '/profile',
  [ManagerRoutes.NOT_FOUND]: '*'
};

export const managerNavigationLinks: {
  title: string;
  link: string;
  Icon: typeof IconHome;
  disabled?: boolean;
}[] = [
  { title: 'Главная', link: ManagerPaths[ManagerRoutes.MAIN], Icon: IconHome, disabled: true },
  { title: 'Компания', link: ManagerPaths[ManagerRoutes.COMPANY], Icon: IconCompany },
  {
    title: 'Отделы',
    link: ManagerPaths[ManagerRoutes.DEPARTAMENTS],
    Icon: IconTest,
    disabled: true
  },
  { title: 'Сотрудники', link: ManagerPaths[ManagerRoutes.EMPLOYEES], Icon: IconEmployee }
];

export const ManagerRouteConfig: Record<ManagerRoutes, RouteProps> = {
  [ManagerRoutes.MAIN]: {
    path: ManagerPaths[ManagerRoutes.MAIN],
    element: <Navigate to={ManagerPaths[ManagerRoutes.COMPANY]} replace />
  },
  [ManagerRoutes.COMPANY]: { path: ManagerPaths[ManagerRoutes.COMPANY], Component: CompanyPage },
  [ManagerRoutes.DEPARTAMENTS]: {
    path: ManagerPaths[ManagerRoutes.DEPARTAMENTS],
    element: <Navigate to={ManagerPaths[ManagerRoutes.COMPANY]} replace />
  },
  [ManagerRoutes.EMPLOYEES]: {
    path: ManagerPaths[ManagerRoutes.EMPLOYEES],
    Component: EmployeesPage
  },
  [ManagerRoutes.EMPLOYEE]: {
    path: ManagerPaths[ManagerRoutes.EMPLOYEE],
    Component: EmployeePage
  },
  [ManagerRoutes.PROFILE]: { path: ManagerPaths[ManagerRoutes.PROFILE], Component: ProfilePage },
  [ManagerRoutes.NOT_FOUND]: {
    path: ManagerPaths[ManagerRoutes.NOT_FOUND],
    element: <Navigate to={ManagerPaths[ManagerRoutes.COMPANY]} replace />
  }
};
