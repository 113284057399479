import { useMutation } from 'react-query';

import { generateByInstruction } from 'api/microservice';
import { handleError, notifySuc } from 'helpers';
import { GenerateByInstructionDto } from 'models/microservice';

export const useGenerateByInstruction = () => {
  return useMutation(
    (dto: GenerateByInstructionDto) => {
      return generateByInstruction(dto);
    },
    {
      onSuccess() {
        notifySuc('Данные сгенерированы');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
