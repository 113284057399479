import {
  CreateAdminDto,
  CreateMasmanDto,
  LoginDto,
  LoginResponse,
  PasswordResetDto
} from 'models/auth';
import { instance } from './instance';

export const login = async (body: LoginDto) => {
  const response = await instance.post<LoginResponse>('auth/login', body);
  return response.data;
};

export const resetPassword = async (body: PasswordResetDto) => {
  const response = await instance.post('auth/pwd/recovercode', body);
  return response.data;
};

export const createAdmin = async ({ body }: CreateAdminDto) => {
  const response = await instance.post('auth/signup/admin', body);
  return response.data;
};

export const createMasman = async ({ body }: CreateMasmanDto) => {
  const response = await instance.post('auth/signup/masman', body);
  return response.data;
};

export const deleteMyAccount = async () => {
  const response = await instance.delete('auth/delete');
  return response.data;
};
