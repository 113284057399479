import { useMutation } from 'react-query';

import { login } from 'api/auth';
import { handleError, notifySuc } from 'helpers';
import { LoginDto } from 'models/auth';
import { useAuthStore } from 'store/auth';

export const useLoginMutation = () => {
  const setLogin = useAuthStore(state => state.setLogin);

  return useMutation(
    (data: LoginDto) => {
      return login(data);
    },
    {
      onSuccess(data) {
        notifySuc('Авторизация прошла успешно');

        localStorage.setItem('token', data.token);
        setLogin(true);
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
