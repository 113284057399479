import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { Button, IconMagic, TextEditor, TextPreview } from 'components';
import { DUMMY_EMPLOYEE_CHARACTERISTIC } from 'const/employee';
import { FORM_ERROR_MESSAGES, htmlToNotBeEmpty } from 'const/form';
import { useGenerateEmployeeCharacteristic } from 'hooks/microservice';
import { useGetUserTestings } from 'hooks/testing';

type FormValues = {
  characteristic: string;
};

type EmployeeCharacteristicProps = {
  employeeId: string;
  editable?: boolean;
  className?: string;
};

const EmployeeCharacteristic: FC<EmployeeCharacteristicProps> = props => {
  const { editable, employeeId, className } = props;
  const { data: testings, isLoading: testingLoading } = useGetUserTestings({ userId: employeeId });
  const { mutate: generateCharacteristic, isLoading: generateCharacteristicLoading } =
    useGenerateEmployeeCharacteristic();
  const newData = false;

  const {
    control,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty }
  } = useForm<FormValues>({
    mode: 'onSubmit',
    defaultValues: {
      characteristic: DUMMY_EMPLOYEE_CHARACTERISTIC
    }
  });

  const handleGenerate = () => {
    if (!testings) return;

    const feedbacks = testings
      .map(testing => testing.groupedAnswers)
      .flat()
      .map(groupedAnswersResult => groupedAnswersResult.feedbackResult?.feedbackCompany)
      .map(feedbackResult => (feedbackResult ? feedbackResult : ''))
      .filter(feedbackResult => !!feedbackResult);

    generateCharacteristic(
      { feedbacks },
      {
        onSuccess: response => {
          setValue('characteristic', response.result);
        }
      }
    );
  };

  const handleReset = () => {
    reset();
  };

  const onSubmit = (data: FormValues) => {
    console.log(data);
  };

  return (
    <div
      className={
        `p-[24px_24px_24px_32px] flex flex-col gap-[24px] ${className} ` +
        'rounded-[20px] bg-light elevation-2'
      }
    >
      <header className='flex justify-between items-center gap-[8px]'>
        <h3 className='text-h3 text-on-surface'>Характеристика сотрудника</h3>
        <div className='flex items-center gap-[8px]'>
          {newData && (
            <span className='text-bm text-error'>
              Был пройден новый тест, <b className='font-[600]'>обновите данные</b>!
            </span>
          )}
          {editable && (
            <Button
              variant='tertiary'
              height='big'
              title='Сгенерировать'
              icon={<IconMagic className='h-[18px] w-[18px]' />}
              iconPosition='right'
              onClick={handleGenerate}
              isLoading={generateCharacteristicLoading || testingLoading}
            />
          )}
        </div>
      </header>

      {editable ? (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-[12px]'>
          <TextEditor
            name='characteristic'
            placeholder='Характеристика сотрудника по общим результатам тестирований'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              validate: {
                HtmlToNotBeEmpty: htmlToNotBeEmpty
              }
            }}
          />

          <div className='flex gap-[8px] justify-end'>
            <Button
              variant='secondary'
              title='Сбросить'
              onClick={handleReset}
              disabled={!isDirty}
              className='min-w-[160px]'
            />
            <Button
              type='submit'
              variant='primary'
              title='Сохранить'
              // disabled={!isDirty}
              disabled
              className='min-w-[160px]'
            />
          </div>
        </form>
      ) : (
        <TextPreview value={DUMMY_EMPLOYEE_CHARACTERISTIC} />
      )}
    </div>
  );
};

export default EmployeeCharacteristic;
