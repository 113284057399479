import { Tab } from '@headlessui/react';
import { FC, Fragment, useMemo } from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch
} from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { OpenQuestions, TestForm, TestQuestions } from 'components';
import { TestFormValues } from 'models/test(old)';
import { tabs } from '../../model/const';
import { GeneralInfo } from '../GeneralInfo';

type TabsProps = {
  control: Control<TestFormValues>;
  trigger: UseFormTrigger<TestFormValues>;
  watch: UseFormWatch<TestFormValues>;
  getValues: UseFormGetValues<TestFormValues>;
  setValue: UseFormSetValue<TestFormValues>;
  className?: string;
};

const Tabs: FC<TabsProps> = props => {
  const { control, trigger, watch, getValues, setValue, className } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedIndex = useMemo(() => {
    return Number(searchParams.get('tab')) || 0;
  }, [searchParams]);

  const handleTabChange = (index: number) => {
    searchParams.set('tab', index.toString());
    setSearchParams(searchParams);
  };

  return (
    <div className={`flex flex-col ${className}`}>
      <Tab.Group as={Fragment} selectedIndex={selectedIndex} onChange={handleTabChange}>
        <Tab.List
          className={
            'mb-[16px] flex gap-[2px] flex-wrap border-b-[1px] border-solid border-[#C5C6D0]'
          }
        >
          {tabs.map(tab => (
            <Tab
              key={tab}
              className={({ selected }) =>
                `${selected ? 'bg-secondary-container' : 'bg-[var(--color-primary-008)]'} ` +
                'h-[40px] py-[10px] px-[40px] text-ll text-on-secondary-fixed ' +
                'rounded-[16px_16px_0_0] duration-200'
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels as={Fragment}>
          <Tab.Panel>
            <GeneralInfo control={control} />
          </Tab.Panel>

          <Tab.Panel>
            <TestForm
              control={control}
              trigger={trigger}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
            />
          </Tab.Panel>

          <Tab.Panel>
            <TestQuestions
              control={control}
              trigger={trigger}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
            />
          </Tab.Panel>

          <Tab.Panel>
            <OpenQuestions
              control={control}
              trigger={trigger}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Tabs;
