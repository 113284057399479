import { ChangeEvent, FC, InputHTMLAttributes, useRef } from 'react';

import styles from './SearchField.module.css';

import { IconCross, IconSearch } from 'components';

type SearchFieldProps = InputHTMLAttributes<HTMLInputElement> & {
  value: string;
  setValue: (value: string) => void;
  onSubmit?: () => void;
  isDropdown?: boolean;
  isActive?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
};

const SearchField: FC<SearchFieldProps> = ({
  value,
  setValue,
  onSubmit,
  isDropdown,
  isActive,
  isDisabled,
  isLoading,
  className,
  type = 'text',
  placeholder = 'Поиск',
  ...rest
}) => {
  const id = `search-field-${Math.random()}`;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleClear = () => {
    setValue('');
    inputRef.current?.focus();
  };

  const handleSubmit = () => {
    onSubmit?.();
  };

  return (
    <label
      htmlFor={id}
      className={
        `${styles.label} h-[var(--height-search-field)] p-[4px_16px_3px_24px] flex items-center gap-[8px] ` +
        'bg-light border-b-[1px] border-solid cursor-text transition-all duration-150 ease-in ' +
        `${
          isDropdown && isActive
            ? 'rounded-[28px_28px_0_0] border-on-surface-transparent'
            : 'rounded-[100px] elevation-2 border-transparent'
        } ` +
        className
      }
    >
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        className={`${styles.input} flex text-bl text-on-surface disabled:text-on-surface-variant`}
        {...rest}
      />

      {!!value.length && (
        <>
          <button
            type='button'
            disabled={isDisabled || isLoading}
            onClick={handleClear}
            className={
              `${styles.buttonClose} shrink-0 ` + `${isActive ? styles.buttonClose_active : ''}`
            }
          >
            <IconCross className={styles.icon} />
          </button>

          <div className='h-full w-[1px] bg-on-surface-transparent' />
        </>
      )}

      <button
        type='button'
        disabled={isDisabled || isLoading}
        className={
          `${styles.buttonSearch} shrink-0 ${isLoading ? 'hidden' : ''} ` +
          `${isActive ? styles.buttonSearch_active : ''}`
        }
        onClick={handleSubmit}
      >
        {/* <IconSpinner className={`${styles.icon} ${isLoading ? '' : 'hidden'}`} /> */}
        <IconSearch className={`${styles.icon} ${isLoading ? 'hidden' : ''}`} />
      </button>
    </label>
  );
};

export default SearchField;
