import { Navigate, RouteProps } from 'react-router-dom';

import { LoginPage, PasswordRecoveryPage } from 'pages/unauthorized';

export enum UnauthorizedRoutes {
  SIGNIN = 'signin',
  SIGNUP = 'signup',
  RESET_PASSWORD = 'reset-password',
  NOT_FOUND = 'not-found'
}

export const UnauthorizedPaths: Record<UnauthorizedRoutes, string> = {
  [UnauthorizedRoutes.SIGNIN]: '/signin',
  [UnauthorizedRoutes.SIGNUP]: '/signup',
  [UnauthorizedRoutes.RESET_PASSWORD]: '/reset-password',
  [UnauthorizedRoutes.NOT_FOUND]: '*'
};

export const UnauthorizedRouteConfig: Record<UnauthorizedRoutes, RouteProps> = {
  [UnauthorizedRoutes.SIGNIN]: {
    path: UnauthorizedPaths[UnauthorizedRoutes.SIGNIN],
    Component: LoginPage
  },
  [UnauthorizedRoutes.SIGNUP]: { path: UnauthorizedPaths[UnauthorizedRoutes.SIGNUP] },
  [UnauthorizedRoutes.RESET_PASSWORD]: {
    path: UnauthorizedPaths[UnauthorizedRoutes.RESET_PASSWORD],
    Component: PasswordRecoveryPage
  },
  [UnauthorizedRoutes.NOT_FOUND]: {
    path: UnauthorizedPaths[UnauthorizedRoutes.NOT_FOUND],
    element: <Navigate to={UnauthorizedPaths[UnauthorizedRoutes.SIGNIN]} replace />
  }
};
