import { FC } from 'react';

import { TextPreview } from 'components';

type RecommendationProps = {
  recommendation: string;
  className?: string;
};

// TODO add group question / inner question prop, accordion, update TextPreview

const Recommendation: FC<RecommendationProps> = props => {
  const { recommendation, className } = props;

  return (
    <div className={`flex ${className}`}>
      <TextPreview value={recommendation} />
    </div>
  );
};

export default Recommendation;
