import { useMutation } from 'react-query';

import { generateFeedbackForQuestion } from 'api/microservice';
import { handleError, notifySuc } from 'helpers';
import { GenerateFeedbackForQuestionDto } from 'models/microservice';

export const useGenerateFeedbackForQuestion = () => {
  return useMutation(
    (dto: GenerateFeedbackForQuestionDto) => {
      return generateFeedbackForQuestion(dto);
    },
    {
      onSuccess() {
        notifySuc('Данные сгенерированы');
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
