import { useMutation, useQueryClient } from 'react-query';

import { apiTest } from 'api/test';
import { TESTS_QUERY_KEY } from 'const/test';
import { handleError, notifySuc } from 'helpers';
import { DeleteTestDto, GetTestsResponse } from 'models/test(old)';

export const useDeleteTestMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: DeleteTestDto) => {
      return apiTest.deleteTest(data);
    },
    {
      onSuccess(_, { testId }) {
        notifySuc('Тест удален');

        queryClient.invalidateQueries(TESTS_QUERY_KEY, {
          refetchActive: false,
          refetchInactive: false
        });

        const oldData = queryClient.getQueryData<GetTestsResponse>(TESTS_QUERY_KEY);
        if (oldData) {
          const updatedData = oldData.filter(test => test._id !== testId);
          queryClient.setQueryData(TESTS_QUERY_KEY, updatedData);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
