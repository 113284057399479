import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import { FORM_ERROR_MESSAGES } from 'const/form';
import { CreateAccountFormValues } from 'models/auth';

import { Button, Select, TextField } from 'components';
import { EMAIL_REGEXP, PASSWORD_NO_MATCH_REGEXP } from 'const/regexp';
import { useCreateAdmin, useCreateMasman } from 'hooks/auth';
import { Gender, USER_ROLES } from 'models/user';

type CreateAccountFormProps = {
  onClose?: () => void;
  className?: string;
};

const CreateAccountForm: FC<CreateAccountFormProps> = props => {
  const { onClose, className } = props;
  const { mutate: createAdminMutate, isLoading: createAdminLoading } = useCreateAdmin();
  const { mutate: createMasmanMutate, isLoading: createMasmanLoading } = useCreateMasman();
  const isLoading = createAdminLoading || createMasmanLoading;
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty }
  } = useForm<CreateAccountFormValues>({
    mode: 'onSubmit'
  });

  const role = watch('role');
  const showAdmsec = useMemo<boolean>(() => {
    return role === USER_ROLES.ADMIN;
  }, [role]);

  const handleReset = () => {
    reset();
  };

  const onSubmit = (data: CreateAccountFormValues) => {
    const { role, gender } = data;

    if (role === USER_ROLES.ADMIN) {
      createAdminMutate(
        { body: { ...data, gender: gender === Gender.MALE } },
        { onSuccess: onClose }
      );
    } else {
      createMasmanMutate(
        { body: { ...data, gender: gender === Gender.MALE } },
        { onSuccess: onClose }
      );
    }
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`grow flex flex-col items-center justify-between gap-[36px] ${className}`}
    >
      <div className='max-w-[544px] w-full flex flex-col gap-[16px]'>
        <Select
          name='role'
          label='Выбрать роль'
          control={control}
          rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
          options={[
            { value: USER_ROLES.ADMIN, label: 'Администратор IN-EO (Admin)' },
            { value: USER_ROLES.MASTER, label: 'Ответственное лицо (Master)' },
            { value: USER_ROLES.MANAGER, label: 'Руководитель отдела (Manager)' }
          ]}
          disabled={isLoading}
        />

        {showAdmsec && (
          <TextField
            name='admsec'
            label='Секретный код'
            control={control}
            rules={{
              required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
              maxLength: { value: 64, message: 'Максимальная длина - 64 символа' }
            }}
            disabled={isLoading}
          />
        )}

        <TextField
          name='companyId'
          label='ID компании'
          control={control}
          rules={{
            maxLength: { value: 64, message: 'Максимальная длина - 64 символа' }
          }}
          disabled={isLoading}
        />

        <TextField
          name='name'
          label='Имя'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 64, message: 'Максимальная длина - 64 символа' }
          }}
          disabled={isLoading}
        />

        <TextField
          name='email'
          label='Email'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 64, message: 'Максимальная длина - 64 символов' },
            validate: {
              doesntContainSpaces: (value: any) => {
                return !value.includes(' ') ? true : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
              },
              validEmail: (value: any) => {
                return EMAIL_REGEXP.test(value) ? true : FORM_ERROR_MESSAGES.INCORRECT_EMAIL;
              }
            }
          }}
          disabled={isLoading}
        />

        <TextField
          type='password'
          name='password'
          label='Пароль'
          control={control}
          rules={{
            required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD },
            maxLength: { value: 64, message: 'Максимальная длина - 64 символа' },
            validate: {
              doesntContainSpaces: (value: any) => {
                return !value.includes(' ') ? true : FORM_ERROR_MESSAGES.DOESNT_CONTAIN_SPACES;
              },
              validPassword: (value: any) => {
                return !value.match(PASSWORD_NO_MATCH_REGEXP)
                  ? true
                  : FORM_ERROR_MESSAGES.INCORRECT_EMAIL;
              }
            }
          }}
          disabled={isLoading}
        />

        <Select
          name='gender'
          label='Пол'
          control={control}
          rules={{ required: { value: true, message: FORM_ERROR_MESSAGES.REQUIRED_FIELD } }}
          options={[
            { value: Gender.MALE, label: 'Мужской' },
            { value: Gender.FEMALE, label: 'Женский' }
          ]}
          disabled={isLoading}
        />

        <TextField
          name='birthday'
          label='Дата рождения (YYYY-MM-DD)'
          control={control}
          rules={{
            maxLength: { value: 64, message: 'Максимальная длина - 64 символа' }
          }}
          disabled={isLoading}
        />

        <TextField
          name='phone'
          label='Телефон'
          control={control}
          rules={{
            maxLength: { value: 64, message: 'Максимальная длина - 64 символа' }
          }}
          disabled={isLoading}
        />
      </div>

      <div className='flex gap-[8px] self-end'>
        <Button
          variant='secondary'
          title='Сбросить'
          onClick={handleReset}
          disabled={!isDirty || isLoading}
          className='min-w-[160px]'
        />
        <Button
          type='submit'
          variant='primary'
          title='Добавить'
          disabled={!isDirty}
          isLoading={isLoading}
          className='min-w-[160px]'
        />
      </div>
    </form>
  );
};

export default CreateAccountForm;
