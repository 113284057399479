import { useMutation, useQueryClient } from 'react-query';

import { addPromocodeToTests } from 'api/promocode';
import { handleError, notifySuc } from 'helpers';
import { AddPromocodeToTestsDto } from 'models/promocode';

export const useAddPromocodeToTests = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (dto: AddPromocodeToTestsDto) => {
      return addPromocodeToTests(dto);
    },
    {
      onSuccess(_, { refetch = true }) {
        notifySuc('Промокод добавлен');

        if (refetch) {
          queryClient.invalidateQueries(['promocodes/supervisor']);
        }
      },
      onError(error) {
        handleError(error);
      }
    }
  );
};
