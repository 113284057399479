export const DUMMY_EMPLOYEES: any[] = [
  {
    id: '1',
    name: 'Орлов Олег Юрьевич',
    birthday: '1990-06-13',
    phone: '+7 (123) 456-78-90',
    gender: true,
    city: 'Москва',
    department: 'Отдел охраны труда',
    position: 'Специалист'
  },
  {
    id: '2',
    name: 'Орлова Ольга Юрьевна',
    birthday: '1990-06-13',
    phone: '+7 (123) 456-78-90',
    gender: false,
    city: 'Москва',
    department: 'Отдел охраны труда',
    position: 'Специалист'
  }
];

export const DUMMY_EMPLOYEE = DUMMY_EMPLOYEES[0];

export const DUMMY_EMPLOYEE_CHARACTERISTIC =
  '<p>Личностные качества: коммуникабельность, целеустремленность, стрессоустойчивость, трудолюбие, высокая работоспособность.</p><p><br></p><p>В коллективе со всеми находится в дружеских отношениях, конфликты решает мирно, проявляет инициативу в общественной жизни организации, пунктуален и требователен как к себе, так и к подчиненным. Никогда не был причастен к дисциплинарным нарушениям.</p>';

export const DUMMY_EMPLOYEE_TESTINGS = [];
