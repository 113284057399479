import { FC } from 'react';

import { CalibrationData } from 'models/calibration';
import { MyChart } from './MyChart';

type CalibrationProps = {
  calibration: CalibrationData;
  className?: string;
};

const Calibration: FC<CalibrationProps> = props => {
  const { calibration, className } = props;

  const xAxis: number[][] = calibration.accelerometerData.reduce((acc, current) => {
    acc.push(current.x);
    return acc;
  }, [] as number[][]);

  const yAxis: number[][] = calibration.accelerometerData.reduce((acc, current) => {
    acc.push(current.y);
    return acc;
  }, [] as number[][]);

  const zAxis: number[][] = calibration.accelerometerData.reduce((acc, current) => {
    acc.push(current.z);
    return acc;
  }, [] as number[][]);

  const xnAxis: number[][] = calibration.accelerometerData.reduce((acc, current) => {
    acc.push(current.x_norm);
    return acc;
  }, [] as number[][]);

  const ynAxis: number[][] = calibration.accelerometerData.reduce((acc, current) => {
    acc.push(current.y_norm);
    return acc;
  }, [] as number[][]);

  const znAxis: number[][] = calibration.accelerometerData.reduce((acc, current) => {
    acc.push(current.z_norm);
    return acc;
  }, [] as number[][]);

  return (
    <div className={`flex flex-col ${className}`}>
      <MyChart
        xAxis={xAxis}
        yAxis={yAxis}
        zAxis={zAxis}
        xnAxis={xnAxis}
        ynAxis={ynAxis}
        znAxis={znAxis}
        className='mb-[40px]'
      />
    </div>
  );
};

export default Calibration;
