import { FC } from 'react';

import { TextPreview } from 'components';

type FeedbackProps = {
  feedback: string;
  className?: string;
};

// TODO add group question / inner question prop, accordion, update TextPreview

const Feedback: FC<FeedbackProps> = props => {
  const { feedback, className } = props;

  return (
    <div className={`flex ${className}`}>
      <TextPreview value={feedback} />
    </div>
  );
};

export default Feedback;
