import { FC, Suspense, useMemo } from 'react';

import { useUserRole } from 'hooks/auth';
import { USER_ROLES } from 'models/user';
import { useAuthStore } from 'store/auth';

import AdminRoutes from './AdminRoutes';
import ManagerRoutes from './ManagerRoutes';
import MasterRoutes from './MasterRoutes';
import UnauthorizedRoutes from './UnauthorizedRoutes';

const AppRouter: FC = () => {
  const isLogin = useAuthStore(state => state.isLogin);
  const userRole = useUserRole();

  const UserRoutes = useMemo<FC>(() => {
    if (!isLogin) {
      return UnauthorizedRoutes;
    }

    switch (userRole) {
      case USER_ROLES.ADMIN:
        return AdminRoutes;
      case USER_ROLES.MASTER:
        return MasterRoutes;
      case USER_ROLES.MANAGER:
        return ManagerRoutes;
      default:
        return UnauthorizedRoutes;
    }
  }, [isLogin, userRole]);

  return (
    <Suspense fallback={<h2>Loading...</h2>}>
      {isLogin ? <UserRoutes /> : <UnauthorizedRoutes />}
    </Suspense>
  );
};

export default AppRouter;
