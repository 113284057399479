import { FC, useMemo } from 'react';

import { GroupedByTestTestingCard, TestingCard } from 'components';
import { useGetCompanyTestings } from 'hooks/testing';
import { groupTestingsByTest } from 'lib';
import { GroupedByTestTesting } from 'models/testing';

type CompanyTestingsProps = {
  withGrouping?: boolean;
  withTestings?: boolean;
  className?: string;
};

const CompanyTestings: FC<CompanyTestingsProps> = props => {
  const { withGrouping = false, withTestings = false, className } = props;
  const { data: testings, isLoading: testingLoading } = useGetCompanyTestings();

  const groupedByTestTestings = useMemo<GroupedByTestTesting[]>(() => {
    if (!testings || !withGrouping) return [];

    const result = groupTestingsByTest(testings);
    return result;
  }, [testings]);

  if (testingLoading)
    return (
      <div
        className={
          `p-[24px_24px_24px_32px] flex flex-col gap-[24px] ${className} ` +
          'rounded-[20px] bg-light elevation-2'
        }
      >
        <h3 className='text-h3 text-on-surface'>Загрузка...</h3>
      </div>
    );

  if (!testings)
    return (
      <div
        className={
          `p-[24px_24px_24px_32px] flex flex-col gap-[24px] ${className} ` +
          'rounded-[20px] bg-light elevation-2'
        }
      >
        <h3 className='text-h3 text-on-surface'>Не удалось загрузить результаты тестирований</h3>
      </div>
    );

  return (
    <>
      {withGrouping && (
        <div
          className={
            `mb-[16px] p-[24px_24px_24px_32px] flex flex-col gap-[24px] ${className} ` +
            'rounded-[20px] bg-light elevation-2'
          }
        >
          <header className='flex flex-col gap-[24px]'>
            <h3 className='text-h3 text-on-surface'>
              Результаты тестирований (сгруппированные по тестам)
            </h3>
            <div className='h-[1px] w-full bg-on-surface-transparent' />
          </header>

          {groupedByTestTestings.length ? (
            <ul className='flex flex-col gap-[24px]'>
              {groupedByTestTestings.map((groupedByTestTesting, index) => (
                <div key={groupedByTestTesting.testId} className='flex flex-col gap-[24px]'>
                  <GroupedByTestTestingCard groupedByTestTesting={groupedByTestTesting} />
                  {index !== testings.length - 1 && (
                    <div className='h-[1px] w-full bg-on-surface-transparent' />
                  )}
                </div>
              ))}
            </ul>
          ) : (
            <h4 className='text-h4 text-on-surface'>Нет результатов</h4>
          )}
        </div>
      )}

      {withTestings && (
        <div
          className={
            `p-[24px_24px_24px_32px] flex flex-col gap-[24px] ${className} ` +
            'rounded-[20px] bg-light elevation-2'
          }
        >
          <header className='flex flex-col gap-[24px]'>
            <h3 className='text-h3 text-on-surface'>Результаты тестирований (все)</h3>
            <div className='h-[1px] w-full bg-on-surface-transparent' />
          </header>
          <ul className='flex flex-col gap-[24px]'>
            {testings.map((testing, index) => (
              <div key={index} className='flex flex-col gap-[24px]'>
                <TestingCard testing={testing} />
                {index !== testings.length - 1 && (
                  <div className='h-[1px] w-full bg-on-surface-transparent' />
                )}
              </div>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default CompanyTestings;
